import React, { useState, useEffect } from 'react';
import { MdFolderOpen } from 'react-icons/md';
import { BsArrowReturnRight } from 'react-icons/bs';
import moment from 'moment';
import '../../style/dashboard.style.scss'
import { ToastContainer, toast } from 'react-toastify';
import { TrashData, getStorageCount } from "../../service/dashboard/data-services"
import { useHistory } from "react-router-dom";
import {formatBytes} from "../../service/dashboard/size-count";
import { Table } from 'react-bootstrap';
import { getFileType } from 'service/dashboard/file-check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface Iprop {
    setHeadLoader: Function
    setID: Function
    setTargetName: Function
    setFolderParentId: Function
    setFolderDirType: Function
    handleClick: any
    setTrashFlag: Function
    customState: boolean
    setRestoreFlag: Function
    setDeleteFlag: Function
    disable: Function
    setIDArray: Function
    layoutType: string
    idArray: any[]
}
const Trash: React.FC<Iprop> = ({
    setHeadLoader, setID, setTargetName, handleClick, setTrashFlag, setDeleteFlag, customState, setRestoreFlag,
    disable, setIDArray, idArray, layoutType }: Iprop) => {
    const history = useHistory();
    const [trashFolder, setTrashFolder] = useState<any[]>([]);
    const [trashFiles, setTrashFiles] = useState<any[]>([]);
    const [trashloading, setTrashloading] = useState(false);
    const [authFlag, setAuthFlag] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [allIds, setAllIds] = useState<any>([]);
    const [folderCounts, setFolderCounts] = useState<any>([]);
    const [hasMoreFolder, setHasMoreFolders] = useState(false);
    const [hasMoreFiles, setHasMoreFiles] = useState(false);
    let [pageNo, setPageNo] = useState<number>(0);
    let [filePage, setFilePage] = useState<number>(0);
    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
    });
    function getStorage() {
        setTrashFlag(true);
        trashFolder.length = 0;
        trashFiles.length = 0;
        setTrashFolder(trashFolder);
        setTrashFiles(trashFiles);
        getTrashFolder();
        getTrashFiles();
    }
    useEffect(() => {
        getStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customState])
    const handleFolderScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (!hasMoreFolder) {
                setPageNo(++pageNo);
                getTrashFolder();
            }
        }
    }
    const getFoldersCount = async (ids:number[]) => {
        if(ids.length < 1) return
        try {            
            const response = await getStorageCount(ids);
            setFolderCounts(response.data.response)
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }   
    }

    const toggleSelected = (id:number) => {
        if(idArray.includes(id)){
            let filterArray = idArray.filter((d:any)=>d !== id);
            setIDArray(filterArray)
        }
        else{
            const ids = [...idArray, id];
            setIDArray(ids);
        }
    } 

    const onSelectAll = () =>{
        if(selectAll){
            setIDArray([]);
            setRestoreFlag(false);
            setDeleteFlag(false);
        }
        else {
            setIDArray(allIds)
            setRestoreFlag(true)
            setDeleteFlag(true)
        }
        setSelectAll(!selectAll);
    }

    const handlefileScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (!hasMoreFiles) {
                setFilePage(++filePage);
                getTrashFiles();
            }
        }
    }
    const getTrashFolder = async () => {
        disable();
        setHeadLoader(true);
        try {
            const response = await TrashData('DIR');
            let ids:any = []
            response.data.response.trashedStorages.map((element: any) => {
                trashFolder.push(element)
                allIds.push(element.id)
                ids.push(element.id)
                return element;
            });
            setTrashFolder(Array.from(new Set(trashFolder)));
            setAllIds(allIds)
            getFoldersCount(ids)
            setTrashloading(true);
            if (response.data.response.trashedStorages.length === 0 || response.data.response.trashedStorages.length < 10) {
                setHasMoreFolders(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        disable();
    }
    const getTrashFiles = async () => {
        setHeadLoader(true);
        try {
            const files = await TrashData('FILE');
            files.data.response.trashedStorages.map((element: any) => {
                trashFiles.push(element)
                allIds.push(element.id)
                return element;
            });
            setTrashFiles(Array.from(new Set(trashFiles)));
            setAllIds(allIds)
            setTrashloading(true);
            if (files.data.response.trashedStorages.length === 0 || files.data.response.trashedStorages.length < 10) {
                setHasMoreFiles(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode !== 401) {
                    toast.error("Error in Loading Folder")
                }
            }
        }
        setHeadLoader(false);
    }
    const renderFolders = () => {
        return trashFolder.map((Folder: any) => {
            return (
                <tr key={Folder.id} className={"cursor select-tab " + (idArray.includes(Folder.id) ? 'selects' : '')} id={Folder.id}
                onClick={()=>{
                    setRestoreFlag(true);
                    setDeleteFlag(true);
                    if(idArray.includes(Folder.id)){
                        let newArray = idArray.filter(id => id !== Folder.id)
                        setIDArray(newArray)
                    }else{
                        const ids = [...idArray, Folder.id];
                        setIDArray(ids);
                    }
                }}
                >
                    <td className="file-name-o checkbox">
                    <Checkbox
                    className={''}
                    disableRipple
                    checked={idArray.includes(Folder.id) ? true : false}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <MdFolderOpen className="folder-icon" />{Folder.name.slice(0, 20)}</td>
                    <td className="right">{folderCounts[Folder.id] && folderCounts[Folder.id].foldersCount} Folders | {folderCounts[Folder.id] && folderCounts[Folder.id].fileCount} Files</td>
                    <td className="right" >{moment(Folder.updated_at).format("DD MMM YYYY HH:mm")}
                    </td>
                </tr>
            );
        })
    }

    const renderGridFolders = () => {
        return trashFolder.map((Folder: any) => {
            return (
                <tr key={Folder.id} className={"cursor select-tab " + (idArray.includes(Folder.id) ? 'selects' : '')} id={Folder.id}
                onClick={(e)=>{
                    setRestoreFlag(true);
                    setDeleteFlag(true);
                    if(idArray.includes(Folder.id)){
                        let newArray = idArray.filter(id => id !== Folder.id)
                        setIDArray(newArray)
                    }else{
                        if (idArray.length < 1){
                        const ids = [...idArray, Folder.id];
                        setIDArray(ids);
                        } else {
                            if (e.ctrlKey){
                                const ids = [...idArray, Folder.id];
                                setIDArray(ids);
                            }
                        }
                    }
                }}
                >
                    <div className="row folder-card-row">
                        <div className="col-md-4">
                            <div className="folder-card">
                                <div className="folder-card-heading">
                                <MdFolderOpen className="folder-icon-grid" />
                                </div>
                                <div className="folder-card-content">
                                    {Folder.name.slice(0, 15)}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <td className="file-name-o">
                    <MdFolderOpen className="folder-icon" />{Folder.name.slice(0, 20)}</td>
                    <td className="right">{Folder.dir_count} Folders | {Folder.files_count} Files</td>
                    <td className="right" >{moment(Folder.updated_at).format("DD MMM YYYY HH:mm")}
                    </td> */}
                </tr>
            );
        })
    }

    const renderFiles = () => {
        return trashFiles.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr className={"cursor select-tab " + (idArray.includes(files.id) ? 'selects' : '')} key={files.id}
                    onClick={(e) => {
                        setRestoreFlag(true);
                        setDeleteFlag(true);
                        toggleSelected(files.id);
                    }}
                >
                    <td className="file-name-o checkbox">
                    <Checkbox
                    className={''}
                    disableRipple
                    checked={idArray.includes(files.id) ? true : false}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onClick={(e:any) => toggleSelected(files.id)}
                    />
                        <span className={"type-text text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpge' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{getFileType(files.name)}</span>{files.name}</td>
                    <td className="right">{formatBytes(+files.size,0)}</td>
                    <td className="right">{moment(files.updated_at).format("DD MMM YYYY HH:mm")}
                    </td>
                </tr>
            );
        })
    }

    const renderGridFiles = () => {
        return trashFiles.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr className={"cursor select-tab " + (idArray.includes(files.id) ? 'selects' : '')} key={files.id}
                    onClick={() => {
                        setRestoreFlag(true);
                        setDeleteFlag(true);
                        toggleSelected(files.id);
                    }}
                >
                    <div className="files-card">
                            <div className="card-img d-flex justify-content-center align-items-center"><span className={"text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpeg' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'csv-icon')))))))} >{type}</span></div>
                            <div className="card-body">
                                <div className="headings">
                                    <div className="introduction">
                                    {files.name}
                                    </div>
                                </div>                      
                                </div>
                    </div>
                    {/* <td className="file-name-o">
                        <span className={"mr-3 text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpge' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{type}</span>{files.name}</td>
                    <td className="right">{sizeCount(+files.size)}</td>
                    <td className="right">{moment(files.updated_at).format("DD MMM YYYY HH:mm")}
                    </td> */}
                </tr>
            );
        })
    }

    return (
        <div className="border-top tab-size mt-2">
            <ToastContainer limit={1} />
            {trashloading ?
                <div className="row w-100">
                    <p className="trash-text mt-4 ml-3">Files kept in the Trash Bin is counted as part of your Storage Usage. Please do delete the files here permanently to recover more Storage Space</p>
                    <div className="pl-3 col-12">
                    {(trashFiles.length > 0 || trashFolder.length > 0) && <FormControlLabel
                    className='mt-4'
                    value="end"
                    control={<Checkbox checked={selectAll} onClick={()=>onSelectAll()} color="primary" />}
                    label="Select All"
                    labelPlacement="end"
                    />}
                        <p className="folder">Folders</p>
                        {trashFolder.length !== 0 ?
                        layoutType === 'layoutlist' ?
                            <Table responsive="md" className="tableBodyScroll" onScroll={(event: any) => { handleFolderScroll(event) }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="right" >Contents</th>
                                        <th className="right" >Last Modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trashFolder ?
                                        renderFolders() : ''}
                                </tbody>
                            </Table>:<div className="folders-data">{trashFiles ?
                                renderGridFolders() : ''}</div>
                            :
                            <p className="txt mt-4"><BsArrowReturnRight />No Folders</p>}
                    </div>
                    <div className="pl-3 col-12">
                        <p className="mt-4 folder">Files</p>
                        {trashFiles.length !== 0 ?
                        layoutType === 'layoutlist' ? 
                            <Table responsive="md" className="tableBodyScroll" onScroll={(event: any) => { handlefileScroll(event) }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="right" >Size</th>
                                        <th className="right" >Last Modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trashFiles ?
                                        renderFiles() : ''}
                                </tbody>
                            </Table> :<div className="folders-data">{trashFiles ?
                                renderGridFiles() : ''}</div>
                            :
                            <p className="txt mt-4"><BsArrowReturnRight />No Files</p>}
                    </div>

                </div>

                :
                <div className="d-flex  pos-tab">
                    <div className="spinner-border spiner-xp mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
        </div>
    );
};
export const TrashDataComponent = React.memo(Trash);