import React, { useState } from "react";
import Header from "../../../components/header/index";
import { GiTimeTrap } from 'react-icons/gi';
import { userVerification } from "../../../service/auth-services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
const queryString = require('query-string');

const UserVerification = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const parse = queryString.parse(window.location.search);
    const token = parse.token;
    const verifyUser = async () => {
        setLoading(true);
        try {
            const response = await userVerification(token);
            toast.success(response.data.response.message);
            await setTimeout(() => {
                history.push("/");
            }, 1500);
        } catch (error) {
            if (error && error.response && error.response.data) {
                toast.error(error.response.data.message)
            } else {
                toast.info("Network Error (Check Your Internet)")
            }        }
        setLoading(false);
    }
    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <Header select={false} />
            <p className="center my-5  text1 ml-lg-2" >Verify Your Account</p>
            <div className="center main-div m-top-div">
                <p className="center heading"><GiTimeTrap className="mr-3 tick-icon" />Waiting For Account Verification</p>
                <span className="d-block mx-auto txt">Click on the Button to verify account.</span>
            </div>
            <div className="update-btn mx-auto  mt-1">
                {!loading ?
                    <p className="login-txt" onClick={()=>{verifyUser()}}>verify</p>
                    :
                    <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="login-txt"> Loading...</span></>
                }
            </div>
        </div>
    );
};
export default UserVerification;