import axios from 'axios';
import config from '../../config';
import { getCredentials, decryptResponse } from 'components/common-functions/crypto.function';
//forgot password call
export async function forgotCall(email: any) {
    const url = config.BASE_ENDPOINT + '/user/forgot/password/request';
    const Body = {
        email,
    };
    const response: any = await axios.post(url, Body)
    return response
};

//pre-login user
export async function prelogin(email: string) {
    const url = config.BASE_ENDPOINT + '/user/prelogin';
    const Body = {
        email
    };
    const response: any = await axios.post(url, Body)
    return  response.data.response.encryption_public_key
};

//login user
export async function loginUser(email: string, credentials: string) {
    const url = config.BASE_ENDPOINT + '/user/login';
    const data = {
        email,
        credentials,
    };
    const response: any = await axios.post(url, data)
    return response
};
//register user
export async function registerUser(name : string ,email: string, country: string, password: string, password_confirmation: string,phrase_pub_key:string) {
    const url = config.BASE_ENDPOINT + '/user/register';
    const Body = {
        name,
        email,
        country,
        password,
        password_confirmation,
        phrase_pub_key,
        "first_name": "Zaigham",
        "last_name": "Javed",
        "type": "USER"
    };
    const response: any = await axios.post(url, Body)
    return response
};
//reset password
export async function resetUserPass(phrase_pub_key: string, credentials: string) {
    const url = config.BASE_ENDPOINT + '/user/password/reset/';
    const Body = {
        phrase_pub_key,
        credentials,
    };
    const response: any = await axios.post(url, Body)
    return response
};
//update password 
export async function updateUserPass(oldPassword: string, password: string, password_confirmation: string) {
    const url = config.BASE_ENDPOINT + '/user/password/change';
    const token = sessionStorage.getItem('access_token');
    const obj = {
        oldPassword,
        password,
        password_confirmation,
    };
    const response: any = await axios.post(url, 
        {
            data: await getCredentials(obj)
        },
        {
            headers: {
                'Authorization': token
            }
        })
        return await decryptResponse(response)
    };
//login through private phrase
export async function loginByKey(phrase_pub_key: string){
    const url = config.BASE_ENDPOINT + '/user/restore/wallet';
    const Body = {
        phrase_pub_key
    };
    const response: any = await axios.post(url, Body)
    return response
};
//user account verification
export async function userVerification(token: string){
    const url = config.BASE_ENDPOINT + `/user/user-verification/${token}`;
    const response: any = await axios.put(url)
    return response
};
export async function setNameuser(name: string){
    const url = config.BASE_ENDPOINT + '/user/profile';
    const token = sessionStorage.getItem('access_token');
    const obj = {
        name
    };
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }
    ,{
        headers: {
                'Authorization': token
            }
        })
        return await decryptResponse(response)
    }
//resend verification email
export async function ResendVerificationEmail(email: any) {
    const url = config.BASE_ENDPOINT + '/user/verification/email/resend';
    const Body = {
        email,
    };
    const response: any = await axios.post(url, Body)
    return response
};