import React, { useState } from "react";
import Logo from '../../../assets/ziptik_logo_light_bg.svg';
import DarkLogo from '../../../assets/login_ziptic_logo.svg';
import { HiOutlineMail } from 'react-icons/hi';
import { MdLockOutline } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { prelogin,loginUser } from '../../../service/auth-services/auth';
import InputProps from "../../../style/input-style";
import LabelProps from "../../../style/label-style";
import { generatePrivateKey, generatePublicKey, getCredentials, decryptResponse } from '../../../components/common-functions/crypto.function';
import { useHistory } from "react-router-dom";
const Login = () => {
    const isDarkTheme = localStorage.getItem('isDarkTheme');
    const history = useHistory();
    const [passType, setPassType] = useState('password');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const emailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
    const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const validate = () => {
        let passwordError = "";
        let emailError = "";
        email.trim() === '' ? emailError = "Email required" : (emailRegex.test(email) !== true) ?
            emailError = "Email Invalid" :
            emailError = "";
        password.trim() === '' ? passwordError = "Password required" : (passRegex.test(password) !== true) ?
            passwordError = "Password Invalid" :
            passwordError = "";

        if (emailError || passwordError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            return false;
        } else {
            return true;
        }
    }
    const LoginUser = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setEmailError("");
            setPasswordError("");
            try {
                const server_public_key = await prelogin(email);
                let privateKey = await generatePrivateKey();
                privateKey = privateKey.toString('hex');
                let publicKey = await generatePublicKey(privateKey);
                sessionStorage.setItem("server_public_key",server_public_key);
                sessionStorage.setItem("private_key",privateKey);
                sessionStorage.setItem("public_key",publicKey);
                console.log('publicKey => ', publicKey);
                let obj = {
                  encryption_public_key: publicKey,
                  password,
                };
                let credentials = await getCredentials(obj);
                console.log(credentials);
                const result = await loginUser(email,credentials);
                const response = await decryptResponse(result)
                console.log(response);
                if(response.data.code === 200){
                    toast.success(response.data.response.message);
                    sessionStorage.setItem('access_token', response.data.response.user.token);
                    sessionStorage.setItem('password', password);
                    sessionStorage.setItem('name', response.data.response.user.name);
                    sessionStorage.setItem('email', response.data.response.user.email);
                    sessionStorage.setItem('phrase_pub_key', response.data.response.user.phrase_pub_key);
                    setTimeout(() => {
                        history.push('/dashboard');
                    }, 1000);
                    setEmail('');
                    setPassword('');
                }
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }            }
            setLoading(false);
        }
    }
    const changeType = () => {
        passType === 'password' ? setPassType('text') : setPassType('password')
    }
    const onChangeEmail = (evt:any) => {
        setEmailError('')
        setEmail(evt.target.value);
    }
    const onChangePassword = (evt:any) => {
        setPasswordError('')
        setPassword(evt.target.value);
    }
    const onPressEnter = (event:any) => {
        if (event.key === 'Enter') 
        LoginUser();
    }


    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <p className="logo-img center"><img src={isDarkTheme === 'true' ? DarkLogo : Logo } alt="logo svg" /></p>
            <div className="row container-fluid mx-auto">
                <div className="col-12 col-lg-6 center borderRight">
                    <div className="m-top-bottom">
                        <div className="main-from ">
                            <div className="center pos">
                                <HiOutlineMail className="icon-size" />
                                <TextField
                                    error={emailError !== '' ? true : false}
                                    className="div-from mb-4"
                                    id="email-box"
                                    label={emailError !== '' ? emailError : "Email Address"}
                                    variant="filled"
                                    InputLabelProps={LabelProps}
                                    InputProps={InputProps}
                                    value={email} onChange={onChangeEmail}
                                />
                            </div>
                            <MdLockOutline className="lock-icon pass" />
                            {passType === 'text' ?
                                <AiOutlineEyeInvisible className="eye-icon" onClick={changeType} />:
                                <AiOutlineEye className="eye-icon" onClick={changeType} />}
                            <TextField
                                error={passwordError !== '' ? true : false}
                                className="div-from mb-4"
                                id="pass-box"
                                type={passType}
                                label={passwordError !== '' ? passwordError : "Password"}
                                variant="filled"
                                InputProps={InputProps}
                                InputLabelProps={LabelProps}
                                value={password} 
                                onChange={onChangePassword}
                                onKeyPress={onPressEnter}
                            />
                        </div>
                        <button className="text-box2 mt-4" onClick={LoginUser}>
                            {!loading ?
                                <p className="login-txt center">Login</p> :
                                <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="login-txt"> Loading...</span></>}
                        </button>
                    </div>
                </div>
                <div className="col-12 col-lg-6 center">
                    <div className="m-top-bottom">
                        <p className="center">New to Ziptic?</p>
                        <Link to="/signup" className="text-link">
                            <div className="text-box3 border mt-3">
                                <p className="account-txt">Create New Account</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="w-100 border-top footer mb-3 ">
                <p className="footer-txt mt-3">Restore Account Via
                <Link to="/keyRestore" className="text-link"><span className="highlight-text"> Private Key</span></Link></p>
            </div>
        </div>
    );
};
export default React.memo(Login);