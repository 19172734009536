import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { Rename } from '../../service/dashboard/data-services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
    setNameChangeModal: Function,
    setCustomState : Function ,
    customState : boolean,
    targetName : string,
    id :number
}
const ConfirmModal: React.FC<IProps> = ({ 
    setNameChangeModal ,setCustomState 
    ,customState ,targetName ,id }: IProps) => {
    const [nameError, setNameError] = useState('');
    const [floderName, setFloderName] = useState(targetName.split('.').slice(0, -1).join('.'));
    const [loading, setLoading] = useState(false);
    const validate = () => {
        let nameError = "";
        floderName.trim() === '' ? nameError = "Name required" :
            nameError = "";
        if (nameError) {
            setNameError(nameError);
            return false;
        } else {
            return true;
        }
    }
    const onRename = async () => {
        const isValid = validate();
        if(floderName === targetName.split('.')[0]){
            setNameChangeModal(false);
            return
        }
        if (isValid) {
            setLoading(true);
            try {
                const response = await Rename(id, floderName );
                toast.success(response.data.response.message);
                setNameChangeModal(false)
                setCustomState(!customState)
                // window.location.reload();
            } catch (error) {
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                        return
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setNameChangeModal(false)
        }
        setLoading(false);
    }
    return (
        <Modal className="text-dark modal-pos modal-margin-top border-0" show={true}>
            <ToastContainer limit={1} />
            <div className="p-4 upload-text">
                <p className="upload-text mb-2">Rename Folder/File</p>
                <TextField
                    error={nameError !== '' ? true : false}
                    id="input-a"
                    label={nameError !== '' ? nameError : "New Name"}
                    className="mb-4 text-box-l"
                    value={floderName}
                    onChange={(evt) => {
                        if(evt.target.value.length < 100){
                            // return
                            setNameError('')
                            setFloderName(evt.target.value);
                        }
                      
                    }}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            onRename()
                        }
                    }}
                />
                <div className="float-right mt-2">
                    <button className="cancel-btn border mr-2" onClick={() => { setNameChangeModal(false) }}>
                        <p className="center ">Cancel</p>
                    </button>
                    {loading ?
                        <span className="spinner-box">
                            <span className="spinner-border spinner-color login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                        :
                        <button className="confirm-btn" onClick={() => { onRename() }} >
                            <p className="login-txt center">Rename</p>
                        </button>}
                </div>
            </div>
        </Modal>
    );
};
export default ConfirmModal;