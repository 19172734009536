import React, { useState } from "react";
import Header from "../../../components/header/index";
import { HiOutlineMail } from 'react-icons/hi';
import TextField from '@material-ui/core/TextField';
import forgotCall from '../../../service/auth-services/forgot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import InputProps from "../../../style/input-style";
import LabelProps from "../../../style/label-style";

const Forgot = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const emailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
    const [emailError, setEmailError] = useState('');
    const validate = () => {
        let emailError = "";
        email.trim() === '' ? emailError = "Email required" : (emailRegex.test(email) !== true) ?
            emailError = "Email Invalid" :
            emailError = "";
        if (emailError) {
            setEmailError(emailError);
            return false
        }
        return true;
    }
    const ForgotRequest = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setEmailError("");
            try {
                const response = await forgotCall(email);
                toast.success(response.data.response.message);
                sessionStorage.setItem('userEmail', email);
                await setTimeout(() => {
                    history.push("/forgoted");
                }, 2000);
                setEmail('');
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }            }
            setLoading(false);
        }
    }
    return (
        <div className="main-section ">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center mb-5 text1 mt-5 ml-lg-2" >Forgot Password</p>
            <div className="center main-div m-top">
                <div className="main-from">
                    <HiOutlineMail className="icon-size" />
                    <TextField
                        error={emailError !== '' ? true : false}
                        label={emailError !== '' ? emailError : "Email Address"}
                        className="div-from mb-4"
                        id="forgot-box"
                        variant="filled"
                        InputProps={InputProps}
                        InputLabelProps={LabelProps}
                        value={email} onChange={(evt) => {
                            setEmailError('')
                            setEmail(evt.target.value);
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                ForgotRequest();
                            }
                        }}
                    />
                </div>
                <button className="text-box2  mt-1 mt-lg-4" onClick={() => { ForgotRequest() }}>
                    {!loading ?
                        <p className="login-txt center">Submit</p> :
                        <><span className="spinner-border center login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="login-txt"> Loading...</span></>}
                </button>
            </div>

        </div>
    );
};
export default Forgot;