import React, { useState } from 'react';
import moment from 'moment';
import '../../style/dashboard.style.scss'
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { RecentFiles, DownloadFiles, GetAvaibaleBandwidth } from "../../service/dashboard/data-services"
import FilesViewer from '../file-viewer/viewer';
import { BsArrowReturnRight } from 'react-icons/bs';
import { formatBytes } from "../../service/dashboard/size-count"
import { getDecryptedViewableBuffer } from 'components/common-functions/crypto.function';
import { isPreviewFile, getFileType } from 'service/dashboard/file-check';
interface Iprop {
    setHeadLoader: Function
    disable: Function
    layoutType: string,
    startDownload: Function;
    setRefreshStorage: Function;
    refreshStorage: boolean;
    customState: boolean
}
const Recent: React.FC<Iprop> = ({ setHeadLoader, disable, customState, setRefreshStorage, refreshStorage, layoutType, startDownload }: Iprop) => {
    const [recentFiles, setRecentFiles] = useState<any>([]);
    const [recentloading, setRecentloading] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [hasMoreFiles, setHasMoreFiles] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState('' as any);
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [showNoti, setShowNoti] = useState(false);
    const [notifyText, setNotifyText] = useState('');
    let [filePage, setFilePage] = useState(0);

    const handlefileScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (!hasMoreFiles) {
                setFilePage(++filePage);
                getRecentFiles();
            }
        }
    }

    React.useEffect(() => {
        getRecentFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    const CheckAvaibleBandwidth = async () => {
        try {
            const res = await GetAvaibaleBandwidth()
            return res.data.response.freeBandwidth
        } catch (error) {
            console.log(error);
        }
    }

    const getRecentFiles = async () => {
        setHeadLoader(true);
        try {
            const files = await RecentFiles('FILE', filePage, 10);
            files.data.response.archive_list.map((element: any) => {
                recentFiles.push(element)
                return element;
            });
            setRecentFiles(Array.from(new Set(recentFiles)));
            setTotalFiles(files.data.response.total)
            setRecentloading(true)
            if (files.data.response.archive_list.length === 0 || files.data.response.archive_list.length < 10) {
                setHasMoreFiles(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode !== 401) {
                    toast.error("Error in Loading Folder")
                }
            }
        }
        setHeadLoader(false);
    }
   
    const updateProgress = (downlodedBytes:any,totalBytes:any) => {
        let percentage = (downlodedBytes / totalBytes) * 100;
        setProgress(parseInt(percentage.toFixed(0)))
    }

    const renderFileViewer = () => {
        switch (showModal) {
            case true:
                return <FilesViewer setShowfileViewer={setShowModal} progress={progress} url={url} fileName={fileName} customState={refreshStorage} setCustomState={setRefreshStorage} />;
        }
    };

    const startDecryption = async (obj:any) => {
        try {
            setFileName(obj.name)
            let flag = isPreviewFile(obj.name);
            let isViewable = obj.size < 26214400 ? true : false; // 25 MB file is viewable
            const freeBandwidth = await CheckAvaibleBandwidth();
            if(obj.size > freeBandwidth){
                toast.error("You don't have enough bandwidth")
                return
            }
            if(isViewable && flag){
                    setUrl('');
                    setProgress(0)
                    setShowModal(true);
                    const response = await DownloadFiles(obj.id);
                    const base64String = await getDecryptedViewableBuffer(response.data.response,updateProgress,obj.name,false)
                    setUrl(base64String);
                    return
                }
                setNotifyText(`Downloading ${fileName}`)
                    setShowNoti(true);
                    setTimeout(function () {
                        setShowNoti(false);
                    }, 2000);
                    const response = await DownloadFiles(obj.id);
                    let file = {res: response.data.response, name: fileName }
                    await startDownload(file)
                } catch (error) { console.log(error);
             }}

    const renderFiles = () => {
        return recentFiles.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr key={files.id} onClick={()=> startDecryption(files)}>
                    <td><span className={"type-text text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                        : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpge' ? 'jpg-icon'
                            : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{getFileType(files.name)}</span>{files.name.slice(0, 35)}</td>
                    <td className="right">{formatBytes(+files.size,0)}</td>
                    <td className="right">{moment(files.updated_at).format("DD MMM YYYY HH:mm")}</td>
                </tr>
            );
        })
    }
    const renderGridFiles = () => {
        return recentFiles.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr key={files.id} onClick={()=> startDecryption(files)}>
                    <div className="files-card">
                    <div className="card-img d-flex justify-content-center align-items-center"><span className={"text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpeg' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'csv-icon')))))))} >{type}</span></div>
                            <div className="card-body">
                                <div className="headings">
                                    <div className="introduction">
                                    {files.name.slice(0, 35)}
                                    </div>
                            </div>                      
                        </div>
                    </div>
                </tr>
            );
        })
    }
    return (
        <div className="tab-size">
            <ToastContainer limit={1} />
            {recentloading ?
                <div className="row w-100">
                    <div className="pl-3 col-12">
                        <p className="mt-4 folder">Files</p>
                        {totalFiles !== 0 ?
                        layoutType === 'layoutlist' ? 
                            <Table responsive="md" className="tableBodyScroll recent" onScroll={(event: any) => { handlefileScroll(event) }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="right" >Size</th>
                                        <th className="right" >Last Modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentFiles ?
                                        renderFiles() : ''}
                                </tbody>
                            </Table>:<div className="recent-grid-data" onScroll={(event: any) => { handlefileScroll(event) }}>{recentFiles ?
                                renderGridFiles() : ''}</div>
                            :
                            <p className="txt mt-4"><BsArrowReturnRight />No Files</p>}
                    </div>
                </div>
                :
                <div className="d-flex  pos-tab">
                    <div className="spinner-border spiner-xp mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
                {renderFileViewer()}
                {showNoti && <div className="p-3 notificatins"> <p className="notify-text">{notifyText}</p> </div>}
        </div>
    );
};
export const RecentComponent = React.memo(Recent);