/**
 * @description check file is image or video based on its extension
 * @param name extension name
 * @author Naeem
 */
export const isImageFile = (name: string) => {
    const imageType = [
        'JPG',
		'JPEG',
		'JPE',
		'JIF',
		'JFIF',
		'JFI',
		'PNG',
		'GIF',
		'WEBP',
		'TIFF',
		'TIF',
		'PSD',
		'RAW',
		'ARW',
		'CR2',
		'NRW',
		'K25',
		'BMP',
		'DIB',
		'HEIF',
		'HEIC',
		'INDD',
		'IND',
		'INDT',
		'JP2',
		'J2K',
		'JPF',
		'JPX',
		'JPM',
		'MJ2',
    ]
    const extension: any = name.split('.').pop()
    if (extension && imageType.includes(extension.toUpperCase())) {
        return true
    }
    return false
}

export const isVideoFile = (name: string) => {
    const videoType = [
        'MP4',
		'AVI',
		'MKV',
		'FLV',
		'MOV',
		'WMV',
		'3GP',
		'MPEG4'
    ]
    const extension: any = name.split('.').pop()
    if (extension && videoType.includes(extension.toUpperCase())) {
        return true
    }
    return false
}