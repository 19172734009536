import React, { useState } from "react";
import Header from "../../../components/header/index";
import { Modal } from 'react-bootstrap';
import { TextareaAutosize } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { sendFeedback } from "../../../service/dashboard/data-services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SendFeedBack = () => {
    const history = useHistory();
    const [feedback, setfeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [feedbackError, setfeedbackError] = useState('');
    const validate = () => {
        let keyError = "";
        feedback.trim() === '' ? keyError = "Feed Back Required" :
            keyError = "";
        if (keyError) {
            setfeedbackError(keyError);
            return false;
        } else {
            return true;
        }
    }
    const onPressClose = () => {
        setIsModalOpen(false)
        history.push("/dashboard");
    }
    const loginUserByKey = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setfeedbackError("");
            try {
                const response = await sendFeedback(feedback);
                setIsModalOpen(true)
                toast.success(response.data.response.message);
                setfeedback('');
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setLoading(false)
        }
    }
    return (
        <div className="">
            <ToastContainer limit={1} />
            <Header select={true} />
            <h2 className="center my-5 text1 font-weight-normal">Send Feedback</h2>
            <h1 className="center mt-5 mb-3 faq-txt font-weight-bold">Write your feedback</h1>
            <div className="input-section">
                <TextareaAutosize
                    className="input-box"
                    rowsMin={6}
                    aria-label="maximum height"
                    placeholder="Enter or Paste Private Key Here"
                    value={feedback}
                    onChange={(evt) => {
                        setfeedbackError('')
                        setfeedback(evt.target.value);
                    }}
                />
                <p className="feedback-error">{feedbackError}</p>
                <div className="text-right">
                    <button className="text-box2 feedback-btn mt-1 mt-lg-4" onClick={() => loginUserByKey()}>
                        {loading ?
                            <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="login-txt"> Loading...</span></>
                            :
                            <p className="login-txt center">Send</p>
                        }
                    </button>
                </div>
            </div>
            <Modal
                className="feedback-modal"
                effect="fadeInUp"
                show={isModalOpen}
                size="sm"
            >
                <div className="p-4 text-left">
                    <h1 className="title font-weight-bold">Thank you for your feedback</h1>
                    <h1 className="description">We have received your feedback. Our agent <br /> will contact you shortly via email.</h1>
                    <div className="text-right">
                        <button className="text-box2 feedback-btn-close mt-1 mt-lg-4" onClick={() => onPressClose()}>
                            <p className="login-txt center">Close</p>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default SendFeedBack;