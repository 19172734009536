import React, { useState } from 'react';

//React Icons
import { AiOutlineCloud, AiOutlineDelete, AiOutlineCamera, AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosTimer } from 'react-icons/io';
import { MdSupervisorAccount, MdContentCopy } from 'react-icons/md';
import { BiTrashAlt, BiPaste, BiInfoCircle, BiUserPlus, BiCloudUpload, BiMicrophone } from 'react-icons/bi';
import { HiOutlinePhotograph, HiOutlineTrash, HiOutlinePencil } from 'react-icons/hi';
import { RiCloseLine, RiFolderTransferLine, RiArrowDownCircleLine } from 'react-icons/ri';
import { GiBackwardTime } from 'react-icons/gi';
import { CgLayoutList, CgNotes, CgMenuGridR } from 'react-icons/cg';
import { ProgressBar, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FiFolderPlus } from 'react-icons/fi';
import { BsBoxArrowInUpRight } from 'react-icons/bs';

//Material ui
import Menu from '@material-ui/core/Menu';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import FormatPaintOutlinedIcon from '@material-ui/icons/FormatPaintOutlined';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

//services
import {
    AddFolder,
    DownloadFiles,
    DeleteStorage,
    copyOrMoveData,
    deleteTrashData,
    restoreData,
    GetFreeAvaibaleStorage,
    GetAvaibaleBandwidth,
    getAllSharedIds
} from "../../../service/dashboard/data-services";

//components
import {CloudComponent} from "../../../components/cloud/cloud";
import {RecentComponent} from "../../../components/recent/recent";
import {SharedDataComponent} from "../../../components/shared-data/shared";
import {TrashDataComponent} from "../../../components/trash-data/trash";
import {GalleryComponent} from "../../../components/gallery/gallery";
import NoteComponent from '../../../components/notes/notes';
import PlusLogo from "../../../assets/Create.png";
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../../components/header/index";
import InfoComponent from "../../../components/folder-info/info";
import SharingComponent from "../../../components/file-share/sharing";
import RenameComponent from "../../../components/rename-folder/rename";
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import '../../../style/dashboard.style.scss';
import { getDecryptedBuffer } from 'components/common-functions/crypto.function';
import DownloadPage from 'components/file-viewer/file-download';
import {formatBytes} from 'service/dashboard/size-count';
import RecorderComponent from 'components/voice-recording/recorder';
import PasswordModal from 'components/cloud/password-modal/password-modal';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const DashboardComponent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [authFlag, setAuthFlag] = useState(false);
    const [selectedOption, setSelectedOption] = useState('cloud');
    const [preSelectedOption, setPreSelectedOption] = useState('cloud');
    const [floderName, setFloderName] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [trashFlag, setTrashFlag] = useState(false);
    const [percentage, setPercentage] = useState(-2);
    const [currentFileName, setCurrentFileName] = useState('');
    const [customState, setCustomState] = useState(false);
    const [refreshStorage, setRefreshStorage] = useState(false);
    let [arraylength, setArraylength] = useState(1);
    let [count, setCount] = useState(1);
    let [folderParentId, setFolderParentId] = useState(0);
    let [folderDirType, setFolderDirType] = useState('ROOT');
    const [showMenu, setShowMenu] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');
    let [selectedFile, setSelectedFile] = useState<any>({});
    const [showNoti, setShowNoti] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [showRecorder, setShowRecorder] = useState(false);
    const [showWebCam, setshowWebCam] = useState(false);
    const [notifyText, setNotifyText] = useState('');
    const [pasteLoader, setPasteLoader] = useState(false);
    const [txtUpload, setTxtUpload] = useState('' as any);   
    const [voiceUpload, setVoiceUpload] = useState('' as any);     
    
    $(document).ready(function () {
        $(".tab").click(function () {
            $(".tab").removeClass("active");
            $(this).addClass("active");
        });
    });
    $(document).ready(function () {
        $("#browse").click(function () {
            $("#browser").click();
        });
        $("#dropzone").css({'height':window.outerHeight})
    });
    $(document).click((event) => {
        if (!$(event.target).closest('#upload-box').length) {
          // the click occured outside '#upload-box'
          setShowModal(false)
        }        
      });

    const [showModal, setShowModal] = useState(false);
    const [nameError, setNameError] = useState('');
    const [id, setID] = useState(0);
    const [sharedIds, setSharedIds] = useState<any>([]);
    const [idArray, setIDArray] = useState<any>([]);
    const [fileNames, setFileNames] = useState<any>([]);
    const [targetName, setTargetName] = useState('');
    const [downloadFileData, setDownloadFileData] = useState({name:'',size:'0',downloaded:'0', progress:0});
    const [headLoader, setHeadLoader] = useState(false);
    const [currentTheme, setCurrentTheme] = useState('true');
    const [storage, setStorage] = useState({total:0,used:0,free:0});
    const [bandwidth, setBandwidth] = useState({total:0,used:0,free:0});
    // eslint-disable-next-line
    const [layoutType, setlayoutType] = useState('layoutlist');
    const [isFetching,setFetching] = useState(false);

    const AntSwitch = withStyles((theme: Theme) =>
        createStyles({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }),
    )(Switch);

    const handleLayoutClick = () => {
        const layout = localStorage.getItem('layout');
        if (layout === 'layoutgrid') {
            setlayoutType('layoutlist');
            localStorage.setItem("layout", 'layoutlist');
        } else {
            setlayoutType('layoutgrid');
            localStorage.setItem("layout", 'layoutgrid');
        }
    }

    React.useEffect(() => {
        // if(isFetching){
        // window.addEventListener('beforeunload', alertUser)
        // window.addEventListener('unload', handleTabClosing)
        // }
        // return () => {
        //     window.removeEventListener('beforeunload', alertUser)
        //     window.removeEventListener('unload', handleTabClosing)
        // }
    },[isFetching])
    
    // const handleTabClosing = () => {
    //     // if(window.confirm('Hello')){
            
    //     // }
    // }
    
    // const alertUser = (event:any) => {
    //     event.preventDefault()
    //     event.returnValue = ''
    // }

    React.useEffect(() => {
        let status,layout: any;
        status = localStorage.getItem('isDarkTheme');
        setCurrentTheme(status ? status : 'false');
        layout = localStorage.getItem('layout');
        setlayoutType(layout ? layout : 'layoutlist')
        CheckAvaibleStorage();
        CheckAvaibleBandwidth();
        getSharedIds();
    }, []);

    React.useEffect(()=>{
        CheckAvaibleStorage();
        CheckAvaibleBandwidth();
    },[refreshStorage])

    const[query, setQuery] = useState('');
    const[toogleSearch, setToogleSearch] = useState(false);

    const onSetQuery = (val: string) => {
        val.length === 0 && window.location.reload();
        setQuery(val);
    }

    const onPressSearch = () => {
        setToogleSearch(!toogleSearch);
        setPreSelectedOption(selectedOption);
        selectedOption !== 'shared' ? setSelectedOption('cloud') : setSelectedOption('shared');
        commonAction();
        setTrashFlag(false);
    }

    const getSharedIds = async () => {
        try {
            const res = await getAllSharedIds();
            setSharedIds(res.data.response);            
        } catch (error) {
            console.log(error);
        }
    }
 
    const CheckAvaibleStorage = async () => {
        try {
            const res = await GetFreeAvaibaleStorage()
            let free = parseInt(res.data.response.freeStorage);
            let total = parseInt(res.data.response.totalStorage);
            let used = total - free;            
            setStorage({ free, total, used})
        } catch (error) {
            console.log(error);
        }
    }    

    const CheckAvaibleBandwidth = async () => {
        try {
            const res = await GetAvaibaleBandwidth()
            let free = parseInt(res.data.response.freeBandwidth);
            let total = parseInt(res.data.response.totalBandwidth);
            let used = total - free;            
            setBandwidth({ free, total, used})
        } catch (error) {
            console.log(error);
        }
    }

    const themeChange = () => {
        const isDarkTheme = localStorage.getItem('isDarkTheme');
        if (isDarkTheme === 'true') {
            setCurrentTheme('false');
            localStorage.setItem("isDarkTheme", 'false');
        } else {
            setCurrentTheme('true');
            localStorage.setItem("isDarkTheme", 'true');
        }

        let DarkMode: any
        DarkMode = document.getElementById("light")?.classList.toggle("dark")
        localStorage.setItem("isDarkTheme", DarkMode)
    }

    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
    }, [authFlag, history]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorElShare, setAnchorElShare] = React.useState(null);
    const handleShareClick = (event: any) => {
        setAnchorElShare(event.currentTarget);
        setAnchorElShare(event.currentTarget);
    };
    const handleShareClose = () => {
        setAnchorElShare(null);
    };
    const [anchorElGallery, setAnchorElGallery] = React.useState(null);
    const handleGalleryClick = (event: any) => {
        setAnchorElGallery(event.currentTarget);
        setAnchorElGallery(event.currentTarget);
    };
    const handleGalleryClose = () => {
        setAnchorElGallery(null);
    };
    const renderUploadPopup = () => {
        switch (showModal) {
            case true:
                return (
                    <div id="upload-box" className="text-dark modal-postion  mt-5 border-0">
                        <p className="popup-text center py-3 border-bottom">Upload</p>
                        <div className="p-3 pl-4 upload-text">
                            <p className="mb-3" id="browse"><BiCloudUpload className="upload-icon mr-3" />From Device</p>
                            <p className="mb-3" onClick={() => { setShowModal(false); setshowWebCam(true) }}><AiOutlineCamera className="upload-icon mr-3" />Take Picture</p>
                            <p className="mb-3" onClick={() => { setShowDialog(true); setShowModal(false) }}><FiFolderPlus className="upload-icon mr-3" />Create New Folder</p>
                            <p className="mb-3" onClick={() => { setShowModal(false); setShowNote(true) }}><CgNotes className="upload-icon mr-3" />Create New Notes</p>
                            <p className="mb-2" onClick={() => { setShowModal(false); setShowRecorder(true) }}><BiMicrophone className="upload-icon mr-3" />Create New Voice Note</p>
                            <BsBoxArrowInUpRight className="close-menu" onClick={() => { setShowModal(false) }} />
                        </div>
                    </div>
                );
        }
    };
    const [nameChangeModal, setNameChangeModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [copyMoveFlag, setCopyMoveFlag] = useState(false);
    const [pasteType, setPasteType] = useState('')
    const [stopUpload, setStopUpload] = useState(false);
    const [stopDownload, setStopDownload] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordModal, setPasswordModal] = useState(false);
    const [downloadController, setDownloadController] = useState('' as any);
    const [fromId, setFromId] = useState<any>([]);
    const [toId, setToId] = useState(0);
    const renderNameChange = () => {
        switch (nameChangeModal) {
            case true:
                return (
                    <RenameComponent
                        setNameChangeModal={setNameChangeModal}
                        setCustomState={setCustomState}
                        customState={customState}
                        targetName={targetName}
                        id={id}
                    />
                );
        }
    };
    const renderDownloadModal = () => {
        switch (downloadModal) {
            case true:
                return (
                    <DownloadPage
                        data={downloadFileData}
                        setFetching={setFetching}
                        setModalVisible={setDownloadModal}
                        isModal={downloadModal}
                        setStopDownload={setStopDownload}
                        controller={downloadController}
                    />
                );
        }
    };
    const renderDialog = () => {
        switch (showDialog) {
            case true:
                return (
                    <Modal className="text-dark modal-pos modal-margin-top border-0" show={true}>
                        <div className="p-4 upload-text">
                            <p className="upload-text mb-2">New Folder</p>
                            <TextField
                                error={nameError !== '' ? true : false}
                                id="input-a"
                                label={nameError !== '' ? nameError : "New Folder"}
                                className="mb-4 text-box-l"
                                value={floderName} onChange={(evt) => {
                                    setNameError('')
                                    setFloderName(evt.target.value);
                                }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        onCreateNewFolder()
                                    }
                                }}
                            />
                            <div className="float-right mt-2">
                                <button className="cancel-btn border mr-2" onClick={() => { setShowDialog(false) }}>
                                    <p className="center ">Cancel</p>
                                </button>
                                {loading ?
                                    <span className="spinner-box">
                                        <span className="spinner-border spinner-color login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </span>
                                    :
                                    <button className="confirm-btn" onClick={() => { onCreateNewFolder() }} >
                                        <p className="login-txt center">Create</p>
                                    </button>}
                            </div>
                        </div>
                    </Modal>
                );
        }
    };
    const renderShareMenuPopup = () => {
        return (
            <Menu
                className="shadow-sm"
                id="simple-menu"
                anchorEl={anchorElShare}
                open={Boolean(anchorElShare)}
                onClose={handleShareClose}
                keepMounted
                PaperProps={{
                    style: {
                        left: '1000px',
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="p-3 pl-4 upload-text cursor">
                    <p onClick={() => {
                        DownloadFilById();
                        handleShareClose();
                        setShowModal(false)
                    }}><RiArrowDownCircleLine className="upload-icon mr-3" />Download</p>
                </div>
            </Menu>);
    };
    
    const renderMenuPopup = () => {
        return (
            <Menu
                className="shadow-sm"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                PaperProps={{
                    style: {
                        left: '1000px',
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <div className="p-3 pl-4 upload-text cursor">
                    {idArray.length > 1 ? '' : <p className="pb-3" onClick={() => {
                        if (selectedSection === 'info') {
                            handleClose()
                            setSelectedSection('');
                        } else {
                            setSelectedSection('info');
                            handleClose()
                        }
                        setShowModal(false)
                    }}><BiInfoCircle className="upload-icon mr-3" />Information</p>}
                    <p className="pb-3" onClick={() => {
                        if (selectedSection === 'share') {
                            handleClose()
                            setSelectedSection('');
                            setTimeout(function () {
                                setSelectedSection('share');
                            }, 230);
                        } else {
                            setSelectedSection('share');
                            handleClose()
                        }
                        setShowModal(false)
                    }}><BiUserPlus className="upload-icon mr-3" />Share</p>
                    {idArray.length > 1 ? '' : <p className="pb-3" onClick={() => {
                        setSelectedSection('');
                        handleClose();
                        setNameChangeModal(true)
                        setShowModal(false)
                    }}><HiOutlinePencil className="upload-icon mr-3" />Rename</p>}
                    <p className="pb-3"
                        onClick={() => {
                            sessionStorage.setItem('fromIds', JSON.stringify(idArray))
                            setPasteType('MOVE');
                            movefiles();
                            handleClose();
                            setShowModal(false)
                        }}
                    ><RiFolderTransferLine className="upload-icon mr-3" />Move</p>
                    <p className="pb-3"
                        onClick={() => {
                            sessionStorage.setItem('fromIds', JSON.stringify(idArray))
                            setPasteType('COPY');
                            movefiles();
                            handleClose();
                            setShowModal(false)
                        }}
                    ><MdContentCopy className="upload-icon mr-3" />Copy</p>
                    {targetName.includes('.') &&
                    <p className="pb-3" onClick={() => {
                        DownloadFilById();
                        handleClose();
                        setShowModal(false)
                    }}><RiArrowDownCircleLine className="upload-icon mr-3" />Download</p>}
                    <p onClick={() => {
                        DeleteStorageById();
                        handleClose();
                        setShowModal(false)
                    }}><AiOutlineDelete className="upload-icon mr-3" />Move to Trash</p>
                </div>
            </Menu>);
    };
    const validate = () => {
        let nameError = "";
        floderName.trim() === '' ? nameError = "Name required" :
            nameError = "";
        if (nameError) {
            setNameError(nameError);
            return false;
        } else {
            return true;
        }
    }
    const onCreateNewFolder = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            try {
                const response = await AddFolder('DIR', folderDirType, floderName, folderParentId, 1);
                if(response.data.response.message === 'created successfully!')
                toast.success('Folder Created Successfully')
                else
                toast.success(response.data.response.message);
                setShowDialog(false);
                setFloderName('')
                setCustomState(!customState)
                // window.location.reload();
            } catch (error) {
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                        setAuthFlag(true);
                        return
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setShowDialog(false)
        }
        setLoading(false);
    }

    const updateProgress = (downlodedBytes:any,totalBytes:any, fileName:any, isDone: Boolean) => {
        if(isDone){
            setFetching(false)
            setTimeout(() => {
                setDownloadModal(false)
                setRefreshStorage(!refreshStorage)
            }, 3500);
        }
        setDownloadFileData({
         name: fileName,
         size: formatBytes(totalBytes),
         downloaded: formatBytes(downlodedBytes),
         progress: (downlodedBytes / totalBytes) * 100
        })
    }


    const DownloadFilById = async () => {
        try {
            const response = await DownloadFiles(id, selectedOption === 'shared' ? selectedFile.shared_by.email : undefined);
            setNotifyText('Download started...')
            setShowNoti(true);
            let file = {
                res: selectedOption === 'shared' ? { ...response.data.response, pass_enc_key: selectedFile.key } : response.data.response,
                name: targetName, pass_enc_key: selectedFile.key }
            await startDownload(file)
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet) ")
            }
        }
    }
    
    const abortDownloading = (controller:any) => {
        setDownloadController(controller);
    }
      
      async function startDownload(file:any) {
        setTimeout(function () {
            setShowNoti(false);
            setDownloadModal(true)
        }, 3000);
        setFetching(true);
            try {
                const res = await getDecryptedBuffer(file.res,updateProgress,file.name, selectedOption === 'shared' ? true : false,true,abortDownloading);
                return res
            } catch (error) {
                return error
            }
      }

    const DownloadFilesById = async () => {
        try {
            setShowNoti(true);
            setNotifyText(`${idArray.length} items will be downloaded. See notification for details`)
            let files = idArray.map((val:any,i:number)=>{
                return {id:val,name:fileNames[i]}
            })
            for (const file of files) {
            const response = await DownloadFiles(file.id);
            let temp = {...file, res : response.data.response}
            const result = await startDownload(temp);
            if(result === 'Aborted') continue 
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                if(error === 'Aborted')
                console.log(error);
                else
                toast.info("Network Error (Check Your Internet)")
            }
        }
    }
    const DeleteStorageById = async () => {
        try {
            const response = await DeleteStorage(idArray);
            if (response) {
                setSelectedSection('');
                if (response.data.response.message === 'deleted successfully!') {
                    setNotifyText('Selected File/Folder is deleted successfully!')
                    setRefreshStorage(!refreshStorage)
                    setShowNoti(true);
                    setTimeout(function () {
                        setShowNoti(false);
                    }, 4000);
                    setCustomState(!customState);
                    setShowMenu(false);
                }
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
    }

    const onSubmitPassword = async () => {
        setPasteLoader(true);
        const fromArray = JSON.parse(sessionStorage.getItem('fromIds') || '');
        console.log("To => " + toId + 'From => ' + fromArray)
        try {    
            const response = await copyOrMoveData(fromArray, toId, pasteType,password);             
            if (response.data.response.message === 'updated successfully!') {
                setCopyMoveFlag(false)
                setCustomState(!customState)
                setNotifyText('File/Folder ' + pasteType + ' Successfully');
                setShowNoti(true);
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
                sessionStorage.removeItem('fromIds')
                setTimeout(function () {
                    setShowNoti(false);
                }, 4000);
            }
            else{
                toast.success(response.data.response.message)
                setCopyMoveFlag(false)
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
            }
        } catch (error) {
            setCopyMoveFlag(false)
            if (error && error.response && error.response.data) {
                if (error.response.data.message === 'Storage already exist in this directory') {
                    toast.error("File Already Exists");

                    setPasteLoader(false);
                    return
                }
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setPasteLoader(false);
    };

    const renderPasswordModal = () => {
        switch (passwordModal) {
            case true:
                return (
                    <PasswordModal
                        header={`You are ${pasteType.toLowerCase() === 'move' ? 'mov' : 'copy'}ing files in a shared folder. Please enter your password:`}
                        setModal={setPasswordModal}
                        onSubmitPassword={onSubmitPassword}
                        onPressCancel={()=>setPasswordModal(false)}
                        setSelectedSection={setSelectedSection}
                        setPassword={setPassword}
                        password={password}
                    />
                );
        }
    };  

    const commonAction = () => {
        setSelectedSection('');
        setShowMenu(false);
        setShowModal(false)
        setRestoreFlag(false);
        setDeleteFlag(false);
        closeCopy()
    }
    const movefiles = async () => {
        idArray.length = 0;
        setIDArray(idArray)
        setCopyMoveFlag(true)
    }

    const cancelUpload = () => {
        if(window.confirm('Are you sure you want to cancel upload?'))
        setStopUpload(true)
    }


    const paste = async () => {
        setPasteLoader(true);
        const fromArray = JSON.parse(sessionStorage.getItem('fromIds') || '');
        const isShared = sharedIds.includes(toId);
        if(isShared){
            setPasswordModal(true);
            setPasteLoader(false)
            return
        }
        try {    
            const response = await copyOrMoveData(fromArray, toId, pasteType); 
            if (response.data.response.message === 'updated successfully!') {
                setCopyMoveFlag(false)
                setCustomState(!customState)
                setNotifyText('File/Folder ' + pasteType + ' Successfully');
                setShowNoti(true);
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
                sessionStorage.setItem('fromIds',JSON.stringify([]))
                sessionStorage.removeItem('fromIds')
                setTimeout(function () {
                    setShowNoti(false);
                }, 4000);
            }
            else{
                toast.success(response.data.response.message)
                setCopyMoveFlag(false)
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
                sessionStorage.setItem('fromIds',JSON.stringify([]))
            }
        } catch (error) {
            setCopyMoveFlag(false)
            sessionStorage.setItem('fromIds',JSON.stringify([]))
            if (error && error.response && error.response.data) {
                if (error.response.data.message === 'Storage already exist in this directory') {
                    toast.error("File Already Exists");

                    setPasteLoader(false);
                    return
                }
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setPasteLoader(false);
    }
    const closeCopy = () => {
        sessionStorage.setItem('fromIds',JSON.stringify([]))
        setCopyMoveFlag(false);
        fromId.length = 0;
        setFromId(fromId);
        setToId(0);
    }
    const [deleteFlag, setDeleteFlag] = useState(false);
    const deleteTrashedData = async () => {
        setHeadLoader(true);
        try {
            const response = await deleteTrashData(idArray);
            if (response.data.response.message === 'deleted successfully!') {
                setDeleteFlag(false);
                setRestoreFlag(false);
                setRefreshStorage(!refreshStorage)
                setCustomState(!customState);
                setNotifyText('Trash Storage delete Sucessfully');
                setShowNoti(true);
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
                setTimeout(function () {
                    setShowNoti(false);
                }, 4000);
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        setDeleteFlag(false);
    }
    
    const [restoreFlag, setRestoreFlag] = useState(false);
    const restoreTrashedData = async () => {
        setHeadLoader(true);
        try {
            const response = await restoreData(idArray);
            if (response.data.response.message === 'Storage Restored Sucessfully') {
                setRestoreFlag(false);
                setDeleteFlag(false)
                setCustomState(!customState);
                setNotifyText('Storage Restored Sucessfully');
                setShowNoti(true);
                fromId.length = 0;
                setFromId(fromId);
                setToId(0)
                setTimeout(function () {
                    setShowNoti(false);
                }, 4000);
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true);
                    return
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        setRestoreFlag(false);
    }
    const hideUploadOptions = () => {
        setShowNote(false);
        setShowRecorder(false);
        setshowWebCam(false);
    }
    //disable click when Api Calling
    function disable() {
        $("#div2").attr("disabled", "disabled").off('click');
        var x1 = $("#div2").hasClass("disabledDiv");
        (x1 === true) ? $("#div2").removeClass("disabledDiv") : $("#div2").addClass("disabledDiv");
    }
    const renderGalleryMenuPopup = () => {
        return (
            <Menu
                className="shadow-sm"
                id="simple-menu"
                anchorEl={anchorElGallery}
                PaperProps={{
                    style: {
                        marginTop: "60px",
                        left: '1000px',
                        top:'179px'
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElGallery)}
                onClose={handleGalleryClose}
            >
                <div className="p-3 pl-4 upload-text cursor">
                    <p className="pb-3" onClick={() => {
                        if (selectedSection === 'share') {
                            handleGalleryClose()
                            setSelectedSection('');
                            setTimeout(function () {
                                setSelectedSection('share');
                            }, 230);
                        } else {
                            setSelectedSection('share');
                            handleGalleryClose()
                        }
                    }}><BiUserPlus className="upload-icon mr-2" />Share</p>
                    <p className="pb-3" onClick={() => {
                        setSelectedSection('');
                        handleGalleryClose();
                        setNameChangeModal(true)
                    }}><HiOutlinePencil className="upload-icon mr-2" />Rename</p>
                    <p className="pb-3" onClick={() => {
                        DownloadFilById();
                        handleGalleryClose();
                    }}><RiArrowDownCircleLine className="upload-icon mr-2" />Download</p>
                    <p onClick={() => {
                        DeleteStorageById();
                        handleGalleryClose();
                    }}><AiOutlineDelete className="upload-icon mr-2" />Move to Trash</p>
                </div>
            </Menu>);
    };
    return (
        <div className="pb-1">
            <ToastContainer />
            <Header select={true} query={query} setQuery={onSetQuery} onPressSearch={onPressSearch} headLoader={headLoader} pasteLoader={pasteLoader} />
            <div className={pasteLoader ? "disabledDiv" : ""}>
            {copyMoveFlag ?
                <span className="float-right pt-1 cursor mr-4 ">
                    <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                    <BiPaste className="header-icons" onClick={() => {
                        paste()
                        }} data-tip="Paste" />
                    <RiCloseLine className="header-icons" data-tip="Close" onClick={() => {
                        closeCopy()
                        idArray.length = 0;
                        setIDArray(idArray);
                    }} />
                </span> :
                <span className="float-right py-1 cursor mr-4">
                    {showMenu ?
                        <><BiUserPlus className="header-icons" data-tip="Share" onClick={() => {
                            selectedSection === 'share' ? setSelectedSection('') : setSelectedSection('share')
                            setShowModal(false)
                        }} />
                            {idArray.length > 1 ? '' : <HiOutlinePencil className="header-icons" data-tip="Rename" onClick={() => {
                                setSelectedSection('');
                                handleClose();
                                setNameChangeModal(true)
                                setShowModal(false)
                            }} />}
                            <RiFolderTransferLine className="header-icons" data-tip="Move"
                                onClick={() => {
                                    sessionStorage.setItem('fromIds', JSON.stringify(idArray))
                                    setPasteType('MOVE');
                                    movefiles();
                                    setShowModal(false)
                                }}
                            />
                            <MdContentCopy className="header-icons" data-tip="Copy"
                                onClick={() => {
                                    sessionStorage.setItem('fromIds', JSON.stringify(idArray))
                                    setPasteType('COPY');
                                    movefiles();
                                    setShowModal(false)
                                }}
                            />
                            <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                            {!fileNames.some((name:string)=> !name.includes('.')) &&
                            <RiArrowDownCircleLine className="header-icons" onClick={() => {
                                DownloadFilesById();
                                setShowModal(false)
                            }} data-tip="Download" />}
                            <AiOutlineDelete className="header-icons mr-5" onClick={() => {
                                DeleteStorageById(); setShowModal(false)
                            }} data-tip="Delete" /></> : ''}
                    {selectedOption !== 'gallery' && (layoutType === 'layoutlist' ?
                        <CgLayoutList className="header-icons" onClick={() => handleLayoutClick()} />
                        :
                        <CgMenuGridR className="header-icons" onClick={() => handleLayoutClick()} />)
                    }
                    {/* <CgMenuGridR className="header-icons" data-tip="Layout" /> */}
                    {/* <BiDotsVerticalRounded className="header-icons" /> */}
                    {showMenu && idArray.length < 2 ? <BiInfoCircle className="header-icons" onClick={() => {
                        selectedSection === 'info' ? setSelectedSection('') : setSelectedSection('info')
                        setShowModal(false)
                    }} data-tip="Info" /> : ''}
                </span>}
            {trashFlag ?
                <span className="float-right pt-1 cursor ">
                    {deleteFlag ? <>
                        <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                        <HiOutlineTrash className="header-icons" data-tip="Delete" onClick={() => { deleteTrashedData() }} /></> : ''}
                    {restoreFlag ? <>
                        <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                        <GiBackwardTime className="header-icons" data-tip="Restore Storage" onClick={() => { restoreTrashedData() }} /></> : ''}

                </span> : ''}
            {percentage === -2 ? '' :
            <>
                <div><span className="up-txt bar-text">{currentFileName}</span>
                    <span className="down-txt bar-text">{count} of {arraylength} {arraylength === 1 ? 'File' : 'Files'}</span>
                     <ProgressBar now={percentage} className="bar bar-width bar-pos float-right " />
                     <AiOutlineCloseCircle className="bar-cancel-pos float-right" size={20} color={'#5e47d4'} onClick={cancelUpload} />
                    </div></>
            }
            <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
            <div className="flex justify-content-between w-100 mt-4 ">
                {/* <!-- Sidebar --> */}
                <div id="div2" className="list-group sidebar f-1 list-group-flush">
                    <div className="tab active list-group-item list-group-item-action " onClick={() => {
                        setTrashFlag(false);
                        if (selectedOption === 'cloud') {
                            window.location.reload();
                            setShowMenu(false)
                        } else {
                            setSelectedOption('cloud')
                            setQuery('');
                        }
                    }}> <AiOutlineCloud className="sidebar-icons" />Cloud</div>
                    <div className="list-group-item list-group-item-action tab" onClick={() => {
                        setSelectedOption('recent');
                        commonAction();
                        setTrashFlag(false);
                    }}><IoIosTimer className="sidebar-icons" />Recents</div>
                    <div className="list-group-item list-group-item-action tab" onClick={() => {
                        if (selectedOption === 'shared') {
                            setCustomState(!customState)
                            setShowMenu(false)
                        } else {
                            setSelectedOption('shared')
                        }
                        setQuery('');
                        commonAction();
                        setTrashFlag(false);
                    }}><MdSupervisorAccount className="sidebar-icons" />Shared</div>
                    <div className="list-group-item list-group-item-action tab" onClick={() => {
                        setSelectedOption('gallery');
                        commonAction();
                        setTrashFlag(false);
                    }}><HiOutlinePhotograph className="sidebar-icons" />Gallery</div>
                    {/* <div className="list-group-item list-group-item-action tab" onClick={() => {
                        setSelectedOption('search');
                        commonAction();
                        setTrashFlag(false);
                    }}><HiOutlinePhotograph className="sidebar-icons" />Search</div> */}
                    <div className="list-group-item list-group-item-action tab" onClick={() => {
                        setSelectedOption('trash');
                        commonAction();
                        setTrashFlag(false);
                    }}><BiTrashAlt className="sidebar-icons" />Trash</div>
                    <div className="bor-top mt-2">
                 <div className="list-group-item-action mt-2"><StorageIcon className="help-icon mt-1" />Storage ({ storage.total > 0 ? ((storage.used/storage.total)*100).toFixed(0) : '0' }% Full)
                        <ProgressBar now={(storage.used/storage.total)*100} className="bar mt-2" />
                            <span className="bar-text">{formatBytes(storage.used,1)} of {formatBytes(storage.total)} used</span>
                        </div>

                        <div className="list-group-item-action "><NetworkCheckIcon className="help-icon" />Band Width
                        <ProgressBar now={(bandwidth.used/bandwidth.total)*100} className="bar mt-2" />
                            <span className="bar-text">{formatBytes(bandwidth.used)} of {formatBytes(bandwidth.total)} used</span>
                        </div>
                        <div className="center mb-2">
                            <button className="up-btn" onClick={() => { history.push('/selectPlan') }}>
                                <p className="btn-text center">Upgrade Plan</p>
                            </button>
                        </div>
                    </div>
                    <div className="bor-top mt-2">
                        <div className="list-group-item-action mt-2 list-group-item tab">
                            <FormatPaintOutlinedIcon className="help-icon" />
                            Theme
                            <div className='float-right d-flex justify-content-end'>
                            <span className="float-right">
                            Light
                            <label className="mx-auto">
                            <AntSwitch checked={currentTheme === 'true'} onClick={() => themeChange()} />
                            </label>Dark
                            </span>    
                            </div>
                        </div>
                        <div className="list-group-item-action list-group-item tab" onClick={() => {
                            history.push("/help"); setShowMenu(false)
                        }}><LiveHelpOutlinedIcon className="help-icon" />Help & Support</div>
                        <div className="list-group-item-action list-group-item tab" onClick={() => {
                            history.push("/profile"); setShowMenu(false)
                        }}><SettingsIcon className="help-icon" />Settings</div>
                        <div className="list-group-item-action list-group-item tab" onClick={() => { setAuthFlag(true) }}><PowerSettingsNewIcon className="help-icon" />Logout</div>
                    </div>
                </div>
                {/* <!-- /# popup for plus Menu and add folder --> */}
                {renderUploadPopup()}
                {renderMenuPopup()}
                {renderNameChange()}
                {renderDownloadModal()}
                {renderDialog()}
                {renderGalleryMenuPopup()}
                {renderShareMenuPopup()}
                {/* <!-- Page Content for Cloud and recent etc--> */}
                <div className={'w-100 ' + (showMenu ? 'border-top mt-2' : '')}>
                    {selectedOption === 'cloud' && selectedSection === '' ? <img src={PlusLogo} alt="" className="plus-icon" id="upload-box" data-tip="Upload" onClick={() => {
                        if(!isFetching) setShowModal(true);
                        else {
                            setNotifyText('Please wait your file is being uploaded! ')
                            setShowNoti(true);
                            setTimeout(() => {
                                setShowNoti(false)
                                setNotifyText('')
                            }, 900);
                        }
                    }} /> : ''}
                    {showNoti ?
                        <div className="p-3 notificatins">
                            <p className="notify-text">{notifyText}</p>
                        </div> : ''}
                    {selectedOption === 'cloud' ?
                        <CloudComponent
                            setShowModal={setShowModal}
                            query={query}
                            toogleSearch={toogleSearch}
                            preSelectedOption={preSelectedOption}
                            percentage={percentage}
                            setSelectedOption={setSelectedOption}
                            setPercentage={setPercentage}
                            hideUploadOptions={hideUploadOptions}
                            setCurrentFileName={setCurrentFileName}
                            setCount={setCount}
                            arrayLength={arraylength}
                            setArraylength={setArraylength}
                            count={count}
                            txtUpload={txtUpload}
                            voiceUpload={voiceUpload}
                            stopUpload={stopUpload}
                            setStopUpload={setStopUpload}
                            stopDownload={stopDownload}
                            setStopDownload={setStopDownload}
                            setFolderParentId={setFolderParentId}
                            setFolderDirType={setFolderDirType}
                            setRefreshStorage={setRefreshStorage}
                            refreshStorage={refreshStorage}
                            setCustomState={setCustomState}
                            customState={customState}
                            setAnchorEl={setAnchorEl}
                            handleClick={handleClick}
                            setShowMenu={setShowMenu}
                            getSharedIds={getSharedIds}
                            id={id}
                            setID={setID}
                            setToId={setToId}
                            setTargetName={setTargetName}
                            setHeadLoader={setHeadLoader}
                            disable={disable}
                            sharedIds={sharedIds}
                            isFetching={isFetching}
                            setFetching={setFetching}
                            setIDArray={setIDArray}
                            setFileNames={setFileNames}
                            idArray={idArray}
                            fileNames={fileNames}
                            copyMoveFlag={copyMoveFlag}
                            layoutType={layoutType}
                            setshowWebCam={setshowWebCam}
                            showWebCam={showWebCam}
                            setSelectedSection={setSelectedSection}
                            startDownload={startDownload}
                        />
                        :
                        (selectedOption === 'recent' ? <RecentComponent setHeadLoader={setHeadLoader} disable={disable}  customState={customState} refreshStorage={refreshStorage} setRefreshStorage={setRefreshStorage} startDownload={startDownload} layoutType={layoutType} /> : (selectedOption === 'shared' ?
                            <SharedDataComponent setHeadLoader={setHeadLoader} startDownload={startDownload} query={query} toogleSearch={toogleSearch} disable={disable} setTargetName={setTargetName} setID={setID} setIDArray={setIDArray} setSelectedFile={setSelectedFile} handleClick={handleShareClick} preSelectedOption={selectedOption} setShowMenu={setShowMenu} setFolderParentId={setFolderParentId} customState={customState} setCustomState={setCustomState} layoutType={layoutType} refreshStorage={refreshStorage} setRefreshStorage={setRefreshStorage}/> :
                            (selectedOption === 'trash' ?
                                <TrashDataComponent
                                    setHeadLoader={setHeadLoader}
                                    setID={setID}
                                    setTargetName={setTargetName}
                                    setFolderParentId={setFolderParentId}
                                    setFolderDirType={setFolderDirType}
                                    setTrashFlag={setTrashFlag}
                                    setDeleteFlag={setDeleteFlag}
                                    customState={customState}
                                    setRestoreFlag={setRestoreFlag}
                                    handleClick={handleClick}
                                    disable={disable}
                                    setIDArray={setIDArray}
                                    idArray={idArray}
                                    layoutType={layoutType}
                                /> :
                                (selectedOption === 'gallery' ?
                                    <GalleryComponent
                                        setHeadLoader={setHeadLoader}
                                        disable={disable}
                                        percentage={percentage}
                                        setAnchorElGallery={setAnchorElGallery}
                                        handleGalleryClick={handleGalleryClick}
                                        setID={setID}
                                        setTargetName={setTargetName}
                                        setIDArray={setIDArray}
                                        idArray={idArray}
                                        refreshStorage={refreshStorage}
                                        setRefreshStorage={setRefreshStorage}
                                        customState={customState}
                                    />
                                    : ''
                                )
                            )
                        )
                    )}
                </div>
                {renderPasswordModal()}
                {/* third section is start here  */}
                {selectedSection === 'info' ? <InfoComponent id={id} setSelectedSection={setSelectedSection} /> : selectedSection === 'share' ? <SharingComponent id={id} idArray={idArray} setIDArray={setIDArray} setCustomState={setCustomState} customState={customState} setSelectedSection={setSelectedSection} /> : ''}
                {/* third section end here */}
                {showNote && <NoteComponent setShowNote={setShowNote} setUpload={setTxtUpload} dirType={folderDirType} parent_id={folderParentId} setSelectedSection={setSelectedSection} customState={customState} setCustomState={setCustomState} setPercentage={setPercentage} setFileName={setCurrentFileName} />}
                {/* {showWebCam && <WebCamPop setshowWebCam={setshowWebCam} />}                 */}
                {showRecorder && <RecorderComponent setShowRecorder={setShowRecorder} setUpload={setVoiceUpload} dirType={folderDirType} parent_id={folderParentId} setSelectedSection={setSelectedSection} customState={customState} setCustomState={setCustomState} setPercentage={setPercentage} setFileName={setCurrentFileName} />}
            </div>
            </div>
        </div>
    );
};

export const Dashboard = React.memo(DashboardComponent);