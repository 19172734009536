import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import TextField from "@material-ui/core/TextField";
import CryptoJS from "crypto-js";
import { getPassHash } from "components/common-functions/crypto.function";

interface IProps {
  setModal: Function;
  setSelectedSection: Function;
  setPassword: Function;
  password: string;
  onSubmitPassword: Function;
  onPressCancel: Function;
  header?: string;
}
const PasswordModal: React.FC<IProps> = ({
  password,
  header,
  setPassword,
  setModal,
  setSelectedSection,
  onSubmitPassword,
  onPressCancel,
}: IProps) => {
  const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
  const [passError, setPassError] = useState("");
  const [passType, setPassType] = useState("password");

  const changeType = () => {
    passType === "password" ? setPassType("text") : setPassType("password");
  };

  const validate = () => {
    let passwordError = "";
    password.trim() === ""
      ? (passwordError = "Password required")
      : passRegex.test(password) !== true
      ? (passwordError = "Password Invalid")
      : (passwordError = "");
    if (passwordError) {
      setPassError(passwordError);
      return false;
    } else {
      return true;
    }
  };
  const onSubmit = async () => {
    const isValid = validate();
    const passHash = getPassHash();
    const currentHash = CryptoJS.SHA256(password).toString();
    if (isValid) {
      if (currentHash === passHash) {
        setModal(false);
        onSubmitPassword();
      } else {
        setPassError("Wrong Password");
      }
    }
  };
  return (
    <Modal
      className="text-dark modal-pos modal-margin-top border-0"
      show={true}
    >
      <div className="p-4 upload-text">
        <p className="upload-text mb-2">
          {header ? header : "Enter your password"}
        </p>
        <div className="center">
          {passType !== "text" ? (
            <AiOutlineEyeInvisible
              className="reset-eye6"
              onClick={() => {
                changeType();
              }}
            />
          ) : (
            <AiOutlineEye
              className="reset-eye6"
              onClick={() => {
                changeType();
              }}
            />
          )}
          <form autoComplete="off" autoSave='off' className='w-100 pass-form'>
            <TextField
              error={passError !== "" ? true : false}
              id="pass-field"
              // autoComplete='new-password'
              type={passType}
              label={passError !== "" ? passError : "Password"}
              className="mb-4 text-box-l"
              value={password}
              onChange={(evt) => {
                setPassword(evt.target.value);
                setPassError("");
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onSubmit();
                }
              }}
            />
          </form>
        </div>

        <div className="float-right mt-2">
          <button
            className="cancel-btn border mr-2"
            onClick={() => {
              if (passRegex.test(password)) setModal(false);
              else onPressCancel();
            }}
          >
            <p className="center ">Cancel</p>
          </button>
          <button
            className="confirm-btn"
            onClick={() => {
              onSubmit();
            }}
          >
            <p className="login-txt center">Confirm</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default PasswordModal;
