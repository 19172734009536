import React, { useState } from "react"
import { BiUserPlus } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { IoMdClose, IoMdLink } from 'react-icons/io';
import {
    checkUserExistence,
    addSharedStorage,
    getSharedStorageInfo,
    getShareableLink,
} from '../../service/dashboard/data-services';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import TextField from '@material-ui/core/TextField';
import 'react-toastify/dist/ReactToastify.css';
import PasswordModal from "components/cloud/password-modal/password-modal";
import SharedLinkModal from "components/cloud/sharing-modal/shared-link-modal";
interface Iprop {
    id: number,
    idArray: any[]
    setIDArray: Function
    setCustomState: Function
    customState: boolean
    setSelectedSection: Function
}
const SharingComponent: React.FC<Iprop> = ({ id, idArray, setIDArray, setCustomState, customState, setSelectedSection }: Iprop) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailList, setEmailList] = useState<any[]>([]);
    const [emailError, setEmailError] = useState('');
    const [sharedLink, setSharedLink] = useState<string[]>([]);
    const [authFlag, setAuthFlag] = useState(false);
    const [password, setPassword] = useState('');
    const [showNoti, setShowNoti] = useState(false);
    const [passwordModal, setPasswordModal] = useState(true);
    const [sharedLinkModal, setSharedLinkModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const onwerEmail = sessionStorage.getItem('email');
    let notifyText = 'Generating Shareable Link';

    $(document).ready(function () {
        $("#add-email").click(function () {
            $('#email-input').focus();
        });
    });
    
    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
    }, [authFlag, history]);

    React.useEffect(() => {
       if(password.length > 0){
           setMessage('')
       }
    }, [password]);
    const [data, setData] = useState<any>([]);
    const [accessLoading, setAccessLoading] = useState(false);
    React.useEffect(() => {
        const getAccessInfo = async () => {
            setAccessLoading(true)
            try {
                const response = await getSharedStorageInfo(id);
                if (response) {
                    setData(response.data.response);
                    console.log('response data',response.data.response);
                }
            } catch (error) {
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setAccessLoading(false)
        }
        getAccessInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const renderPasswordModal = () => {
        switch (passwordModal) {
            case true:
                return (
                    <PasswordModal
                        onSubmitPassword={()=>{}}
                        onPressCancel={()=>setSelectedSection('')}
                        setModal={setPasswordModal}
                        setSelectedSection={setSelectedSection}
                        setPassword={setPassword}
                        password={password}
                    />
                );
        }
    };  
    const renderSharedLinkModal = () => {
        switch (sharedLinkModal) {
            case true:
                return (
                    <SharedLinkModal
                    setModal={setSharedLinkModal}
                    sharedLink={sharedLink}
                    />
                );
        }
    };  
    const onPressLinkIcon = async () => {
        try {
            setShowNoti(true)
            const response = await getShareableLink(idArray,accessType,password);
            setShowNoti(false)
            let urls = response.data.response.join();
            setSharedLink(urls)
            setSharedLinkModal(true)
         
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
    }  

    const getAccessInfo = async () => {
        try {
            const response = await getSharedStorageInfo(id);
            if (response) {
                setData(response.data.response);
                
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
    }
    
    const showStackData = () => {
        return emailList.map((element: any, index) => { 
            return (
                <div className="email-elements " key={index}>
                    <p>{element} <MdClose onClick={() => {
                        let array: any[] = [];
                        array = emailList.filter(function (item) {
                            return item !== element
                        })
                        setEmailList(array);
                    }} /></p>
                </div>
            );
        })
    }
    const addEmailInArray = async () => {
        if (!emailList.includes(email) && email.toLowerCase() !== onwerEmail?.toLowerCase()) {
            try {
                let isAlreadyShared = data.some((v:any)=> {   
                    if(v.shared_with !== null)
                    return v.shared_with.email === email
                    else 
                    return false
                })
                if(isAlreadyShared){
                    setEmailError('Already Shared')
                    return
                }
                const response = await checkUserExistence(email.toLowerCase());
                if (response) {
                    if (response.data.response !== false) {
                        emailList.push(email.toLowerCase());
                        setEmailList(emailList);
                        setEmail('')
                    } else {
                        setEmailError('Email Not Exist')
                    }
                }
            } catch (error) {
                console.log(error);
                
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                        setAuthFlag(true);
                        return
                    } else if (error.response.data.statusCode === 400) {
                        setEmailError('Invalid Email')
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
        } else if (email.toLowerCase() === onwerEmail?.toLowerCase()) {
            setEmailError('Owner Email')
        } else {
            setEmailError('Already Added')
        }
    }
    const [accessType, setAccessType] = useState('VIEWER');
    const validate = () => {
        let mesError = "";
        let emailError = "";
        emailList.length === 0 && email === '' ? emailError = "Enter an email" :
            emailError = "";
        message.trim() === '' ? mesError = "Required" :
            mesError = "";
        if (emailError || mesError) {
            setEmailError(emailError);
            setMessageError(mesError);
            return false;
        } else {
            return true;
        }
    }

    const onSelectType = async (event: any, element: any) => {
        if(event.target.value !== element.access_type){
            try {
                const response = await addSharedStorage([element.shared_with.email], [id], `I am updating the access type to ${event.target.value}`, event.target.value,password);            
                if (response) {
                    if (response.data.response.message === 'created successfully!') {
                        await getAccessInfo();
                        toast.success('Access Type Updated!');
                    }
                }
            } catch (error) {
                console.log(error.response.data.message)
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                        return
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
        }
    }

    const AddSharedStorage = async (shouldValidate: boolean, emailArray: any, idArray: any, message: any, accessType: any) => {
        // const isValid = validate();
        if (!shouldValidate ? true  : validate()) {
            if(emailError !== ''){
                return
            }
            setLoading(true);
            try {
                const response = await addSharedStorage(emailList, idArray, message, accessType,password);
                if (response) {
                    if (response.data.response.message === 'created successfully!') {
                        toast.success('Data Shared Successfully');
                        setSelectedSection('');
                        setCustomState(!customState)

                    }
                }
                setLoading(false);
            } catch (error) {
                console.log(error.response.data.message)
                if (error && error.response && error.response.data) {
                        toast.error(error.response.data.message);
                } 
            }
        }
        setLoading(false);
    }
    $(document).ready(function () {
        $(".check-add").click(function () {
            if ($(".message-box").hasClass("border-down")) {
                $(".message-box").removeClass("border-down");
            } else {
                $(".message-box").addClass("border-down");
            }
        });
    });
    const renderAccessMembers = () => {        
        return data.map((element: any, index: any) => {     
            if(element.shared_with)  
            return (
                <div className="row mt-3" key={index}>
                    <div className="col-2">
                        <div className="circle-name">
                            <p className="circle-text">{element.shared_with.email?.charAt(0)}</p>
                        </div>
                    </div>
                    <div className="col-8 col-sm-7 textfontsize">
                        <p className="font-weight-bold text-dark word-wrap">{element.shared_with.name ? element.shared_with.name : 'Dummy Name'}</p>
                        <p className="text-muted pr-4" style={{wordWrap:'break-word'}}>
                            <small>{element.shared_with.email}</small>
                        </p>
                    </div>
                    <div className="col-1 mt-1">
                        <p className="text-muted access-dropdown">
                            {/* <small>{element.access_type}</small> */}
                            {/* <span className="float-right ml-auto select-div spec">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option >{element.access_type}</option>
                                    <option >{element.access_type === 'VIEWER' ? 'MODIFIER' : 'VIEWER'}</option>
                                </select>
                            </span> */}
                            <select onClick={e => onSelectType(e, element)} className="form-control frm-ctrl">
                                {/* <option onClick={() => { setAccessType('VIEWER') }}>VIEWER</option>
                                <option onClick={() => { setAccessType('MODIFIER') }}>MODIFIER</option> */}
                                <option value="VIEWER" selected={element.access_type === 'VIEWER'}>VIEWER</option>
                                <option value="MODIFIER" selected={element.access_type === 'MODIFIER'}>MODIFIER</option>
                                <option value="CANCEL" selected={element.access_type === 'CANCEL'}>CANCEL</option>
                            </select>
                        </p>
                    </div>
                </div>
            );
            else return false
        })
    }
    return (
        <div className="list-group sidebar border mt-2 f-1 list-group-flush">
            <ToastContainer limit={1} />
            <div className="list-item ">
                {!accessLoading ?
                    <><p className="pl-5 info-text p-5"><BiUserPlus className="share-menu-icons mr-2" />Share<IoMdClose onClick={() => {
                        setSelectedSection('')
                        setCustomState(!customState)
                    }} className="float-right" /></p>
                        <div className=" mx-4">
                            <div id="add-email" className={'' + emailError ? 'error-down' : 'border-down'}>
                                <div className='d-flex justify-content-between align-items-center'>
                                <p className={"add-people " + (emailError ? 'error-text' : '')}
                                    data-toggle="tooltip" data-placement="left" title="Write Email and press Enter to Validate"
                                >{emailError ? emailError : 'Add People'}</p>
                                <div className='link-icon' onClick={onPressLinkIcon} data-tip='Get a link'>
                                <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="solid" />
                                <IoMdLink color={'#5b42ca'} size={24} />
                                </div>
                                </div>
                                
                                <div className={"" + (emailList.length === 0 ? '' : 'Scroll-email-array')}>
                                    {showStackData()}
                                </div>
                                <input id="email-input" className="add-ppl-txt" type="text" value={email} onChange={(evt) => {
                                    setEmailError('')
                                    setEmail(evt.target.value);
                                }}
                                onBlur={()=>{
                                    if(email.length > 0)
                                    addEmailInArray()
                                }}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter' || event.key === ' ') {
                                            addEmailInArray();
                                        }
                                    }} />
                                <p className="helper-text">Press enter to add email</p>
                            </div>
                            <div className="mt-5 folder">
                                <span>Permissions *</span>
                                <span className="float-right select-div">
                                    <select onChange={event => setAccessType(event.target.value)} className="form-control" id="exampleFormControlSelect1">
                                        {/* <option onClick={() => { setAccessType('VIEWER') }}>VIEWER</option>
                                        <option onClick={() => { setAccessType('MODIFIER') }}>MODIFIER</option> */}
                                        <option value="VIEWER">VIEWER</option>
                                        <option value="MODIFIER">MODIFIER</option>
                                    </select>
                                </span>
                            </div>
                            <div className="center w-100 mt-2">
                                <TextField
                                    inputProps={{
                                        autocomplete: "new-password",
                                        form: {
                                          autocomplete: "off",
                                        },
                                      }}
                                    error={messageError !== '' ? true : false}
                                    label={messageError !== '' ? messageError : "Add Message"}
                                    className="div-from mb-4"
                                    autoComplete='off'
                                    value={message}
                                    onChange={(evt) => {
                                        if(evt.target.value.length <= 255){
                                            setMessage(evt.target.value);
                                        }
                                        setMessageError('')
                                    }}
                                />
                            </div>
                            <div className="text-right">
                                {!loading ?
                                    <button className="share-btn mt-1 mt-lg-4" onClick={() => { AddSharedStorage(true, emailList, idArray, message, accessType) }}>
                                        <p className="login-txt center">Share</p>
                                    </button> :
                                    <><span className="spinner-border mt-3 spinner-color login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="login-txt"></span></>}
                            </div>
                            <p className="folder access my-5">Who has Access</p>
                            <div className="Scroll-on-access ">
                                <div className="row">
                                    <div className="col-2">
                                        <div className="circle-name">
                                            <p className="circle-text">{onwerEmail?.charAt(0)}</p>
                                        </div>
                                    </div>
                                    <div className="col-8 col-sm-7 folder">
                                        <span className="font-weight-bold text-dark">{sessionStorage.getItem('name')?.slice(0,15) || 'No Name'}</span>
                                        <p className="text-muted">
                                            <small>{onwerEmail}</small>
                                        </p>
                                    </div>
                                    <div className="col-1 mt-1">
                                        <p className="text-muted">
                                            <small>Owner</small>
                                        </p>
                                    </div>
                                </div>
                                {renderAccessMembers()}
                                {renderPasswordModal()}
                                {renderSharedLinkModal()}
                            </div>
                        </div></>
                    :
                    <div className="d-flex  loader-info  center">
                        <div className="spinner-border spiner-xp mt-5" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}
            </div>
            {showNoti && <div className="p-3 notificatins"> <p className="notify-text">{notifyText}</p> </div>}
        </div>
    );
};
export default SharingComponent;