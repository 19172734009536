import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import InputProps from "../../style/update-input-style";
import LabelProps from "../../style/label-style";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { updateUserPass } from "../../service/auth-services/auth";
const UpdatePassword = () => {
    const history = useHistory();
    const [passType, setPassType] = useState('password');
    const [confirmPassType, setConfirmPassType] = useState('password');
    const [confirmPassType2, setConfirmPassType2] = useState('password');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
    const [oldPassError, setOldPassError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [loading, setLoading] = useState(false);

    const validate = () => {
        let passwordError = "";
        let oldpassError = "";
        let confirmPassError = "";
        oldPassword.trim() === '' ? oldpassError = "Password Required" :
            oldpassError = "";
        password.trim() === '' ? passwordError = "Password Required" : (passRegex.test(password) !== true) ?
            passwordError = "Password Invalid" :
            passwordError = "";
        confirmPassword.trim() === '' ? confirmPassError = "Confirm Password Required" : password !== confirmPassword ?
            confirmPassError = "Not Match" :
            confirmPassError = "";
        if (oldpassError || passwordError || confirmPassError) {
            setOldPassError(oldpassError);
            setPasswordError(passwordError);
            setConfirmPasswordError(confirmPassError);
            return false;
        } else {
            return true;
        }
    }
    const RegisterUser = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setOldPassError("");
            setPasswordError("");
            setConfirmPasswordError("");
            try {
                const response = await updateUserPass(oldPassword, password, confirmPassword);
                toast.success(response.data.response.message);
                setTimeout(() => {
                    history.push('login');
                }, 500);
                setOldPassword('')
                setPassword('');
                setConfirmPassword('');
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setLoading(false);
        }
    }
    const changeType = () => {
        passType === 'password' ? setPassType('text') : setPassType('password')
    }
    const changeConfirmType = () => {
        confirmPassType === 'password' ? setConfirmPassType('text') : setConfirmPassType('password')
    }
    const changeConfirmType2 = () => {
        confirmPassType2 === 'password' ? setConfirmPassType2('text') : setConfirmPassType2('password')
    }
    return (
        <form noValidate autoComplete="off" className="main-from mt-lg-5">
            <ToastContainer limit={1} />
            <div className="center">
                <LockOutlinedIcon className="lock-Icon" />
                {passType === 'text' ?
                    <AiOutlineEyeInvisible className="reset-eye" onClick={() => { changeType() }} /> :
                    <AiOutlineEye className="reset-eye" onClick={() => { changeType() }} />}
                <TextField
                    error={oldPassError !== '' ? true : false}
                    className="div-from mb-4"
                    id="reset"
                    type={passType}
                    InputProps={InputProps}
                    InputLabelProps={LabelProps}
                    label={oldPassError !== '' ? oldPassError : "Current Password"}
                    value={oldPassword} onChange={(evt) => {
                        setOldPassError('')
                        setOldPassword(evt.target.value);
                    }}
                />
            </div>
            <div className="center">
                <LockOutlinedIcon className="lock-Icon5" />
                {passType === 'text' ?
                    <AiOutlineEyeInvisible className="reset-eye2" onClick={() => { changeConfirmType() }} /> :
                    <AiOutlineEye className="reset-eye2" onClick={() => { changeConfirmType() }} />}
                <TextField
                    error={passwordError !== '' ? true : false}
                    className="div-from mb-4"
                    id="con-reset"
                    type={confirmPassType}
                    InputProps={InputProps}
                    InputLabelProps={LabelProps}
                    label={passwordError !== '' ? passwordError : "New Password"}
                    value={password} onChange={(evt) => {
                        setPasswordError('')
                        setPassword(evt.target.value);
                    }}
                />
            </div>
            <div className="center">
                <LockOutlinedIcon className="lock-Icon3" />
                {passType === 'text' ?
                    <AiOutlineEyeInvisible className="reset-eye3" onClick={() => { changeConfirmType2() }} /> :
                    <AiOutlineEye className="reset-eye3" onClick={() => { changeConfirmType2() }} />}
                <TextField
                    error={confirmPasswordError !== '' ? true : false}
                    className="div-from mb-4"
                    id="con-resets"
                    type={confirmPassType2}
                    InputProps={InputProps}
                    InputLabelProps={LabelProps}
                    label={confirmPasswordError !== '' ? confirmPasswordError : "Confirm Password"}
                    value={confirmPassword} onChange={(evt) => {
                        setConfirmPasswordError('')
                        setConfirmPassword(evt.target.value);
                    }}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                        }
                    }}
                />
            </div>
            <div className="float-right">
                <div className="update-btn float-right mt-1 mt-lg-4" onClick={() => { RegisterUser() }} >
                    {!loading ?
                        <p className="login-txt center">Update</p> :
                        <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="login-txt"> Loading...</span></>}
                </div>
            </div>
        </form>
    );
};
export default UpdatePassword;