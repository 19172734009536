import React, { useState } from 'react';
import { MdFolderOpen } from 'react-icons/md';
import moment from 'moment';
import '../../style/dashboard.style.scss'
import FilesViewer from '../file-viewer/viewer';
import { ToastContainer, toast } from 'react-toastify';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { getSharesData, DownloadFiles, getStorageCount, SearchStorageData } from "../../service/dashboard/data-services"
import { IoIosArrowForward } from 'react-icons/io';
import { useHistory } from "react-router-dom";
import { BsArrowReturnRight } from 'react-icons/bs';
import { Table } from 'react-bootstrap';
import { getDecryptedViewableBuffer } from 'components/common-functions/crypto.function';
import { isPreviewFile, getFileType } from 'service/dashboard/file-check';
interface Iprop {
    setHeadLoader: Function;
    setCustomState: Function;
    customState: Boolean;
    disable :Function;
    toogleSearch: boolean,
    query: any,
    preSelectedOption: string;
    setFolderParentId: Function;
    layoutType: string;
    refreshStorage: boolean;
    setRefreshStorage: Function;
    startDownload: Function;
    setShowMenu: Function;
    handleClick: Function;
    setTargetName: Function;
    setID: Function;
    setIDArray: Function;
    setSelectedFile: Function
}
const Shared: React.FC<Iprop> = ({ setHeadLoader , setShowMenu, handleClick, disable, setID, setIDArray, setTargetName, setSelectedFile, preSelectedOption, query, toogleSearch, layoutType, setFolderParentId, customState, setCustomState, refreshStorage, setRefreshStorage, startDownload }: Iprop) => {
    const history = useHistory();
    const [sharedFolder, setSharedFolder] = useState<any>([]);
    const [sharedFiles, setSharedFiles] = useState<any>([]);
    const [sharedloading, setSharedloading] = useState(false);
    const [hasMoreFolder, setHasMoreFolders] = useState(false);
    const [hasMoreFiles, setHasMoreFiles] = useState(false);
    const [totalFolder, setTotalFolder] = useState(0);
    const [folderCounts, setFolderCounts] = useState<any>([]);
    const [totalFiles, setTotalFiles] = useState(0);
    const [authFlag, setAuthFlag] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [parentId, setParentId] = useState(0);
    const [url, setUrl] = useState('' as any);
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [showNoti, setShowNoti] = useState(false);
    const [notifyText, setNotifyText] = useState('');
    let [stackData, setStackData] = React.useState<any[]>([{ name: 'Shared ', id: undefined }]);
    let [pageNo, setPageNo] = useState(0);
    let [filePage, setFilePage] = useState(0);
    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authFlag, history]);

    React.useEffect(() => {        
        preSelectedOption === 'shared' && getStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customState])

    React.useEffect(() => {
        query && getSearchStorage(query);
        // eslint-disable-next-line
    }, [toogleSearch])

    function getSearchStorage(query: any){
        sharedFiles.length = 0;
        sharedFolder.length = 0;
        setSharedFiles(sharedFiles);
        setSharedFolder(sharedFolder)
        getSearchSharedFolderData();
        getSearchSharedFiles();
    }

    async function getStorage() {
        sharedFiles.length = 0;
        sharedFolder.length = 0;
        setSharedFiles(sharedFiles);
        setSharedFolder(sharedFolder)
        getSharedData()
    }
    
    const handleFolderScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (!hasMoreFolder) {
                setPageNo(++pageNo);
                getSharedData();
            }
        }
    }
    const handlefileScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (!hasMoreFiles) {
                setFilePage(++filePage);
                getSharedData();
            }
        }
    }
    const fetchData = () => {
        setHasMoreFolders(false);
        setHasMoreFiles(false);
        sharedFiles.length = 0;
        sharedFolder.length = 0;
        setSharedFiles(sharedFiles);
        setSharedFolder(sharedFolder)
        setCustomState(!customState)
    }
    const getFoldersCount = async (ids:number[]) => {
        if(ids.length < 1) return
        try {            
            const response: any = await getStorageCount(ids,true);            
            setFolderCounts(response.data.response)
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }   
    }

     const getSearchSharedFolderData = async () => {
        disable();
        setHeadLoader(true);
        try {
            const response = await SearchStorageData(query, 'DIR', "SHARED", pageNo, 15);
            response.data.response.foundStorages.map((element: any) => {  
                let obj = { ...element, shared_by: element.shared_by, id: element.id }  
                sharedFolder.push(obj)
                return obj;
            });
            await setSharedFolder(Array.from(new Set(sharedFolder)));
            setTotalFolder(response.data.response.total)
            setSharedloading(true);
            if (response.data.response.foundStorages.length === 0 || response.data.response.foundStorages.length < 15) {
                setHasMoreFolders(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        disable();
    }

    const getSearchSharedFiles = async () => {
        setHeadLoader(true);
        try {
            const files = await SearchStorageData(query, 'FILE', "SHARED", pageNo, 15);
            files.data.response.foundStorages.map((element: any) => {
                let obj = { ...element, shared_by: element.shared_by, id: element.id, key: element.key }  
                sharedFiles.push(obj)
                return obj;
            });
            setSharedFiles(Array.from(new Set(sharedFiles)));
            setTotalFiles(files.data.response.total);
            setSharedloading(true);
            if (files.data.response.foundStorages.length === 0 || files.data.response.foundStorages.length < 15) {
                setHasMoreFiles(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                } else {
                    toast.error("Error in Loading Folder")
                }
            }
        }
        setHeadLoader(false);
    }
    const onClickMenu = (obj:any,e:any) => {
        setShowModal(false);
        setTargetName(obj.storage.name);
        setID(obj.id);
        handleClick(e);
        setShowMenu(true)
        setSelectedFile(obj)
        let ids = [];
        ids.push(obj.id)
        setIDArray(ids)
    }
    
    const updateProgress = (downlodedBytes:any,totalBytes:any) => {
        let percentage = (downlodedBytes / totalBytes) * 100;
        setProgress(parseInt(percentage.toFixed(0)))
    }

    const renderFileViewer = () => {
        switch (showModal) {
            case true:
                return <FilesViewer setShowfileViewer={setShowModal} progress={progress} url={url} fileName={fileName} customState={refreshStorage} setCustomState={setRefreshStorage} />;
        }
    };

    const startDecryption = async (obj:any) => {
        try {
            setFileName(obj.storage.name)
            let flag = isPreviewFile(obj.storage.name);
            if(flag){
                    setUrl('');
                    setProgress(0)
                    setShowModal(true);
                    const response = await DownloadFiles(obj.id, obj.shared_by.email);
                    let temp = { ...response.data.response, pass_enc_key: obj.key };
                    const base64String = await getDecryptedViewableBuffer(temp,updateProgress,obj.storage.name,true)
                    setUrl(base64String);
                    return
                }
                setNotifyText(`Downloading ${fileName}`)
                    setShowNoti(true);
                    setTimeout(function () {
                        setShowNoti(false);
                    }, 2000);
                    const response = await DownloadFiles(obj.id,obj.shared_by.email);
                    let file = {res: response.data.response, name: obj.storage.name }
                    await startDownload(file)
                } catch (error) { console.log(error);
             }}

    //get files 
    const getSharedData = async () => {
        setHeadLoader(true);
        try {
            const response = await getSharesData('', filePage, 10, parentId);    
            let ids:any = []       
            response.data.response.archive_list.folders.foldersList.map((element: any) => {
                sharedFolder.push(element)
                ids.push(element.id)
                return element;
            });
            setSharedFolder(Array.from(new Set(sharedFolder)));
            getFoldersCount(ids)
            setSharedloading(true);
            setTotalFolder(response.data.response.archive_list.folders.totalFolders)
            if (response.data.response.archive_list.folders.foldersList.length === 0 || response.data.response.archive_list.folders.foldersList.length < 10) {
                setHasMoreFolders(true)
            }
            response.data.response.archive_list.files.filesList.map((element: any) => {
                sharedFiles.push(element)
                return element;
            });
            setSharedFiles(Array.from(new Set(sharedFiles)));
            setSharedloading(true);
            setTotalFiles(response.data.response.archive_list.files.totalFiles)
            if (response.data.response.archive_list.files.filesList.length === 0 || response.data.response.archive_list.files.filesList.length < 10) {
                setHasMoreFiles(true)
            }
            
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode !== 401) {
                    toast.error("Error in Loading Folder")
                }
            }
        } 
        setHeadLoader(false);
    }

    const showStackData = () => {
        return stackData.map((element: any, index) => {
            return (
                <span key={element.id} className="nav-elements" onClick={() => {
                    setPageNo(0);
                    setFilePage(0);
                    setFolderParentId(element.id);
                    setParentId(element.id)
                    stackData.length = index + 1;
                    setStackData(stackData);
                    fetchData()
                }}>
                    {element.name} <IoIosArrowForward />
                </span>
            );
        })
    }
    
    const renderFolders = () => {
        return sharedFolder.map((Folder: any,ind:number) => {
            return (
                <tr key={Folder.id} onClick={()=>{ setFolderParentId(Folder.id); setParentId(Folder.id)}} onDoubleClick={()=>{
                    stackData.push({ name: Folder.storage.name.slice(0, 20), id: Folder.id })
                    setStackData(stackData)
                    fetchData()
                }}>
                    <td><MdFolderOpen className="folder-icon" />{Folder.storage.name.slice(0, 20)}</td>
                    <td className="right">{folderCounts[Folder.id] && folderCounts[Folder.id].foldersCount} Folders | {folderCounts[Folder.id] && folderCounts[Folder.id].fileCount} Files</td>
                    <td className="right">{Folder.shared_by.email}</td>
                    <td className="right">{moment(Folder.shared_at).format("DD MMM YYYY HH:mm")}</td>
                </tr>
            );
        })
    }
    const renderGridFolders = () => {
        return sharedFolder.map((Folder: any) => {
            return (
                <tr key={Folder.id} className={"cursor select-tab "} id={Folder.id} onClick={()=>{setFolderParentId(Folder.id); setParentId(Folder.id)}} onDoubleClick={()=>{
                    stackData.push({ name: Folder.storage.name.slice(0, 20), id: Folder.id })
                    setStackData(stackData)
                    fetchData()
                }}>
                    <td className="file-name-o">
                        <div className="row folder-card-row">
                            <div className="col-md-4">
                                <div className="folder-card">
                                    <div className="folder-card-heading">
                                    <MdFolderOpen className="folder-icon-grid" />
                                    </div>
                                    <div className="folder-card-content">
                                        {Folder.storage.name.slice(0, 15)}
                                    </div>
                                    {/* <div className='d-flex align-items-center justify-content-between'>
                                    <div className="folder-card-content">
                                        {Folder.shared_by.name.slice(0, 15)}
                                    </div>
                                    <div className="folder-card-content">
                                        {moment(Folder.shared_at).format('DD MM YYYY')}
                                    </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        })
    }
    const renderFiles = () => {
        return sharedFiles.map((files: any,ind:number) => {
            let type = files.storage.name.substring(files.storage.name.lastIndexOf(".") + 1);
            return (
                <tr key={ind} onDoubleClick={()=>startDecryption(files)}>
                    <td><span className={"type-text text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                        : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpge' ? 'jpg-icon'
                            : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{getFileType(files.storage.name)}</span>
                        {files.storage.name.slice(0,25)}</td>
                    <td className="right">{files.shared_by.email}</td>
                    <td className="right">{moment(files.shared_at).format("DD MMM YYYY HH:mm")}
                    <BiDotsVerticalRounded className='float-right mr-2' aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => onClickMenu(files,e)} id='b' />
                    </td>
                </tr>
            );
        })
    }
    const renderGridFiles = () => {
        return sharedFiles.map((files: any) => {
            let type = files.storage.name.substring(files.storage.name.lastIndexOf(".") + 1);
            return (
                <tr className={"cursor select-tab "} key={files.id} onDoubleClick={()=>startDecryption(files)}>
                    <td className="file-name-o">
                        <div className="files-card">
                            <div className="card-img d-flex justify-content-center align-items-center"><span className={"text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpeg' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'csv-icon')))))))} >{type}</span></div>
                            <div className="card-body">
                                <div className="headings">
                                    <div className="introduction">
                                    {files.storage.name.slice(0,25)}
                                    </div>
                                </div>                 
                                </div>
                        </div>
                    </td>
                </tr>
            );
        })
    }
    return (
        <div>
            <ToastContainer limit={1} />
            {sharedloading ?
                <div className="row w-100">
                    <div className="pl-3 col-12">
                    {stackData.length > 1 ? showStackData() : ''}
                        <p className="mt-4 folder">Folders</p>
                                   {totalFolder !== 0 ?
                                        layoutType === 'layoutlist' ?  
                                            <Table responsive="md" className="tableBodyScroll"
                                                onScroll={(event: any) => { handleFolderScroll(event) }}
                                            >
                                                <thead>
                                                    <tr>
                                                    <th>Name</th>
                                        <th className="right" >Contents</th>
                                        <th className="right" >Owner</th>
                                        <th className="right" >Shared At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sharedFolder ?
                                                        renderFolders() : ''}
                                                </tbody>
                                            </Table>: <div className="folders-data">{sharedFolder ?
                                               renderGridFolders() : ''}</div>
                                            :
                                            <p className="txt mt-3"><BsArrowReturnRight />Empty</p>}
                    </div>
                    <div className="pl-3 col-12">
                        <p className="mt-4 folder">Files</p>
                            {totalFiles !== 0 ?
                                    layoutType === 'layoutlist' ?  
                                            <Table responsive="md" className="tableBodyScroll"
                                                onScroll={(event: any) => { handlefileScroll(event) }}
                                            >
                                                <thead>
                                                    <tr>
                                                    <th>Name</th>
                                        <th className="right" >Owner</th>
                                        <th className="right" >Shared At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sharedFiles ?
                                                        renderFiles() : ''}
                                                </tbody>
                                            </Table> : <div className="folders-data">{sharedFiles ?
                                                renderGridFiles() : ''}</div> :
                                            <p className="txt mt-3"><BsArrowReturnRight />Empty</p>}
                    </div>
                </div>

                :
                <div className="d-flex  pos-tab">
                    <div className="spinner-border spiner-xp mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
                {renderFileViewer()}
                {showNoti && <div className="p-3 notificatins"> <p className="notify-text">{notifyText}</p> </div>}
        </div>
    );
};
export const SharedDataComponent = React.memo(Shared);