import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../pages/public/auth/login";
import SignUp from "../pages/public/auth/register";
import ForgotPassword from "../pages/public/auth/forgot";
import PageNotFound from "../pages/page-not-found";
import RegisterSucceed from "../pages/public/auth/register-succeed";
import ForgotSucceed from "../pages/public/auth/forgot-succeed";
import PrivateKey from "../pages/public/auth/restore-private-key";
import UserVerfication from "../pages/public/auth/user-verification";
import ResetPass from "../pages/public/auth/reset-password";
import BuyPlan from "../pages/protected/payment-plan/buy-plan";
import SelectPlan from "../pages/protected/payment-plan/select-plan";
import {Dashboard} from "../pages/protected/dashboard/dashboard";
import HelpSupport from "../pages/protected/help-support/help&support";
import Profile from "../pages/protected/profile/profile";
import SendFeedBack from "pages/protected/feedback/feedback";
import Faqs from "pages/protected/faqs";
import PrivateRoute from "./protected";
import SharedData from "pages/public/auth/shared-data";

const Routing = () => {
  const [auth] = useState(true);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgot" exact component={ForgotPassword} />
        <Route path="/registered" component={RegisterSucceed} />
        <Route path="/forgoted" component={ForgotSucceed} />
        <Route path="/keyRestore" component={PrivateKey} />
        <Route path="/reset" component={ResetPass} />
        <Route path="/user-verification" component={UserVerfication} />
        <Route path="/shared/:q" component={SharedData} />
        <PrivateRoute
          exact
          auth={auth}
          path="/buyPlan"
          component={BuyPlan}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/selectPlan"
          component={SelectPlan}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/dashboard"
          component={Dashboard}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/help"
          component={HelpSupport}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/feedback"
          component={SendFeedBack}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/faqs"
          component={Faqs}
        />
        <PrivateRoute
          exact
          auth={auth}
          path="/profile"
          component={Profile}
        />
        <Route component={PageNotFound} /> 
      </Switch>
    </BrowserRouter>
  );
};
export default React.memo(Routing);
