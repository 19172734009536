import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import logo from '../assets/ziptik_logo_light_bg.svg'
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'blue',
        height: '100%',
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    }
}));

const NotFoundView = () => {
    const history = useHistory();
    const classes = useStyles();
    const onPressHome = () => {
        history.push("/");
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            marginTop="100px"
        >
            <Container maxWidth="md">
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h1"
                >
                    404
          </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h1"
                >
                    The page you are looking for isn’t here

          </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="subtitle2"
                >
                    You either tried some shady route or you came here by mistake.
                    Whichever it is, try using the navigation
          </Typography>
                <Box textAlign="center">
                    <img
                        alt="Under development"
                        className={classes.image}
                        src={logo}
                    />
                </Box>
                <Box textAlign="center">
                    <Button variant="contained" onClick={onPressHome} className="mx-auto mt-5" color="primary">
                        HOME
                    </Button>
                </Box>

            </Container>
        </Box>
    );
};

export default React.memo(NotFoundView);