import React, { useState } from "react";
import Header from "../../../components/header/index";
import SucceedMessage from "../../../components/success/message"
import { forgotCall } from '../../../service/auth-services/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotSuccess = () => {
    const email = sessionStorage.getItem('userEmail')
    const [loading, setLoading] = useState(true);
    React.useEffect(() => {
        let timeInterval = setInterval(() => {
            if (timer === 1) {
                setTimer(59)
                setLoading(false);
                clearInterval(timeInterval);
            }
            else {
                setTimer(--timer)
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let [timer, setTimer] = useState(59);
    const resendEmail = async () => {
        setLoading(true);
        let timeInterval = setInterval(() => {
            if (timer === 1) {
                setTimer(59)
                setLoading(false);
                clearInterval(timeInterval);
            }
            else {
                setTimer(--timer)
            }
        }, 1000);
        try {
            const response = await forgotCall(email);
            toast.success(response.data.response.message);
        } catch (error) {
            if (error && error.response && error.response.data) {
                toast.error(error.response.data.message)
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
    }
    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center mb-5 text1 mt-5 ml-lg-2" >Forgot Password</p>
            <SucceedMessage />
            <div className="w-100 border-top footer py-2">
                <div className="center">
                    <p className="footer-txt mr-3">Didn’t get the email?</p>
                    <button className="resent-email center" onClick={resendEmail} disabled={Boolean(loading)} >
                        <p className="resent-txt ">Resend Email{!loading ? '' : <span> in {timer}s</span>}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ForgotSuccess;