import React from "react"
import { Link } from 'react-router-dom';
import { RiCheckboxCircleLine } from 'react-icons/ri'

const Message = () => {
    const userEmail = sessionStorage.getItem('userEmail');
    return (
        <>
            <div className="center main-div m-top-div">
                <p className="center heading"><RiCheckboxCircleLine className="mr-3 tick-icon" />Email Sent Successfully</p>
                <span className="txt d-block mt-3">We have sent an email with a confirmation link to <span className="btn-txt">{userEmail}</span></span>
                <span className="d-block txt">Click on the link to verify account.</span>
                <span className="d-block txt">If you haven’t received any email in inbox, please check spam folder.</span>
            </div>
            <Link to="/login" className="text-link center">
                <div className="btn-box mt-3">
                    <p className="btn-txt">Back to Login</p>
                </div>
            </Link>
        </>
    );
};
export default Message;