import React, { useState, useEffect } from "react";
import Header from "../../../components/header/index";
import { MdFolderOpen } from "react-icons/md";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { Table } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { getFileType } from "service/dashboard/file-check";
import { formatBytes } from "service/dashboard/size-count";
import { getSharedPublicData } from "service/dashboard/data-services";
import {
  generatePrivateKey,
  generatePublicKey,
  getDecryptedBuffer,
} from "components/common-functions/crypto.function";

const SharedData = (props: any) => {
  let [ID, setID] = useState<any>("");
  const [data, setData] = useState<any>([]);
  const [fileData, setFileData] = useState<any>([]);
  let [dirType, setDirType] = useState("ROOT");
  let [stackData, setStackData] = React.useState<any[]>([]);

  const query = props.match.params.q.split(",")[0];

  useEffect(() => {
    const getData = async () => {
      let privateKey = await generatePrivateKey();
      privateKey = privateKey.toString("hex");
      let publicKey = await generatePublicKey(privateKey);
      sessionStorage.setItem("shared_private_key", privateKey);
      sessionStorage.setItem("shared_public_key", publicKey);
      const res = await getSharedPublicData(query, dirType, publicKey);
      if (
        res.data.response.hasOwnProperty("foldersCount") &&
        res.data.response.hasOwnProperty("filesCount")
      ) {
        setData([res.data.response]);
        stackData.push({ name: "Root", id: res.data.response.id });
        setStackData(stackData);
      } else {
        let data = [res.data.response].map((d: any) => {
          let obj = {
            name: d.name,
            url: d.url,
            pass_enc_key: d.key,
            size: d.size,
            date: d.sharedAt
          };
          return obj;
        });
        setFileData(data);
      }
    };

    getData(); // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    data.length = 0;
    fileData.length = 0;
    setData(data);
    setFileData(fileData);
    getData();
  };

  const getData = async () => {
    let publicKey = sessionStorage.getItem("shared_public_key") || "";
    const res = await getSharedPublicData(ID, dirType, publicKey);
    if (dirType === "ROOT") setData([res.data.response]);
    else setData(res.data.response.folders);

    if (res.data.response.hasOwnProperty("files")) {
      let data = res.data.response.files.map((d: any) => {
        let obj = {
          id: d.id,
          name: d.name,
          size: d.size,
          date: d.date,
        };
        return obj;
      });
      setFileData(data);
    }
  };

  async function startDownload(file: any) {
    try {
      const res = await getDecryptedBuffer(file, () => {}, file.name, true, false, ()=>{} );
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  const downloadFile = async (file: any) => {
    try {
      if (file.hasOwnProperty("pass_enc_key")) {
        await startDownload(file);
      } else {
        let publicKey = sessionStorage.getItem("shared_public_key") || "";
        const res = await getSharedPublicData(file.id, dirType, publicKey);
        file = {
          ...file,
          url: res.data.response.url,
          pass_enc_key: res.data.response.key,
        };
        await startDownload(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatNames = (name: string, limit: number) => {
    return name.length < limit ? name : `${name.slice(0, limit)}...`;
  };

  const renderEmpty = () => {
    return (
      <div className="">
        <p className="txt mt-1">
          <BsArrowReturnRight className="mr-2" />
          No Item Found
        </p>
      </div>
    );
  };

  const showStackData = () => {
    return stackData.map((element: any, index: number) => {
      return (
        <span
          key={index}
          className="nav-elements"
          onClick={() => {
            stackData.length = index + 1;
            setStackData(stackData);
            ID = element.id;
            setID(ID);
            if (index === 0) {
              dirType = "ROOT";
              setDirType(dirType);
            } else {
              dirType = "SUB_DIR";
              setDirType(dirType);
            }
            fetchData();
          }}
        >
          {element.name} <IoIosArrowForward />
        </span>
      );
    });
  };

  const renderFolders = () => {
    return data.map((Folder: any, i: any) => {
      return (
        <tr key={i} className={"cursor select-tab "} id={Folder.id}>
          <td
            className="file-name-o"
            onDoubleClick={() => {
              ID = Folder.id;
              setID(ID);
              stackData.push({ name: Folder.name.slice(0, 20), id: Folder.id });
              setStackData(stackData);

              if (Folder.id === 0) {
                dirType = "ROOT";
                setDirType(dirType);
              } else {
                dirType = "SUB_DIR";
                setDirType(dirType);
              }
              fetchData();
            }}
          >
            <MdFolderOpen className="folder-icon" />
            {formatNames(Folder.name, 20)}
          </td>
          <td className="right">
            {Folder.foldersCount} Folders | {Folder.filesCount} Files
          </td>
          <td className="right">
            {moment(Folder.date).format("DD MMM YYYY HH:mm")}
          </td>
        </tr>
      );
    });
  };

  const renderFiles = () => {
    return fileData.map((files: any) => {
      let type = files.name
        .substring(files.name.lastIndexOf(".") + 1)
        .toLowerCase();
      return (
        <tr
          className={"cursor select-tab "}
          key={files.id}
          onClick={(e) => {}}
          onDoubleClick={() => {
            downloadFile(files);
          }}
        >
          <td className="file-name-o">
            <span
              className={
                "type-text text-uppercase " +
                (type === "doc"
                  ? "doc-icon"
                  : type === "pdf"
                  ? "pdf-icon"
                  : type === "xls"
                  ? "xls-icon"
                  : type === "csv"
                  ? "csv-icon"
                  : type === "jpg" || type === "jpge"
                  ? "jpg-icon"
                  : type === "png"
                  ? "png-icon"
                  : type === "psd"
                  ? "psd-icon"
                  : "doc-icon")
              }
            >
              {getFileType(files.name)}
            </span>
            {formatNames(files.name, 45)}
          </td>
          <td className="right">{files.size ? formatBytes(+files.size) : "-"}</td>
          <td className="right">
            {moment(files.date).format("DD MMM YYYY HH:mm")}
          </td>
        </tr>
      );
    });
  };

  if (props.match.params.q.split(",").length > 1) {
    return <Redirect to={`/shared/${query}`} />;
  }
  return (
    <div className="main-section">
      <ToastContainer limit={1} />
      <Header select={false} />
      <div className="row tab-size w-100 d-1">
        <div className="pl-3 pt-3 col-12 folder-div">
          {stackData.length > 1 ? showStackData() : ""}
          <p className="mt-4 folder">Folders</p>
          <Table responsive="md" className="tableBodyScroll">
            <thead>
              <tr>
                <th>Name</th>
                <th className="right">Contents</th>
                <th className="right">Last Modified</th>
              </tr>
            </thead>
            <tbody>{data.length > 0 ? renderFolders() : renderEmpty()}</tbody>
          </Table>
        </div>
        <div className="pl-3 col-12 folder-div">
          <p className="folder mt-3">Files</p>
          <Table responsive="md" className="tableBodyScroll">
            <thead>
              <tr>
                <th>Name</th>
                <th className="right">Size</th>
                <th className="right">Last Modified</th>
              </tr>
            </thead>
            <tbody>{fileData.length > 0 ? renderFiles() : renderEmpty()}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
export default SharedData;
