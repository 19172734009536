import React, {useEffect} from "react";
import Routing from './routes/public';

const App = () => {
  const isDarkTheme = localStorage.getItem('isDarkTheme');

  useEffect(() => {
      if (isDarkTheme === 'true') {
        document.getElementById("light")?.classList.add('dark');
      }
  }, [isDarkTheme]);
  return (
    <Routing/>
  )
};

export default App;
