import React, { useState } from "react";
import Header from "../../../components/header/index";
import { MdVpnKey } from 'react-icons/md';
import TextField from '@material-ui/core/TextField';
import InputProps from "../../../style/restore-input-stype";
import LabelProps from "../../../style/label-style";
import { useHistory } from "react-router-dom";
import { loginByKey } from "../../../service/auth-services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateMnemonic } from 'bip39'
const crypto = require('crypto')
var eccrypto = require("eccrypto");

const RestorePrivateKey = () => {
    const history = useHistory();
    const [seed, setSeed] = useState('');
    const [loading, setLoading] = useState(false);
    const [seedError, setSeedError] = useState('');
    const validate = () => {
        const isValidMnemonic = validateMnemonic(seed);
        let keyError = "";
        seed.trim() === '' ? keyError = "Private Key Required" : isValidMnemonic ? keyError = "" : keyError = 'Invalid Mnemonic';
        if (keyError) {
            setSeedError(keyError);
            return false;
        } else {
            return true;
        }
    }
    const loginUserByKey = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setSeedError("");
            try {
                const privateKey = crypto.createHash('sha256').update(seed).digest('hex');
                const publicKey = eccrypto.getPublic(Buffer.from(privateKey, 'hex')).toString('hex');
                const response = await loginByKey(publicKey);
                if(response.data.code === 200){
                    console.log(response.data.response.encryption_public_key);
                    history.push('/reset',{ 
                        data: {
                            phrase_public_key: publicKey,
                            phrase_private_key: privateKey,
                            server_public_key: response.data.response.encryption_public_key
                        }
                     });
            }
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }            }
            setLoading(false)
        }
    }
    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center my-5 text1 ml-lg-2" >Restore via Private Key</p>
            <div className="center main-div m-top">
                <div className="main-from">
                    <MdVpnKey className="icon-size" />
                    <TextField
                        error={seedError !== '' ? true : false}
                        className="div-from mb-4"
                        id="filled-text"
                        label={seedError !== '' ? seedError : "Enter or Paste Private Key Here"}
                        variant="filled"
                        InputLabelProps={LabelProps}
                        InputProps={InputProps}
                        value={seed} onChange={(evt) => {
                            setSeedError('')
                            setSeed(evt.target.value);
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                loginUserByKey()
                            }
                        }}
                    />
                </div>
                <button className="text-box2  mt-1 mt-lg-4" onClick={() => { loginUserByKey() }}>
                    {!loading ?
                        <p className="login-txt center">Import</p> :
                        <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="login-txt"> Loading...</span></>}
                </button>
            </div>
        </div>
    );
};
export default RestorePrivateKey;