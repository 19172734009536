import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { BsMicFill } from "react-icons/bs";
import { FaStop } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { ReactMic } from "react-mic";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import {
  getPassEncryptedKey,
  generateKey,
  generateIV,
  getPhraseEncryptedKey,
} from "components/common-functions/crypto.function";
import { TextField } from "@material-ui/core";
const tus = require("tus-js-client-v2");

interface Iprop {
  setShowRecorder: any;
  setUpload: any;
  parent_id: any;
  dirType: any;
  setCustomState: any;
  customState: any;
  setSelectedSection: any;
  setPercentage: any;
  setFileName: any;
}
const RecorderComponent: React.FC<Iprop> = ({
  setShowRecorder,
  dirType,
  setUpload,
  parent_id,
  setCustomState,
  customState,
  setSelectedSection,
  setPercentage,
  setFileName,
}: Iprop) => {
  let encryption_public_key = sessionStorage.getItem("server_public_key") || "";
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [record, setRecord] = useState(false);
  const [voiceBlob, setVoiceBlob] = useState("" as any);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const onData = (recordedBlob: any) => {
    // console.log("chunk of real-time data is: ", sizeCount(recordedBlob.size));
  };

  const onStop = (recordedBlob: any) => {
    setVoiceBlob(recordedBlob.blob);
    setValue(recordedBlob.blobURL);
    console.log("recordedBlob is: ", recordedBlob);
  };

  const handleSubmit = async () => {
    if (value === "") {
      toast.error("No voice note found!");
      return;
    }
    if (name.length < 4 || name === ".mp3") {
      setNameError(true);
      return;
    }
    try {
      setLoading(true);
      setPercentage(0);
      setShowRecorder(false);
      var blob = new Blob([voiceBlob], { type: voiceBlob.type });
      console.log("Uploading recording file");
      const token = sessionStorage.getItem("access_token");
      const key = generateKey();
      const iv = generateIV();
      const passKey = getPassEncryptedKey(key, iv);
      const phraseKey = await getPhraseEncryptedKey(key,iv); 
      // Create a new tus upload
      let options = {
        endpoint: config.TS_CLIENT_SERVER + "/uploads",
        headers: {
          authorization: token,
          encryption_public_key,
          type: "FILE",
          dir_type: dirType,
          filename: name,
          parent_id: parent_id,
          pass_enc_key: passKey,
          phrase_enc_key: phraseKey,
        },
        metadata: {
          filename: name,
        },
        data: { key: key, iv: iv }, // -->
        uploadSize: voiceBlob.size,
        onError: function (error: any) {
          setLoading(false);
          setCustomState(!customState);
          setSelectedSection("");
          setPercentage(-2);
          if (error.message.toString().includes("Already Exists")) {
            toast.error("File Already Exists in the directory");
            return;
          }
          console.log("Failed because: " + error);
          toast.error("File Uploaded Failed");
        },
        onProgress: function (bytesUploaded: any, bytesTotal: any) {
          let currentUploading = 0;
          let percent = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          if (+percent > currentUploading) {
            currentUploading = +percent;
            setPercentage(currentUploading);
          }
          console.log(name, bytesUploaded, bytesTotal, percent + "%");
        },
        onSuccess: function () {
          setLoading(false);
          setCustomState(!customState);
          setSelectedSection("");
          setPercentage(-2);
          console.log("Download %s from %s", upload.file.name, upload.url);
        },
      };

      let newFile = new File([blob], name, { type: blob.type });
      var upload = new tus.Upload(newFile, options);
      setUpload(upload)
      // Start the upload
      upload.start();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="list-group sidebar border mt-2 f-1 list-group-flush">
      <ToastContainer limit={1} />
      <div className="list-item ">
        <>
          <p className="pl-5 info-text p-5">
            Create New Voice Note
            <IoMdClose
              onClick={() => setShowRecorder(false)}
              className="float-right"
            />
          </p>
          <div className=" mx-5 w-70 ">
            <div className="center w-100 mt-2">
              <div>
                <ReactMic
                  record={record}
                  className="sound-wave"
                  onStop={onStop}
                  onData={onData}
                  visualSetting={"sinewave"}
                  strokeColor="#007bff"
                  backgroundColor="#f7f7f7"
                />
                <audio src={value} controls className="mt-2" />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="record-btn mt-1 mt-lg-4"
                onClick={() => setRecord(!record)}
              >
                {record ? (
                  <FaStop color="white" />
                ) : (
                  <BsMicFill color={"white"} />
                )}
              </button>
            </div>
            <div className="w-40 mt-5">
              <TextField
                placeholder="File name"
                error={nameError}
                value={name.split(".")[0]}
                onChange={(event: any) => {
                  const val = event.target.value;
                  if (!val.includes(".")) {
                    setNameError(false);
                    setName(`${val}.mp3`);
                    setFileName(`${val}.mp3`);
                  }
                }}
                id="outlined-multiline-static"
              />
            </div>
            <div className="mt-3">
              {!isLoading ? (
                <button className="share-btn mt-2" onClick={handleSubmit}>
                  <p className="login-txt center">Save</p>
                </button>
              ) : (
                <>
                  <span
                    className="spinner-border mt-3 spinner-color login-txt spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="login-txt"></span>
                </>
              )}

              <>
                <span className="login-txt"></span>
              </>
            </div>
            <div className="Scroll-on-access ">
              <div className="row">
                <div className="col-8 col-sm-7 folder">
                  <span className="font-weight-bold text-dark"></span>
                  <p className="text-muted">
                    <small></small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
        :<div className="d-flex  loader-info  center"></div>
      </div>
    </div>
  );
};
export default RecorderComponent;
