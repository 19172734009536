import React, { useState } from "react"
import { MdFolderOpen } from 'react-icons/md';
import { getStorageInfo } from '../../service/dashboard/data-services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {formatBytes} from "../../service/dashboard/size-count"
import { IoMdClose } from 'react-icons/io';

interface Iprop {
    id: number,
    setSelectedSection: Function
}
const InfoComponent: React.FC<Iprop> = ({ id, setSelectedSection }: Iprop) => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [fileType, setFileType] = useState('')
    React.useEffect(() => {
        const GetInfo = async () => {
            setLoading(true)
            try {
                const response = await getStorageInfo(id);
                if (response) {
                    setData(response.data.response);
                    setFileType(response.data.response.name.split('.')[1])
                }
            } catch (error) {
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
            setLoading(false)
        }
        GetInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <div className="list-group sidebar border mt-2 f-1 list-group-flush">
            <ToastContainer limit={1} />
            <div className="list-item ">
                {!loading ?
                    <>
                     <IoMdClose onClick={() => {
                        setSelectedSection('')
                        }} className="mr-4 mt-3 float-right" />
                        <div className="p-5 border-bottom">
                            
                            <div className='d-flex align-items-start' style={{wordWrap:'break-word'}}>{data.type === 'DIR' ? <MdFolderOpen className="folder-icon" /> :
                                <span className={" mr-3 text-uppercase " + (fileType === 'doc' ? 'doc-icon' : (fileType === 'pdf' ? 'pdf-icon' : (fileType === 'xls' ? 'xls-icon'
                                    : (fileType === 'csv' ? 'csv-icon' : (fileType === 'jpg' || fileType === 'jpge' ? 'jpg-icon'
                                        : (fileType === 'png' ? 'png-icon' : (fileType === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{fileType}</span>}
                                <span className='file-info-name'>{data.name ? data.name : 'Loading'}</span>
                               
                            </div>
                        </div>
                        <div className="p-5 folder">
                            <p>Location</p>
                            <p className="pb-4 info-text">Cloud</p>
                            <p>Total Size</p>
                            <p className="pb-4 info-text">{formatBytes(data.size,0)}</p>
                            <p>Added</p>
                            <p className="pb-4 info-text">{moment(data.createdAt).format("DD MMM YYYY HH:mm")}</p>
                            <p>Last Modified</p>
                            <p className="info-text">{moment(data.updatedAt).format("DD MMM YYYY HH:mm")}</p>
                        </div></>
                    :
                    <div className="d-flex  loader-info  center">
                        <div className="spinner-border spiner-xp mt-5" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}
            </div>
        </div>
    );
};
export default InfoComponent;