const InputProps = {
    inputProps: {
        style: {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            background: 'white',
        },
    },
    style: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        background: 'white',
        paddingLeft : 40

    }
}
export default InputProps;