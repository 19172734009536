import React, { useState } from "react";
import Header from "../../../components/header/index";
import copyIcon from "../../../assets/copy.png"
import TextField from '@material-ui/core/TextField';
import { Modal } from 'react-bootstrap';
import { getPhraseKey } from "../../../service/dashboard/data-services"
import { setNameuser } from "../../../service/auth-services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdatePassword from "../../../components/update-password/index"
import { useHistory } from "react-router-dom";
import copy from 'copy-to-clipboard';
import { AiOutlineEye } from 'react-icons/ai';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const Profile = () => {
    let nameofUser = sessionStorage.getItem('name');
    let pass = sessionStorage.getItem('password');
    let email = sessionStorage.getItem('email');

    const history = useHistory();
    const [check, setCheck] = useState('');
    const [updateFlag, setUpdateFlag] = useState(false)
    const [loading, setLoading] = useState(false);
    const [authFlag, setAuthFlag] = useState(false);
    const [password, setPassword] = useState('');
    const [name, setName] = useState(nameofUser || '');
    const [passType, setPassType] = useState('password');
    const [userName, setUserName] = useState('');
    const [nameError, setNameError] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [passError, setPassError] = useState('');
    const [showNamebox, setShowNameBox] = useState(false);
    const [privateKey, setPrivateKey] = useState('');
  
    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
    }, [authFlag, history]);
    const onCopy = () => {
        copy(privateKey);
        toast.info("Copied To Clipboard")
    }
    const changeType = () => {
        passType === 'password' ? setPassType('text') : setPassType('password')
    }
    const renderDialog = () => {
        switch (showDialog) {
            case true:
                return (
                    <Modal className="text-dark modal-pos modal-margin-top border-0" show={true}>
                        <div className="p-4 upload-text">
                            <p className="upload-text mb-2">Please Enter Your Password</p>
                            <AiOutlineEye className="eye-icon-key" onClick={() => { changeType() }} />
                            <TextField
                                error={passError !== '' ? true : false}
                                id="input-pass-a"
                                label={passError !== '' ? passError : "Password"}
                                className="mb-4 text-box-l"
                                type={passType}
                                value={password} onChange={(evt) => {
                                    setPassError('')
                                    setPassword(evt.target.value);
                                }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        onGetPhrase()
                                    }
                                }} />
                            <div className="float-right mt-2">
                                <button className="cancel-btn border mr-2" onClick={() => { setShowDialog(false) }}>
                                    <p className="center ">Cancel</p>
                                </button>

                                <button className="confirm-btn" onClick={() => { onGetPhrase() }} >
                                    {loading ? <span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <p className="login-txt center">Create</p>}
                                </button>
                            </div>
                        </div>
                    </Modal>
                );
        }
    };
    const validate = () => {
        let pasError = "";
        password.trim() === '' ? pasError = "Password required" :
            pasError = "";
        if (pasError) {
            setPassError(pasError);
            return false;
        } else {
            return true;
        }
    }
    const onGetPhrase = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            if (password === pass) {
                try {
                    const response = await getPhraseKey();
                    toast.success(response.data.response.message);
                    setPrivateKey(response.data.response.phrase);
                    setCheck('keyShow')
                    setShowDialog(false)
                } catch (error) {
                    if (error && error.response && error.response.data) {
                        if (error.response.data.statusCode === 401) {
                            toast.error("Your Session is expired. Please login again");
                            setAuthFlag(true);
                            return
                        } else {
                            toast.error(error.response.data.message);
                        }
                    } else {
                        toast.info("Network Error (Check Your Internet)")
                    }
                }
            } else {
                toast.error("Password Incorrect")
            }
        }
        setLoading(false);
    }
    const renderNameEdit = () => {
        switch (showNamebox) {
            case true: 
                return (
                    <Modal className="text-dark modal-pos modal-margin-top border-0" show={true} onHide={()=>setShowNameBox(false)}>
                        <div className="p-4 upload-text">
                            <p className="upload-text mb-2">Please Enter Your Name</p>
                            <TextField
                                error={nameError !== '' ? true : false}
                                id="input-a"
                                label={nameError !== '' ? nameError : ""}
                                className="mb-4 text-box-l"
                                value={name}
                                onChange={(evt) => {                                    
                                    if(evt.target.value.length <= 20){
                                        setNameError('')
                                        setName(evt.target.value);
                                    } 
                                }}
                            />
                            <div className="float-right mt-2">
                                <button className="cancel-btn border mr-2" onClick={() => { setShowNameBox(false) }}>
                                    <p className="center ">Cancel</p>
                                </button>

                                <button className="confirm-btn" onClick={() => { setNameUser() }} >
                                    {loading ? <span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : <p className="login-txt center">Update</p>}
                                </button>
                            </div>
                        </div>
                    </Modal>
                );
        }
    };
    
    const validateName = () => {
        let naError = "";
        name.trim() === '' ? naError = "Name required" : name.length > 2 && name.length <= 20 ? naError = "" : naError = "Name must be between 3-20 characters";
        if (naError) {
            setNameError(naError);
            return false;
        } else {
            return true;
        }
    }
    const setNameUser = async () => {
        const isValid = validateName();
        if(nameofUser === name) { 
            setShowNameBox(false)
            return
         }
        if (isValid) {
            setLoading(true);
            try {
                const response = await setNameuser(name);
                toast.success(response.data.message);
                sessionStorage.setItem('name', name);
                setUserName(name);
                setShowNameBox(false)
            } catch (error) {
                if (error && error.response && error.response.data) {
                    if (error.response.data.statusCode === 401) {
                        toast.error("Your Session is expired. Please login again");
                        setAuthFlag(true);
                        return
                    } else {
                        toast.error(error.response.data.message);
                    }
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }
            }
        }
        setLoading(false);
    }
    return (
        <div>
            <ToastContainer limit={1} />
            <Header select={false} />
            <div className={"split left " + (check !== 'keyShow' ? 'ht' : '')}>
                <div className="centered  mt-4 " >
                    <div className="row wid mb-4">
                        <div className="col-1 mr-2 mt-2">
                            <AccountCircleOutlinedIcon className="profile-icons" />
                        </div>
                        <div className="col-8">
                            <span className="text">Name</span>
                            <span className="sub-text d-block">{userName ? userName : nameofUser}</span>
                        </div>
                        <div className="col-2">
                            <div onClick={() => { setShowNameBox(true); setLoading(false) }} className="profile-icons mt-2 ">
                            <CreateOutlinedIcon className="typeIcon cursor" />
                            </div>
                        </div>
                    </div>
                    <div className="row wid mb-4">
                        <div className="col-1 mr-2 mt-2">
                        <EmailOutlinedIcon className="messbox"/>
                        </div>
                        <div className="col-8">
                            <span className="text">Email</span>
                            <span className="sub-text d-block">{email}</span>
                        </div>
                    </div>
                    <div className="row wid mb-4">
                        <div className="col-1 mr-2 mt-2">
                            <StorageOutlinedIcon className="typeIcon" />
                        </div>
                        <div className="col-6">
                            <span className="text">Account Type</span>
                            <span className="sub-text d-block">Free</span>
                        </div>
                        <div className="col">
                            <button className="up-btn " onClick={() => { history.push('selectPlan') }} >
                                <p className="btn-text center">Upgrade</p>
                            </button>
                        </div>
                    </div>
                    {/* <div className="row wid mb-4" onClick={() => { setShowDialog(true) }}>
                        <div className="col-1 mr-2">
                            <img src={KeyIcon} className="keyIcon" alt="" />
                        </div>
                        <div className="col-8">
                            <span className="sub-text d-block">Private Key</span>
                        </div>
                        <div className="col-2">
                            <img src={arrowIcon} className="arrow-icons" alt="" />
                        </div>
                    </div> */}
                    <div className="row wid mb-4" onClick={() => { setCheck('upPass'); setUpdateFlag(!updateFlag) }}>
                        <div className="col-1 mr-2">
                            <LockOutlinedIcon className="keyIcon" />
                        </div>
                        <div className="col-8">
                            <span className="sub-text d-block">Change Password</span>
                        </div>
                        <div className="col-2">
                            <ArrowForwardIosOutlinedIcon className="keyIcon" />
                        </div>
                    </div>
                    <div className="row wid mb-4" onClick={() => {
                        sessionStorage.clear();
                        history.push('/')
                    }}>
                        <div className="col-1 mr-2">
                            <PowerSettingsNewOutlinedIcon className="keyIcon"/>
                        </div>
                        <div className="col-8">
                            <span className="sub-text d-block">Logout</span>
                        </div>
                    </div>

                </div>
            </div>
            {renderDialog()}
            {renderNameEdit()}
            <div className="split rights">
                <div className="centered">
                    {check === 'upPass' && updateFlag ?
                        <UpdatePassword />
                        :
                        check === 'keyShow' ?
                            <>
                                <div className="p-4 center pr-5 border box-key ">
                                    <span >{privateKey}</span>
                                    <span className=" ml-5 text-right"><img onClick={() => { onCopy() }} src={copyIcon} className="profile-icons" alt="" /></span>
                                </div>
                                <li className="text mt-1"> This is the Private Key which is unique only to you that gives you full ownership of the data stored on Ziptic servers.</li>
                                <li className="text mt-1"> Your data is highly encrypted using military grade technology of AES 256 encryption and locked using this unique Private Key </li>
                                <li className="text mt-1"> Only yourself and the devices you authorise may access your data. Not even any administrators at Ziptic or our Data Centre engineers can view your data without this Private Key</li>
                                <li className="text mt-1"> Please write this randomly generated 12 phrases accurately in its arrangement ON PAPER and avoid storing it on your phone or computer to avoid any possibility of digital theft</li>
                                <li className="text mt-1"> Do store this Private Key safely to unlock your data. If you lose this, Ziptic will not be able to unlock the data for you.</li>
                            </>
                            : ''}
                </div>
            </div>
        </div >
    );
};
export default React.memo(Profile);