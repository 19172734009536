import React from "react";
import { Route, Redirect } from "react-router-dom";

interface IProps {
  auth: boolean;
  component: any;
  path: any;
  exact: boolean;
}
const PrivateRoute: React.FC<IProps> = ({
  auth,
  path,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to={{ pathname: path }} />
      }
    />
  );
};
export default PrivateRoute;
