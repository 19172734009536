import React, { useState } from "react";
import Header from "../../../components/header/index";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';
import InputProps from "../../../style/reset-input";
import { useHistory, useLocation } from "react-router-dom";
import { resetUserPass } from "../../../service/auth-services/auth"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import base64 from 'base-64';
var eccrypto = require("eccrypto");

const ResetPass = () => {
    const history = useHistory();
    const params = useLocation();
    const data: any = params.state;
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [passType, setPassType] = useState('password');
    const [confirmPassType, setConfirmPassType] = useState('password');
    const private_key = data.data.phrase_private_key
    const public_key = data.data.phrase_public_key
    const server_public_key = data.data.server_public_key
    
    const changeType = () => {
        passType === 'password' ? setPassType('text') : setPassType('password')
    }
    const changeConfirmType = () => {
        confirmPassType === 'password' ? setConfirmPassType('text') : setConfirmPassType('password')
    }
    const validate = () => {
        let passwordError = "";
        let confirmPassError = "";
        password.trim() === '' ? passwordError = "Password Required" : (passRegex.test(password) !== true) ?
            passwordError = "Password Invalid" :
            passwordError = "";
        confirmPassword.trim() === '' ? confirmPassError = "Confirm Passsword Required" : password !== confirmPassword ?
            confirmPassError = "Not Match" :
            confirmPassError = "";
        if (passwordError || confirmPassError) {
            setPasswordError(passwordError);
            setConfirmPasswordError(confirmPassError);
            return false;
        } else {
            return true;
        }
    }
    const resetPassword = async () => {
        const isValid = validate();
        if (isValid) {
            setLoading(true);
            setPasswordError("");
            setConfirmPasswordError("");
            try {
                let credentials = {
                    phrase_private_key: private_key,
                    password: password,
                    password_confirmation: confirmPassword,
                  };
                eccrypto.encrypt(Buffer.from(server_public_key, 'hex'), Buffer.from(JSON.stringify(credentials))).then(async function (encrypted_credentials:any) {
                    // A decrypting the message.
                  let encoded_credentials = base64.encode(JSON.stringify(encrypted_credentials));
                  const response = await resetUserPass(public_key,encoded_credentials);
                  toast.success(response.data.response.message);
                   setTimeout(() => {
                      history.push("/login");
                  }, 2000);
                  setPassword('');
                  setConfirmPassword('');

                });
               
            } catch (error) {
                if (error && error.response && error.response.data) {
                    toast.error(error.response.data.message)
                } else {
                    toast.info("Network Error (Check Your Internet)")
                }            }
            setLoading(false)
        }
    }
    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center my-5 text1 ml-lg-2" >Reset Password</p>
            <div className="center main-div margin-top ">
                <div className="main-from">
                    <div className="center">
                        {passType !== 'text' ?
                            <AiOutlineEyeInvisible className="reset-eye" onClick={() => { changeType() }} /> :
                            <AiOutlineEye className="reset-eye" onClick={() => { changeType() }} />}
                        <TextField
                            error={passwordError !== '' ? true : false}
                            className="div-from mb-4"
                            id="reset"
                            type={passType}
                            label={passwordError !== '' ? passwordError : "New Password"}
                            variant="filled"
                            InputProps={InputProps}
                            value={password} onChange={(evt) => {
                                setPasswordError('')
                                setPassword(evt.target.value);
                            }}
                        />
                    </div>
                    {passwordError !== ''?
                    <p className="info-text-reset">Password should be at least 8 characters including special characters</p> :''}
                    <div className={"center" + (passwordError !== ''? 'mt-5 pt-3' : '' )}>
                    {confirmPassType !== 'text' ?
                            <AiOutlineEyeInvisible className={(passwordError !== ''? 'reset-eye5' : 'reset-eye4' )} onClick={() => { changeConfirmType() }} /> :
                            <AiOutlineEye className={(passwordError !== ''? 'reset-eye5' : 'reset-eye4' )} onClick={() => { changeConfirmType() }} />}
                        <TextField
                            error={confirmPasswordError !== '' ? true : false}
                            className="div-from mb-4"
                            id="con-reset"
                            type={confirmPassType}
                            label={confirmPasswordError !== '' ? confirmPasswordError : "Confirm Password"}
                            variant="filled"
                            InputProps={InputProps}
                            value={confirmPassword} onChange={(evt) => {
                                setConfirmPasswordError('')
                                setConfirmPassword(evt.target.value);
                            }}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    resetPassword()
                                }
                            }}
                        />
                    </div>

                </div>
                <div className="ml-auto">
                    <button className="update-btn mt-1 mt-lg-4" onClick={() => { resetPassword() }} >
                        {!loading ?
                            <p className="login-txt center">Update</p> :
                            <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="login-txt"> Loading...</span></>}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default React.memo(ResetPass);