import React, { useState, useEffect } from "react";
import { GetMedia, GetMediaByIds } from "../../service/dashboard/data-services";
import { ToastContainer, toast } from "react-toastify";
import "../../style/dashboard.style.scss";
import FilesViewer from "../file-viewer/viewer";
import { makeStyles } from "@material-ui/core/styles";
import { FaPlay } from "react-icons/fa";
import { AiOutlineReload } from 'react-icons/ai';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { isMediaFile } from "../../service/dashboard/file-check";
import { BsArrowReturnRight } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { getDecryptedViewableBuffer } from "components/common-functions/crypto.function";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 190,
    width: 210,
    border: "1.3px solid #7550ce",
    borderRadius: ".4rem",
  },
  control: {
    padding: theme.spacing(2),
  },
}));
interface Iprop {
  setHeadLoader: Function;
  disable: Function;
  setAnchorElGallery: Function;
  handleGalleryClick: Function;
  setID: Function;
  setTargetName: Function;
  setIDArray: Function;
  idArray: any[];
  customState: boolean;
  refreshStorage: boolean;
  setRefreshStorage: Function;
  percentage: number;
}
const Gallery: React.FC<Iprop> = ({
  setHeadLoader,
  disable,
  customState,
  setAnchorElGallery,
  handleGalleryClick,
  setID,
  setTargetName,
  setIDArray,
  idArray,
  setRefreshStorage,
  percentage,
  refreshStorage,
}: Iprop) => {
  const classes = useStyles();
  const [data, setData] = useState<any>([]);
  let [thumbnails, setThumbnails] = useState<any>([]);
  let [pageNo, setPageNo] = useState(0);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [hasMoreFolder, setHasMoreFolders] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [showNoti, setShowNoti] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  let notifyText= 'Thumbnails are being decrypted. Please wait..';

  useEffect(() => {
    if(data.length === thumbnails.length){
      setShowNoti(false);
    }
    // eslint-disable-next-line
  }, [thumbnails])
  const renderComponents = () => {
    switch (showModal) {
      case true:
        return (
          <FilesViewer
            setShowfileViewer={setShowModal}
            progress={progress}
            url={url}
            fileName={fileName}
            customState={refreshStorage}
            setCustomState={setRefreshStorage}
          />
        );
    }
  };

  function getGalleryData() {
    setLoadingFiles(false); 
    data.length = 0;
    setData(data);
    getMediaData();
  }
  React.useEffect(() => {
    getGalleryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customState]);
  const handleMediaScroll = (event: any) => {
    let element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (!hasMoreFolder) {
        if(data.length === thumbnails.length){
          setShowNoti(false);
          setPageNo(++pageNo);
          getMediaData();
        }
        else {
          setShowNoti(true);
        }
      }
    }
  };
  const getMediaData = async () => {
    disable();
    setHeadLoader(true);
    try {
      const response = await GetMedia(pageNo, 10);
      for (const element of response.data.response) {
        let item = element;
        item = { ...element, thumbnail : {...element.thumbnail, id: Math.random()} };
        data.push(item);
      }
      setData(Array.from(new Set(data)));
      fetchThumbnails(Array.from(new Set(response.data.response)));
      if (
        response.data.response.length === 0 ||
        response.data.response.length < 10
      ) {
        setHasMoreFolders(true);
      }
      setLoadingFiles(true);
    } catch (error) {
      if (error && error.response && error.response.data) {
        if (error.response.data.statusCode === 401) {
          toast.error("Your Session is expired. Please login again");
        } else {
          toast.error("Error in Loading Photos");
        }
      } else {
        console.log(error);
        toast.info("Network Error (Check Your Internet)");
      }
    }
    setHeadLoader(false);
    disable();
  };
 
  const fetchThumbnails = async (files:any) => {      
      for (const element of files) {
      if (element.thumbnail.url) {
        let base64Thumbnail = await getDecryptedViewableBuffer(
          element.thumbnail,
          () => {},
          element.name,
          false
        );
        thumbnails.push(base64Thumbnail);
      }
      else {
      thumbnails.push('');
    }
    setThumbnails(Array.from(thumbnails));      
    };
  };

  const refetchThumbnail = async (obj:any , ind:number) => {
    disable();
    setHeadLoader(true);
    try {
      const response = await GetMediaByIds(obj.id);
      const item = response.data.response[0];
      if(item.thumbnail.url)
      {
        let base64Thumbnail = await getDecryptedViewableBuffer(
          item.thumbnail,
          () => {},
          item.name,
          false
        );
        thumbnails[ind] = base64Thumbnail;
        data[ind] = {...response.data.response[0], thumbnail :{ ...response.data.response[0].thumbnail, id: Math.random() }};
        setThumbnails(thumbnails);
        setData(data)
      }
      else {
        toast.error('Thumbnail Not Found')
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        if (error.response.data.statusCode === 401) {
          toast.error("Your Session is expired. Please login again");
        } else {
          toast.error("Error in Loading Thumbnail");
        }
      } else {
        console.log(error);
        toast.info("Network Error (Check Your Internet)");
      }
    }
    setHeadLoader(false);
    disable();
  }

  const updateProgress = (downlodedBytes: any, totalBytes: any) => {
    let percentage = (downlodedBytes / totalBytes) * 100;
    setProgress(parseInt(percentage.toFixed(0)));
  };

  const startDecryption = async (obj: any) => {
    setFileName(obj.name);
    setShowModal(true);
    // let isViewable = fileSize < 26214400 ? true : false; // 25 MB file is viewable
    setUrl("");
    setProgress(0);
    let base64String: any = await getDecryptedViewableBuffer(
      obj,
      updateProgress,
      obj.name,
      false
    );
    setUrl(base64String);
  };

  const renderImageData = () => {
    return data.map((obj: any, ind: number) => {
      let flag = isMediaFile(obj.name);
      return (
        <Grid key={obj.thumbnail.id} className="gallery-file" item>
          <Paper className={classes.paper}>
            {!flag ? (
              !obj.thumbnail.url ? 
              <div className='thumbnail-load'>
                <AiOutlineReload onClick={()=>refetchThumbnail(obj,ind)} color={'#5e44d3'} size={24} className='cursor'/>
                </div> :
              !thumbnails[ind] ? (
                <div
                  className="thumbnail-load"
                  onClick={() => {
                    startDecryption(obj);
                  }}
                >
                  <div className="lds-dual-ring"></div>
                </div>
              ) : <img
                  src={`data:image/*;base64,${thumbnails[ind]}`}
                  className="image-box-size"
                  alt=""
                  onClick={() => {
                    startDecryption(obj);
                  }}
                />
            ) : (
              <div
                className="position-relative"
                onClick={() => {
                  startDecryption(obj);
                }}
              >
                <div className="play-video-icon">
                  <FaPlay color={"white"} size={30} className="" />
                </div>
                <video
                  className="image-box-size video-gallery-thumbnail"
                  poster={`data:image/*;base64,${thumbnails[ind]}`}
                >
                  <source src={`${thumbnails[ind]}`} type="video/mp4" />
                  <source src={`${thumbnails[ind]}`} type="video/webm" />
                  <source src={`${thumbnails[ind]}`} type="video/mp2" />
                  <source src={`${thumbnails[ind]}`} type="video/mpeg" />
                  <source src={`${thumbnails[ind]}`} type="video/mpe" />
                  <source src={`${thumbnails[ind]}`} type="video/mpv" />
                  <source src={`${thumbnails[ind]}`} type="video/m4p" />
                  <source src={`${thumbnails[ind]}`} type="video/m4v" />
                  <source src={`${thumbnails[ind]}`} type="video/avi" />
                  <source src={`${thumbnails[ind]}`} type="video/wmv" />
                  <source src={`${thumbnails[ind]}`} type="video/flv" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            )}
            <p className={"pl-2 image-name " + (flag ? "" : "pad")}>
              {obj.name.length < 23 ? obj.name : `${obj.name.slice(0, 22)}...`}
              <div className="ml-auto download-icon float-right">
                <BiDotsVerticalRounded
                  onClick={(e) => {
                    handleGalleryClick(e);
                    setTargetName(obj.name);
                    setID(obj.id);
                    idArray.length = 0;
                    idArray.push(obj.id);
                    setIDArray(idArray);
                  }}
                />
              </div>
            </p>
          </Paper>
        </Grid>
      );
    });
  };

  return (
    <div className={"tab-size " + (percentage !== -2 && "mt-5 pt-4")}>
      <ToastContainer limit={1} />
      {loadingFiles ? (
        <div className="row tab-size  pr-4">
          {/* <p className="mt-4 pl-3 folder">Photos</p> */}
          {data.length > 0 ? (
            <Grid
              item
              className="gallery-scroll"
              onScroll={(event: any) => {
                handleMediaScroll(event);
              }}
            >
              <Grid container>
                {renderImageData()}
              </Grid>
            </Grid>
          ) : (
            <p className="txt mt-4">
              <BsArrowReturnRight />
              Empty
            </p>
          )}
        </div>
      ) : (
        <div className="d-flex  pos-tab">
          <div className="spinner-border spiner-xp mt-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {renderComponents()}
      {showNoti && <div className="p-3 notificatins"> <p className="notify-text">{notifyText}</p> </div>}
    </div>
  );
};
export const GalleryComponent = React.memo(Gallery);
