import axios from 'axios';
import config from '../../config';
//forgot password call
const forgotCall = async (email: any) => {
    const url = config.BASE_ENDPOINT + '/user/forgot/password/request';
    const Body = {
        email,
    };
    const response: any = await axios.post(url, Body,{
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
    })
    return response
};
export default forgotCall;