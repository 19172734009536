import React, { useState, useEffect } from 'react';
import { MdFolderOpen } from 'react-icons/md';
import moment from 'moment';
import { BsArrowReturnRight,BsCloudUpload } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import Webcam from "react-webcam";
import '../../style/dashboard.style.scss';
import { Table } from 'react-bootstrap';
import { formatBytes } from "../../service/dashboard/size-count"
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import { UserStorage, GetFreeAvaibaleStorage, GetAvaibaleBandwidth, SearchStorageData, DownloadFiles, getStorageCount } from '../../service/dashboard/data-services';
import { ToastContainer, toast } from 'react-toastify';
import { IoIosArrowForward, IoMdLink } from 'react-icons/io';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';
import FilesViewer from '../file-viewer/viewer';
import config from '../../config';
import { getThumbnails } from 'video-metadata-thumbnails';
import './cloud-style.scss';
import { getPassEncryptedKey, getDecryptedViewableBuffer, generateKey, generateIV, getFileKey, getPhraseEncryptedKey } from 'components/common-functions/crypto.function';
import { isPreviewFile, getFileType } from 'service/dashboard/file-check';
import { isImageFile } from 'service/dashboard/image-check';
const tus = require("tus-js-client-v2");
var upload : any ;
interface Iprop {
    setShowModal: Function,
    percentage: any
    isFetching:Boolean;
    setPercentage: Function,
    setCurrentFileName: Function
    setCount: Function,
    setArraylength: Function,
    count: any,
    setFolderParentId: Function,
    setFolderDirType: Function,
    setCustomState: Function,
    customState: boolean,
    hideUploadOptions: Function,
    setAnchorEl: Function,
    handleClick: any,
    setShowMenu: Function,
    setID: Function,
    id: Number,
    setTargetName: Function,
    setToId: Function,
    setHeadLoader: Function,
    disable: Function
    setIDArray: Function
    setFileNames: Function
    idArray: any[]
    fileNames: any[]
    copyMoveFlag: boolean
    layoutType: string,
    setshowWebCam: any,
    arrayLength: number,
    showWebCam: boolean,
    query: any,
    txtUpload: any,
    getSharedIds: Function
    voiceUpload:any,
    stopUpload: Boolean,
    setStopUpload: Function,
    stopDownload: Boolean,
    startDownload:Function,
    setStopDownload: Function,
    setFetching: Function,
    toogleSearch: boolean,
    preSelectedOption: string,
    setSelectedSection: Function,
    setSelectedOption: Function,
    setRefreshStorage: Function,
    refreshStorage: boolean,
    sharedIds: Number[]
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    const downloadData = new File([blob], fileName, { type: 'image/jpeg' });
    return downloadData
}

const videoConstraints = {
    width: 1078,
    height: 567,
    facingMode: "user"
};

const Cloud: React.FC<Iprop> = ({
    setShowModal, setPercentage, setRefreshStorage, refreshStorage, hideUploadOptions,
    setCurrentFileName, setCount, stopUpload, setStopUpload, setFetching, isFetching,
    setArraylength, count, setFolderParentId, setFolderDirType, startDownload, sharedIds,
    setCustomState, customState, setAnchorEl, setSelectedSection, handleClick, id, getSharedIds,
    setShowMenu, setID, setTargetName, setToId, setHeadLoader, txtUpload, voiceUpload,
    disable, setIDArray, setFileNames, fileNames, idArray, copyMoveFlag, layoutType,
    setshowWebCam, showWebCam, query, toogleSearch, preSelectedOption }: Iprop) => {
    
    const history = useHistory();
    const [data, setData] = useState<any>([]);
    const [uploadingFiles, setUploadingFiles] = useState<any>([]);
    const [fileData, setFileData] = useState<any>([]);
    const [authFlag, setAuthFlag] = useState(false);
    const [isSharedFolder, setIsSharedFolder] = useState<any>(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingFloder, setLoadingFloder] = useState(false);
    const [totalFolder, setTotalFolder] = useState(0);
    let [folderCounts, setFolderCounts] = useState<any>({});
    const [totalFiles, setTotalFiles] = useState(0);
    const [hasMoreFolder, setHasMoreFolders] = useState(false);
    const [hasMoreFiles, setHasMoreFiles] = useState(false);
    const [showNoti, setShowNoti] = useState(false);
    const [notifyText, setNotifyText] = useState('');
    const [webcamError, setWebcamError] = useState('');
    const webcamRef: any = React.useRef(null);
    let [pageNo, setPageNo] = useState<number>(0);
    let [filePage, setFilePage] = useState<number>(0);
    let [parentId, setParentId] = useState(0);
    let [dirType, setDirType] = useState('ROOT');    
    let [stackData, setStackData] = React.useState<any[]>([{ name: 'Cloud ', id: 0 }]);
    let encryption_public_key = sessionStorage.getItem("server_public_key") || "";
    const fromIds = sessionStorage.getItem('fromIds') || "";
    React.useEffect(() => {
        if (authFlag) {
            sessionStorage.clear();
            history.push("/login");
        }
    }, [authFlag, history]);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        dataUrlToFile(imageSrc, 'webcampic.jpeg').then(file => AddImageFile(file));
        setshowWebCam(false);
        // eslint-disable-next-line
    },[webcamRef]
    );

    React.useEffect(() => {
        query && getSearchStorage(query);
        // eslint-disable-next-line
    }, [toogleSearch])


    React.useEffect(() => {
        if(idArray.length < 1){
            setShowMenu(false)
        }
    // eslint-disable-next-line
    }, [idArray])

    React.useEffect(() => {
       if(stopUpload){
           setFetching(false)
           debugger
           upload && upload.abort();
           txtUpload && txtUpload.abort();
           voiceUpload && voiceUpload.abort();
           setPercentage(-2)
        if(uploadingFiles.length > 0){
            let filterFiles = uploadingFiles.filter((f:any)=> f !== upload.file)
           setUploadingFiles(filterFiles)
           setCount(filterFiles.length)
           setStopUpload(false)
           uploadFile(filterFiles,filterFiles.length,0)
        }
       }
        // eslint-disable-next-line
    }, [stopUpload])

    const handleFolderScroll = (event: any) => {
        let element = event.target;
        if (element.scrollHeight !== 0) {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                if (!hasMoreFolder) {
                    setPageNo(++pageNo);
                    getFolderData();
                }
            }
        }
    }
    const handlefileScroll = (event: any) => {
        let element = event.target
        if (element.scrollHeight !== 0) {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                if (!hasMoreFiles) {
                    setFilePage(++filePage);
                    // ++filePage;
                    getFiles();
                }
            }
        }
    }
  
    const CheckAvaibleStorage = async () => {
        try {
            const res = await GetFreeAvaibaleStorage()
            return res.data.response.freeStorage
        } catch (error) {
            console.log(error);
        }
    }

    function thumbnailify(base64Image:any, targetSize:any, callback:any) {
        var img = new Image();
        img.onload = function() {
          var width = img.width,
              height = img.height,
              canvas = document.createElement('canvas'),
              ctx : any = canvas.getContext("2d");
      
          canvas.width = canvas.height = targetSize;
      
          ctx.drawImage(
            img,
            width > height ? (width - height) / 2 : 0,
            height > width ? (height - width) / 2 : 0,
            width > height ? height : width,
            width > height ? height : width,
            0, 0,
            targetSize, targetSize
          );
          canvas.toBlob((blob:any)=>{
            callback(blob);
          })
        };
        img.src = base64Image;
      };

      const uploadVideoThumbnail = async (file:File,thumbBlob:Blob) => {
        return new Promise(async (resolve,reject) => {
            try {
                console.log('Uploading Video Thumbnail for => ', file)
                const extension = file.name.split(".").pop();
                const originalName = file.name.split(".")[0];
                var thumbnailName: any;
                if(dirType !== 'ROOT'){
                    thumbnailName =  `${originalName}_thumbnail_${parentId}.${extension}`;
                }
                else {
                    thumbnailName =  `${originalName}_thumbnail.${extension}`;
                }
                const token = sessionStorage.getItem('access_token');
                const key = generateKey();
                const iv = generateIV();
                const fileKey = sharedIds.includes(id) ? getFileKey(key,iv) : undefined;                
                const passKey = getPassEncryptedKey(key,iv);   
                const phraseKey = await getPhraseEncryptedKey(key,iv); 
                    // Create a new tus upload
                    let options = {
                        endpoint: config.TS_CLIENT_SERVER + '/uploads',
                        headers: {
                            authorization: token,
                            encryption_public_key,
                            type: "THUMBNAIL",
                            dir_type: dirType,
                            filename: thumbnailName,
                            parent_id: parentId,
                            pass_enc_key: passKey,
                            phrase_enc_key: phraseKey,
                            file_enc_key: fileKey,
                            thumbnail_metadeta: `{"filename":"${file.name}","parent_id":${parentId}}`
                        },
                        metadata: {
                            filename: thumbnailName,
                        },
                        data: { key: key, iv: iv },
                        uploadSize: thumbBlob.size, 
                        onError: function (error: any) {
                            console.log("Failed because: " + error.data);
                            toast.error("File Uploaded Failed");

                        },
                        onProgress: function (bytesUploaded: any, bytesTotal: any) {
                            let currentUploading = 0;
                            let percent = (bytesUploaded / bytesTotal * 100).toFixed(2)
                            if (+percent > currentUploading) {
                                currentUploading = +percent;
                                console.log('Current => ', currentUploading , "%");
                            }
                            console.log(thumbnailName, bytesUploaded, bytesTotal, percent + "%");
                        },
                        onSuccess: function () {
                            console.log("Download %s from %s", uploadThumb.file.name, uploadThumb.url)
                            // @ts-ignore
                            resolve();
                    }}
                    let newFile = new File([thumbBlob],thumbnailName,{type:file.type})
                    var uploadThumb = new tus.Upload(newFile, options);
                    // Start the upload
                    uploadThumb.start()
            } catch (err) {
                console.error(err);
            }
        })  
    }    
    
    const uploadImageThumbnail = async (file:any) => {
        return new Promise((resolve,reject) => {
            try {
                const extension = file.name.split(".").pop();
                const originalName = file.name.split(".")[0];
                var thumbnailName: any;
                if(dirType !== 'ROOT'){
                    thumbnailName =  `${originalName}_thumbnail_${parentId}.${extension}`;
                }
                else {
                    thumbnailName =  `${originalName}_thumbnail.${extension}`;
                }
                console.log('Uploading Image Thumbnail for => ', thumbnailName)
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () => {
                thumbnailify(reader.result, 192, async function(thumbnail:Blob) {
                     const token = sessionStorage.getItem('access_token');
                     const key = generateKey();
                     const iv = generateIV();
                     const fileKey = sharedIds.includes(id) ? getFileKey(key,iv) : undefined;
                     const passKey = getPassEncryptedKey(key,iv);  
                     const phraseKey = await getPhraseEncryptedKey(key,iv); 
                    // Create a new tus upload
                    let options = {
                        endpoint: config.TS_CLIENT_SERVER + '/uploads',
                        headers: {
                            authorization: token,
                            encryption_public_key,
                            type: "THUMBNAIL",
                            dir_type: dirType,
                            filename: thumbnailName,
                            parent_id: parentId,
                            pass_enc_key: passKey,
                            phrase_enc_key: phraseKey,
                            file_enc_key: fileKey,
                            thumbnail_metadeta: `{"filename":"${file.name}","parent_id":${parentId}}`
                        },
                        metadata: {
                            filename: thumbnailName,
                        },
                        data: { key: key, iv: iv },
                        uploadSize: thumbnail.size, 
                        onError: function (error: any) {
                            console.log("Failed because: " + error);
                            toast.error("File Uploaded Failed");
                        },
                        onProgress: function (bytesUploaded: any, bytesTotal: any) {
                            let currentUploading = 0;
                            let percent = (bytesUploaded / bytesTotal * 100).toFixed(2)
                            if (+percent > currentUploading) {
                                currentUploading = +percent;
                                console.log('Current => ', currentUploading , "%");
                            }
                            console.log(thumbnailName, bytesUploaded, bytesTotal, percent + "%");
                        },
                        onSuccess: function () {
                            console.log("Download %s from %s", uploadThumb.file.name, uploadThumb.url)
                            // @ts-ignore
                            resolve();
                    }}
    
                    let newFile = new File([thumbnail],thumbnailName,{type:file.type})    
                    var uploadThumb = new tus.Upload(newFile, options);
                    // Start the upload
                    uploadThumb.start()
                });
                });
              
            } catch (err) {
                console.error(err);
            }
        })  
    }

    const uploadFileThumbnail = async (file:any) => {
        const extension = file.name.split(".").pop();
        let imageFlag = isImageFile(file.name);
        const supportedVideoThumbnails = ['mp4','webm','mov','avi']
        if(imageFlag || extension.toLowerCase() === 'svg'){
            uploadImageThumbnail(file)
            }
           else if(supportedVideoThumbnails.includes(extension.toLowerCase())){
               const thumbnails = await getThumbnails(new Blob([file],{type:file.type}),{ start:10,end:10, quality:0.5});
               let thumbnailBLob : any = thumbnails ? thumbnails[0].blob : new Blob(['']);
            uploadVideoThumbnail(file,thumbnailBLob)
            }
    }

    const uploadFile = async (acceptedFiles: any, totalCount: number, index: any) => {
        setHeadLoader(true);
        const freeSpace = await CheckAvaibleStorage();
        const freeBandwidth = await CheckAvaibleBandwidth();
        let file = acceptedFiles[index] ? acceptedFiles[index] : acceptedFiles;
        setCurrentFileName(file.name)       
        let size = file.size;
        if(size > freeBandwidth){
            toast.error('You are out of bandwidth')
            return
        }
        if (size <= freeSpace) {
            setFetching(true)
            try {
                if (size <= 100000) {
                    setPercentage(Math.random() * (60 - 10) + 10);
                }
                const token = sessionStorage.getItem('access_token');
                const key = generateKey();
                const iv = generateIV();
                const fileKey = sharedIds.includes(id) ? getFileKey(key,iv) : undefined;
                const passKey = getPassEncryptedKey(key,iv); 
                const phraseKey = await getPhraseEncryptedKey(key,iv);
                // Create a new tus upload
                let options = {
                    endpoint: config.TS_CLIENT_SERVER + '/uploads',
                    headers: {
                        authorization: token,
                        encryption_public_key,
                        type: "FILE",
                        dir_type: dirType,
                        filename: file.name,
                        parent_id: parentId,
                        pass_enc_key: passKey,
                        phrase_enc_key: phraseKey,
                        file_enc_key: fileKey         // Send File key if folder is shared
                    },
                    metadata: {
                        filename: file.name,
                    },
                    data: { key : key , iv : iv }, // --> Send Dynamic keys to Tus.js
                    uploadSize: size, 
                    onError: function (error: Error) {
                        setFetching(false)
                        if(error.message.toString().includes('Already Exists')){
                            toast.error(`${file.name} already exists in the directory`,{bodyStyle:{fontSize:'14px'}});
                            return
                        }
                        else if(error.message.toString().includes('Your are out of bandwidth')){
                            toast.error("You are out of bandwidth");
                            return
                        }
                        console.log("Failed because: " + error);
                        toast.error("File Uploading Failed");
                        setCount(++count)
                        if (index !== acceptedFiles.length - 1) {
                            index = index + 1;
                            uploadFile(acceptedFiles, totalCount, index);
                        } else {
                            setPercentage(-2);
                        }
                    },
                    onProgress: function (bytesUploaded: any, bytesTotal: any) {
                        let currentUploading = 0;
                        let percent = (bytesUploaded / bytesTotal * 100).toFixed(2)
                        if (+percent > currentUploading) {
                            currentUploading = +percent;
                            setPercentage(currentUploading)
                        }
                        console.log(file.name, bytesUploaded, bytesTotal, percent + "%");
                    },
                    onSuccess: async function () {
                        uploadFileThumbnail(file)   // Start Uploading File Thumbnail
                        console.log("Download %s from %s", upload.file.name, upload.url)
                        setPercentage(0);
                        setFetching(false);
                        setCount(++count)
                        if (index === acceptedFiles.length - 1 || !Boolean(acceptedFiles[index])) {
                            setPercentage(-2);
                            setCount(1)
                            data.length = 0;
                            fileData.length = 0;
                            setData(data);
                            setFileData(fileData)
                            setUploadingFiles([])
                            idArray.length = 0;
                            setIDArray(idArray)
                            sessionStorage.removeItem('fromIds');
                            toast.success("File is uploaded");
                            toast.clearWaitingQueue();
                             getStorage();
                             setRefreshStorage(!refreshStorage)
                        } else {
                            debugger
                            index = index + 1;
                            uploadFile(acceptedFiles, totalCount, index);
                        }
                    }
                }
                upload = new tus.Upload(file, options);
                // Start the upload
                await upload.start()
                
            } catch (error) {
                console.log("Error form tus => ", error)

            }
        } else {
            // toast.info('You dont have enough space')
        }
        setHeadLoader(false);
    }
    function getSearchStorage(query: any){
        data.length = 0;
        fileData.length = 0;
        setData(data);
        setFileData(fileData)
        getSearchFolderData(query);
        getSearchFiles(query);
        // getSearchSharedFolderData(query);
        // getSearchSharedFiles(query);
    }
    async function getStorage() {
        data.length = 0;
        fileData.length = 0;
        setData(data);
        setFileData(fileData)
        if(isSharedFolder){
            // getSharedData();
        }
        else {
            getFolderData();
            getFiles();
        }
    }

    const getFoldersCount = async (ids:number[]) => {
        if(ids.length < 1) return
        try {            
            const response = await getStorageCount(ids);
            folderCounts = { ...folderCounts, ...response.data.response}
            setFolderCounts(folderCounts)
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired. Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }   
    }
    useEffect(() => {
        idArray.length = 0;
        setIDArray(idArray)
        setShowMenu(false);
        getSharedIds();
        // sessionStorage.removeItem('fromIds')
        preSelectedOption === 'cloud' && getStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customState])

    const fetchData = async (type: string, id: number, page: number) => {
        setHasMoreFolders(false);
        setHasMoreFiles(false);
        data.length = 0;
        fileData.length = 0;
        setData(data);
        setFileData(fileData)
        setCustomState(!customState)
    }
    
    const handleDrop = async (acceptedFiles: any) => {      
        if(isFetching){
            setNotifyText('Please wait your file is being uploaded! ')
            setShowNoti(true);
            setTimeout(() => {
                setShowNoti(false)
                setNotifyText('')
            }, 900);
            return
        }
        let totalCount = 1
        let index = 0;
        let temp: any = [];
        acceptedFiles.map((file:any) => {
            let isAlreadyExist = fileData.some((f:any)=> f.name === file.name);
            if(isAlreadyExist){
                toast.error(`${file.name} already exists in the directory`,{autoClose:4000,hideProgressBar:true, bodyStyle:{fontSize:'14px'}});
                toast.clearWaitingQueue();
            }
            else
            temp.push(file)
            return file
        })
        acceptedFiles = temp;
        if(acceptedFiles.length > 0){
        setUploadingFiles(acceptedFiles)
        setArraylength(acceptedFiles.length);
        setTimeout(() => {
            toast.clearWaitingQueue();
            toast.dismiss();
            toast.info("Uploading files");
        },4530);
        uploadFile(acceptedFiles, totalCount, index);
        }        
    }

    const getSearchFolderData = async (query: any) => {
        disable();
        setHeadLoader(true);
        try {
            const response = await SearchStorageData(query, 'DIR', "CLOUD", pageNo, 15);
            response.data.response.foundStorages.map((element: any) => {    
                data.push(element)
                return element;
            });
            await setData(Array.from(new Set(data)));
            setTotalFolder(response.data.response.total)            
            setLoadingFloder(true);
            if (response.data.response.foundStorages.length === 0 || response.data.response.foundStorages.length < 15) {
                setHasMoreFolders(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        disable();
    }

    const getSearchFiles = async (query: any) => {
        setHeadLoader(true);
        try {
            const files = await SearchStorageData(query, 'FILE', "CLOUD", pageNo, 15);
            files.data.response.foundStorages.map((element: any) => {
                fileData.push(element)
                return element;
            });
            setFileData(Array.from(new Set(fileData)));
            setTotalFiles(files.data.response.total);
            setLoadingFiles(true);
            if (files.data.response.foundStorages.length === 0 || files.data.response.foundStorages.length < 15) {
                setHasMoreFiles(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                } else {
                    toast.error("Error in Loading Folder")
                }
            }
        }
        setHeadLoader(false);
    }
    
    const getFolderData = async () => {
        disable();
        setHeadLoader(true);
        try {
            let ids :any= []
            const response = await UserStorage('DIR', dirType, pageNo, 15, parentId);
            response.data.response.archive_list.map((element: any) => {
                data.push(element)
                ids.push(element.id)
                return element;
            });            
            await setData(Array.from(new Set(data)));
            getFoldersCount(ids)            
            setTotalFolder(response.data.response.total)
            setLoadingFloder(true);
            if (response.data.response.archive_list.length === 0 || response.data.response.archive_list.length < 15) {
                setHasMoreFolders(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                    toast.error("Your Session is expired Please login again");
                    setAuthFlag(true)
                } else {
                    toast.error("Error in Loading Folder")
                }
            } else {
                toast.info("Network Error (Check Your Internet)")
            }
        }
        setHeadLoader(false);
        disable();
    }
    const getFiles = async () => {
        setHeadLoader(true);
        try {
            const files = await UserStorage('FILE', dirType, filePage, 15, parentId);
            files.data.response.archive_list.map((element: any) => {
                fileData.push(element)
                return element;
            });
            setFileData(Array.from(new Set(fileData)));
            setTotalFiles(files.data.response.total);
            setLoadingFiles(true);
            if (files.data.response.archive_list.length === 0 || files.data.response.archive_list.length < 15) {
                setHasMoreFiles(true)
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.statusCode === 401) {
                } else {
                    toast.error("Error in Loading Folder")
                }
            }
        }
        setHeadLoader(false);
    }

    const formatNames = (name: string, limit : number) => {
        return name.length < limit ? name : `${name.slice(0,limit)}...`
    }

    const CheckAvaibleBandwidth = async () => {
        try {
            const res = await GetAvaibaleBandwidth()
            return res.data.response.freeBandwidth
        } catch (error) {
            console.log(error);
        }
    }
    
    const addIdOnClick = (id: any, e: any, name: any, type: string) => {
        if (!copyMoveFlag) {
            if (idArray.includes(id)) {
                let array: any[] = [];
                array = idArray.filter(function (item: any) {
                    return item !== id
                })
                let names: any[] = [];
                names = fileNames.filter(function (item: any) {
                    return item !== name
                })
                setFileNames(names)
                setIDArray(array);
            } else {
                    if (e.ctrlKey) {
                        const ids = [...idArray, id];
                        const names = [...fileNames, name];
                        setFileNames(names)
                        setIDArray(ids);
                    }
                    else{
                        const ids = [id];
                        const names = [ name];
                        setFileNames(names)
                        setIDArray(ids);
                    }
            }
        } else {
            $(".select-tab").removeClass("selects");
            $(`#${id}`).addClass("selects");
            type === 'Folder' && setToId(id);
        }
    }
    const onStackClick = (element:any,index:number) => {
        setTargetName(element.name)
        setID(element.id)
        setToId(element.id);
        setSelectedSection('');
        setShowMenu(false);
        setPageNo(0);
        setFilePage(0);
        setFolderParentId(element.id);
        setParentId(element.id);
        stackData.length = index + 1;
        setStackData(stackData);
        if (element.id === 0) {
            setFolderDirType('ROOT');
            setDirType('ROOT');
            fetchData('ROOT', element.id, 0);
        } else {
            setFolderDirType('SUB_DIR');
            setDirType('SUB_DIR');
            fetchData('SUB_DIR', element.id, 0);
        }
        
    }
    const onClickMenu = (obj:any,e:any) => {
        setShowModal(false);
        setTargetName(obj.name);
        setID(obj.id);
        handleClick(e);
        setShowMenu(true)
        let ids = [];
        ids.push(obj.id)
        setIDArray(ids)
    }
    const onFolderClick = (Folder:any,e:any) => {
        addIdOnClick(Folder.id, e,Folder.name,'Folder');
        setTargetName(Folder.name)
        setID(Folder.id);
        setShowModal(false)
        setShowMenu(true)
        setSelectedSection('');
        hideUploadOptions();
    }
    const onGridFolderClick = (Folder:any) => {
        setTargetName(Folder.name)
        setID(Folder.id);
        setShowMenu(true);
        setSelectedSection('');
        hideUploadOptions();
    }
    const onGridFolderDoubleClick = (Folder:any) => {
        setTargetName(Folder.name)
        setID(Folder.id);
        setShowMenu(false);
        stackData.push({ name: Folder.name.slice(0, 20), id: Folder.id })
        setStackData(stackData)
        setFilePage(0)
        setPageNo(0);
        setFolderParentId(Folder.id);
        if (Folder.id === 0) {
            setFolderDirType('ROOT')
            setDirType('ROOT')
        } else {
            setFolderDirType('SUB_DIR')
            setDirType('SUB_DIR')
        }
        setParentId(Folder.id);
        fetchData('SUB_DIR', Folder.id, 0);
    }
    const onFolderDoubleClick = (Folder:any) => {
        setShowModal(false)
        setAnchorEl(null)
        setTargetName(Folder.name)
        setID(Folder.id);
        setShowMenu(false);
        stackData.push({ name: Folder.name.slice(0, 20), id: Folder.id })
        setStackData(stackData)
        setFilePage(0)
        setPageNo(0);
        setFolderParentId(Folder.id);
        if(Folder.shared_by)
        setIsSharedFolder(true);
        else
        setIsSharedFolder(false);
        if (Folder.id === 0) {
            setFolderDirType('ROOT')
            setDirType('ROOT')
        } else {
            setFolderDirType('SUB_DIR')
            setDirType('SUB_DIR')
        }
        setParentId(Folder.id);
        fetchData('SUB_DIR', Folder.id, 0);
    }
    const onFileClick = (files:any,e:any) => {
        addIdOnClick(files.id, e, files.name,'File');
        setTargetName(files.name)
        setID(files.id)
        setShowMenu(true);
        setFileName(files.name)
        setSelectedSection('');
        hideUploadOptions();
    }
    const onFileDoubleClick = (files:any) => {
        setShowMenu(false);
        fileSize = files.size;
        setFileName(files.name)
        getFileUrl(files);
    }
    const renderGridFolders = () => {
        return data.map((Folder: any) => {
            return (
                <tr key={Folder.id} className={"cursor select-tab " + (idArray.includes(Folder.id) ? 'selects' : '') + (fromIds.includes(Folder.id) ? 'disabledDiv' : '')} id={Folder.id} onClick={() => onGridFolderClick(Folder)}>
                    <td className="file-name-o" onDoubleClick={() => onGridFolderDoubleClick(Folder)}
                    onClick={(e)=> addIdOnClick(Folder.id, e, Folder.name,'Folder')}>
                        <div className="row folder-card-row">
                            <div className="col-md-4">
                                <div className="folder-card">
                                    <div className="folder-card-heading">
                                    <MdFolderOpen className="folder-icon-grid" />
                                    </div>
                                    <div className="folder-card-content">
                                        {formatNames(Folder.name,15)}
                                    </div>
                                    {/* @ts-ignore */}
                                    <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                                        <BiDotsVerticalRounded className="dot-icon" aria-controls="simple-menu" data-tip="Menu" aria-haspopup="true" onClick={(e) => onClickMenu(Folder,e)} id='b' />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        })
    }
    const renderFolders = () => {
        return data.map((Folder: any, i : any) => {
            return (
                <tr key={i} className={"cursor select-tab " + (idArray.includes(Folder.id) ? 'selects' : '') + (fromIds.includes(Folder.id) ? 'disabledDiv' : '')} id={Folder.id} onClick={(e) => onFolderClick(Folder,e)}>
                    <td className="file-name-o" onDoubleClick={() => onFolderDoubleClick(Folder)}>
                        <MdFolderOpen className="folder-icon" />{formatNames(Folder.name,20)}</td>
                    <td className="right">{folderCounts[Folder.id] && folderCounts[Folder.id].foldersCount} Folders | {folderCounts[Folder.id] && folderCounts[Folder.id].fileCount} Files</td>
                    <td className="right" >
                    <div className='action-div'>
                    {moment(Folder.updated_at).format("DD MMM YYYY HH:mm")}
                        <div>
                        {sharedIds.includes(Folder.id) && <IoMdLink color={'#a7acb9'} size={20} />}
                        {/* @ts-ignore */}
                        <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                        <BiDotsVerticalRounded aria-controls="simple-menu" aria-haspopup="true" data-tip="Menu" onClick={(e) => onClickMenu(Folder,e)} id='b' /></div>
                        </div>
                    </td>
                </tr>
            );
        })
    }    
    const showStackData = () => {
        return stackData.map((element: any, index:number) => {
            return (
                <span key={element.id} className="nav-elements" onClick={() => onStackClick(element,index)}>
                    {element.name} <IoIosArrowForward />
                </span>
            );
        })
    }
    const updateProgress = (downlodedBytes:any,totalBytes:any) => {
        let percentage = (downlodedBytes / totalBytes) * 100;
        setProgress(parseInt(percentage.toFixed(0)))
    }
    const getFileUrl = async (fileData: any) => {
        try {
            let shared_by = fileData.shared_by ?  fileData.shared_by.email : undefined;
            let isViewable = fileSize < 26214400 ? true : false; // 25 MB file is viewable
            const freeBandwidth = await CheckAvaibleBandwidth();
            if(fileSize > freeBandwidth){
                toast.error("You don't have enough bandwidth")
                return
            }
            if(isViewable && flag){
                    setUrl('');
                    setProgress(0)
                    setShowfileViewer(true);
                    const response = await DownloadFiles(fileData.id,shared_by);
                    let result: any;   
                    if(shared_by) // ---> if the file is shared
                    result = { ...response.data.response, pass_enc_key: fileData.key };
                    else
                    result = response.data.response;
                    const base64String = await getDecryptedViewableBuffer(result,updateProgress,fileName,shared_by ? true : false)
                    setUrl(base64String);
                    return
                }
                    setNotifyText(`Downloading ${fileName}`)
                    setShowNoti(true);
                    setTimeout(function () {
                        setShowNoti(false);
                    }, 2000);
                    const response = await DownloadFiles(fileData.id,shared_by);
                    let file = {res: response.data.response, name: fileName }
                    await startDownload(file)
                }
                catch (error) {
                    toast.info("Network Error (Check Your Internet) ")
                    console.log(error)
                }
            }

    const renderFiles = () => {
        return fileData.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr className={"cursor select-tab " + (idArray.includes(files.id) ? 'selects' : '')} key={files.id} onClick={(e)=>onFileClick(files,e)} onDoubleClick={() => onFileDoubleClick(files)}>
                    <td className="file-name-o">
                        <span className={"type-text text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpge' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'doc-icon')))))))} >{getFileType(files.name)}</span>
                                {formatNames(files.name,45)}
                    </td>
                    <td className="right">{files.size ? formatBytes(+files.size,0) : '-'}</td>
                    <td className="right">
                    <div className='action-div'>
                    {moment(files.updated_at).format("DD MMM YYYY HH:mm")}
                    {!files.shared_by && 
                        (<div>
                        {sharedIds.includes(files.id) && <IoMdLink color={'#a7acb9'} size={20} />}
                        {/* @ts-ignore */}
                        <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                        <BiDotsVerticalRounded aria-controls="simple-menu" aria-haspopup="true" data-tip="Menu" onClick={(e) => onClickMenu(files,e)} id='b' /></div>)}
                        </div>
                    </td>
                </tr>
            );
        })
    }

    const renderModal = () => {
        return (
            <Modal
            dialogClassName="modal60w"
            show={showWebCam}
        >
            {webcamError !== '' ? <div className='webcam-error' >{webcamError}</div> : 
             <Webcam
             className="webcam"
             audio={false}
             height={567}
             ref={webcamRef}
             screenshotFormat="image/jpeg"
             width={1078}
             videoConstraints={videoConstraints}
             onUserMediaError={(error:any)=> setWebcamError(error.message)}
         />
            }
            <div className="web-cam mt-2">
                <button className="cancel-btn border mr-2" onClick={() => { setshowWebCam(false); setTimeout(() => {setWebcamError('');},800); }}>
                    <p className="center ">Cancel</p>
                </button>
                <button className="confirm-btn" disabled={webcamError !== '' ? true : false} onClick={capture} >
                    <p className="login-txt center">Capture</p>
                </button>
            </div>  
        </Modal>
        )
    }

    const renderGridFiles = () => {
        return fileData.map((files: any) => {
            let type = files.name.substring(files.name.lastIndexOf(".") + 1).toLowerCase();
            return (
                <tr className={"cursor select-tab " + (idArray.includes(files.id) ? 'selects' : '')} key={files.id} onClick={(e) => onFileClick(files,e)}
                    onDoubleClick={() => onFileDoubleClick(files)}>
                    <td className="file-name-o" onClick={(e) => {
                        addIdOnClick(files.id, e, files.name,'File');
                    }}>
                        <div className="files-card">
                            <div className="card-img d-flex justify-content-center align-items-center"><span className={"text-uppercase " + (type === 'doc' ? 'doc-icon' : (type === 'pdf' ? 'pdf-icon' : (type === 'xls' ? 'xls-icon'
                            : (type === 'csv' ? 'csv-icon' : (type === 'jpg' || type === 'jpeg' ? 'jpg-icon'
                                : (type === 'png' ? 'png-icon' : (type === 'psd' ? 'psd-icon' : 'csv-icon')))))))} >{type}</span></div>
                            <div className="card-body">
                                <div className="headings">
                                    <div className="introduction">
                                    {formatNames(files.name,20)}
                                    </div>
                                </div>
                                {/* @ts-ignore */}
                                <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                                <BiDotsVerticalRounded className="dot-grid-icon" aria-controls="simple-menu" aria-haspopup="true" data-tip="Menu" onClick={(e) => onClickMenu(files,e)} id='b' />                        
                                </div>
                        </div>
                    </td>
                </tr>
            );
        })
    }
    const AddFile = async (event: any) => {
        setShowModal(false)
        let file = event.target.files;
        let totalCount = 1
        setCount(totalCount);
        let index = 0;
        setArraylength(file.length);
        toast.info("File is Uploading");
        uploadFile(file, totalCount, index);
    };
    const AddImageFile = async (file: any) => {
        let totalCount = 1
        setCount(totalCount);
        let index = 0;
        setArraylength(file.length);
        toast.info("File is Uploading");
        uploadFile(file, totalCount, index);
    };
    const [showfileViewer, setShowfileViewer] = useState(false);
    const [url, setUrl] = useState('' as any);
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('')
    let flag = isPreviewFile(fileName);
    let fileSize = 0;
    const renderComponents = () => {
        switch (showfileViewer) {
            case true:
                return <FilesViewer setShowfileViewer={setShowfileViewer} progress={progress} url={url} fileName={fileName} customState={refreshStorage} setCustomState={setRefreshStorage} />;
        }
    };    

    const renderEmpty = () => {
        return(
            <div className=''>
                <p className='txt mt-1'><BsArrowReturnRight className='mr-2'/>No Item Found</p>
            </div>
        )
    }
    return (
        <>
            <div className="tab-size ">
                <ToastContainer limit={1} />
                <input type="file" id='browser'
                    style={{ display: 'none' }}
                    onChange={AddFile}
                    multiple={true}
                />
                {/* @ts-ignore */}
                <Dropzone onDrop={handleDrop}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div {...getRootProps({ className: "dropzone", id:"dropzone" })}>
                            {!isDragActive || isFetching  ?
                            (loadingFloder || loadingFiles ?
                                <div className="row tab-size w-100">
                                    <div className="pl-3 col-12 folder-div">
                                        {stackData.length > 1 ?
                                            showStackData() : ''}
                                        <p className="mt-4 folder">Folders</p>
                                        {totalFolder !== 0 ?
                                        layoutType === 'layoutlist' ?  
                                            <Table responsive="md" className="tableBodyScroll"
                                                onScroll={(event: any) => { handleFolderScroll(event) }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th className="right" >Contents</th>
                                                        <th className="right" >Last Modified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length > 0 ?
                                                        renderFolders() : renderEmpty()}
                                                </tbody>
                                            </Table>: <div id="scroll-div" className="folders-data"
                                            onScroll={(event: any) => { handleFolderScroll(event) }}
                                            >{data.length > 0 ?
                                            renderGridFolders() : renderEmpty()}</div>
                                            :
                                            <p className="txt mt-3"><BsArrowReturnRight />Empty</p>}
                                    </div>
                                    <div className="pl-3 col-12 folder-div">
                                        <p className="folder mt-3">Files</p>
                                        {totalFiles !== 0 ?
                                        layoutType === 'layoutlist' ?  

                                            <Table responsive="md" className="tableBodyScroll"
                                                onScroll={(event: any) => { handlefileScroll(event) }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th className="right" >Size</th>
                                                        <th className="right" >Last Modified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fileData.length > 0 ?
                                                        renderFiles() : renderEmpty()}
                                                </tbody>
                                            </Table> : <div id="scroll-div" className="folders-data"
                                                onScroll={(event: any) => { handlefileScroll(event) }}
                                            >{fileData.length > 0 ?
                                                renderGridFiles() : renderEmpty()}</div> :
                                            <p className="txt mt-3"><BsArrowReturnRight />Empty</p>}
                                    </div>
                                </div> :
                                <div className="d-flex pos-tab">
                                    <div className="spinner-border spiner-xp mt-5" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>) :
                                <div className="d-flex flex-column align-items-center drag-border" style={{height:window.outerHeight}}>
                                    <BsCloudUpload color={"rgba(94,71,212,0.75)"} size={65}/>
                                    <p className='drag-txt'>Drag and Drop Files Here</p>
                                </div>
                                }
                        </div>
                    )}
                </Dropzone>
                {renderComponents()}
            </div >
            {renderModal()}
            {showNoti && <div className="p-3 notificatins"> <p className="notify-text">{notifyText}</p> </div>}
        </>
    );
};
export const CloudComponent = React.memo(Cloud);