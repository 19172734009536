import React from "react";
import Header from "../../../components/header/index";
import { BsQuestionCircle } from 'react-icons/bs';
import { RiChat4Line } from 'react-icons/ri';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Forgot = () => {
    return (
        <div >
            <ToastContainer limit={1} />
            <Header select={false} />
            <p className="center mb-5 text1 mt-5 ml-lg-2" >Help & Support</p>
            <div className="center main-div m-top">
                <Link to="/faqs" className="text-link">
                    <button className="faq-box2  mt-2">
                        <p className="faq-txt center"><BsQuestionCircle className="mr-2 faq-icon"/>Frequently Asked Questions</p>
                    </button>
                </Link>
                <Link to="/feedback" className="text-link">
                <button className="faq-box2  mt-2 ">
                    <p className="faq-txt center"><RiChat4Line className="mr-2 faq-icon"/>Send Feedback</p>
                </button>
                </Link>
            </div>

        </div>
    );
};
export default Forgot;