import axios from 'axios';
import config from '../../config';
import { getCredentials, decryptResponse } from 'components/common-functions/crypto.function';
//add folders on cloud service
export async function AddFolder(type: string, dir_type: string, name: string, parent_id: Number, size: Number) {
    const url = config.BASE_ENDPOINT + '/storage/add';
    const token = sessionStorage.getItem('access_token');
    let obj =   {
        type,
        dir_type,
        name,
        name_on_cloud: name + '-folder',
        parent_id,
        size,
        pass_enc_key:'qwerty',
        phrase_enc_key:'qwerty'
    }
    const response: any = await axios.post(url,{
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//folders & files on cloud 
export async function UserStorage(type: string, dir_type: string, page_number: number, page_size: number, parent_id: number) {
    const url = config.BASE_ENDPOINT + '/storage';
    const token = sessionStorage.getItem('access_token');
    const obj = {
        type,
        dir_type,
        page_number,
        page_size,
        parent_id
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get shared folders and files 
export async function getSharesData(type: string, page_number: Number, page_size: Number,parent_id?:number) {
    const url = config.BASE_ENDPOINT + '/storage/shared';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        // type,
        page_number,
        page_size,
        parent_id
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get All Shared IDs 
export async function getAllSharedIds() {
    const url = config.BASE_ENDPOINT + '/storage/shared/ids';
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//searchfolders & files on cloud 
export async function SearchStorageData(name: string, document_type: string, type: string, page_number: number, page_size: number) {
    const url = config.BASE_ENDPOINT + '/storage/search';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        name,
        document_type,
        type,
        page_number,
        page_size
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get recent folders and files 
export async function RecentFiles(type: string, page_number: Number, page_size: Number) {
    const url = config.BASE_ENDPOINT + '/storage/recent';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        type,
        page_number,
        page_size
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get folder count 
export async function getStorageCount(ids: number[],isSharedTab?:Boolean) {
    const url = config.BASE_ENDPOINT + '/storage/count';
    const token = sessionStorage.getItem('access_token');
    let obj = {
       ids,
       isSharedTab
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get trash folders and files 
export async function TrashData(type: string) {
    const url = config.BASE_ENDPOINT + `/storage/trashed/storage?type=${type}`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};

//get trash folders and files 
export async function getPhraseKey() {
    const url = config.BASE_ENDPOINT + '/user/view/wallet/phrase';
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.post(url, null, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//Service to get gallery Data
export async function GetMedia(pageNo: Number, page_size: Number) {
    const url = config.BASE_ENDPOINT + `/storage/media?page_number=${pageNo}&page_size=${page_size}`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//Service to get specific gallery Data
export async function GetMediaByIds(ids: Number) {
    const url = config.BASE_ENDPOINT + `/storage/media?page_number=0&page_size=10&ids=[${ids}]`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//rename service
export async function Rename(id: number, name: string) {
    const url = config.BASE_ENDPOINT + '/storage/rename';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        id,
        name
    }
    const response: any = await axios.put(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//download files service
export async function DownloadFiles(file_id: number, sharedBy?: object) {
    const url = config.BASE_ENDPOINT + '/storage/download/file';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        file_id,
        sharedBy
    }    
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//Service for delete file
export async function DeleteStorage(ids: number[]) {
    const url = config.BASE_ENDPOINT + `/storage/delete`;
    const token = sessionStorage.getItem('access_token');
    let obj = { ids }
    const response: any = await axios.post(url,{
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get free avaibale storage for a user and use this before uploading the file
export async function GetFreeAvaibaleStorage() {
    const url = config.BASE_ENDPOINT + '/storage/storage';
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get bandwidth for a user and use this before uploading the file
export async function GetAvaibaleBandwidth() {
    const url = config.BASE_ENDPOINT + '/storage/bandwidth';
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//update bandwidth for a user and use this during downloading/uploading the file
export async function updateUserBandwidth(bytes: number) {
    const url = config.BASE_ENDPOINT + '/storage/bandwidth';
    const token = sessionStorage.getItem('access_token');
    let obj = { bytes }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    },
    {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//restore files and folders from trash using storage id
export async function restoreData(ids: number[]) {
    const url = config.BASE_ENDPOINT + '/storage/trash/restore';
    const token = sessionStorage.getItem('access_token');
    let obj = { ids }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//to delete trashed files hit this route
export async function deleteTrashData(ids: number[]) {
    const url = config.BASE_ENDPOINT + '/storage/delete/trash';
    const token = sessionStorage.getItem('access_token');
    let obj = { ids }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//get user storage info by storage id
export async function getStorageInfo(id: number) {
    const url = config.BASE_ENDPOINT + `/storage/info?id=${id}`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
// move or copy storage
export async function copyOrMoveData(id: number[],parent_id: number , paste_type: string,password?:string) {
    const url = config.BASE_ENDPOINT + '/storage/move';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        id,
        parent_id,
        paste_type,
        password
    }
    const response: any = await axios.put(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//service to check user existence by email
export async function checkUserExistence(email: string) {
    const url = config.BASE_ENDPOINT + `/user/exist?email=${email}`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return response
};
// service to Add Shared Storage
export async function addSharedStorage(users_to_share: any[] ,storage_id: number[] , message: string, access_type: string,password: string) {
    const url = config.BASE_ENDPOINT + '/storage/shared/add';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        users_to_share,
        storage_id,
        message,
        access_type,
        password
    }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
// service to Add Shared Link
export async function getShareableLink(ids: any[], access_type: string, password: string) {
    const url = config.BASE_ENDPOINT + '/storage/shared/public/add';
    const token = sessionStorage.getItem('access_token');
    let obj = {
        storage_ids: ids,
        access_type,
        password,
      };
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
// service to Get Shared Link
export async function getSharedPublicData(id: string, dir_type: String, publicKey: string) {
    const url = config.BASE_ENDPOINT + '/storage/shared/public/get';
    const response: any = await axios.post(url, {
        id,
        publicKey,
        dir_type
    
    })
    return decryptResponse(response,true)
};
//get user storage info by storage id
export async function getSharedStorageInfo(id: number) {
    const url = config.BASE_ENDPOINT + `/storage/shared/info?id=${id}`;
    const token = sessionStorage.getItem('access_token');
    const response: any = await axios.get(url, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};
//send FeedBack
export async function sendFeedback(feedback: string) {
    const url = config.BASE_ENDPOINT + `/user/feedback/`;
    const token = sessionStorage.getItem('access_token');
    let obj = { feedback }
    const response: any = await axios.post(url, {
        data: await getCredentials(obj)
    }, {
        headers: {
            'Authorization': token
        }
    })
    return await decryptResponse(response)
};