import React, { useState } from "react";
import Header from "../../../components/header/index";
import { Link } from 'react-router-dom';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timer from 'react-compound-timer'
import { ResendVerificationEmail } from "../../../service/auth-services/auth";

const RegisterSuccess = () => {
    const email = sessionStorage.getItem('newUserEmail')
    const [loading, setLoading] = useState(true);
 
    const resendEmail = async () => {
        setLoading(true);
        try {
            const response = await ResendVerificationEmail(email);
            toast.success(response.data.response.message);
        } catch (error) {
            if (error && error.response && error.response.data) {
                toast.error(error.response.data.message)
            } else {
                toast.info("Network Error (Check Your Internet)")
            }        }
    }
    return (
        <div className="main-section">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center my-5  text1 ml-lg-2" >Create New Account</p>
            <div className="center main-div m-top-div">
                <p className="center heading"><RiCheckboxCircleLine className="mr-3 tick-icon" />Account Created Successfully</p>
                <span className="txt d-block mt-3">We have sent an email with a confirmation link to <span className="btn-txt">{email}</span></span>
                <span className="d-block txt">Click on the link to verify account.</span>
                <span className="d-block txt">If you haven’t received any email in inbox, please check spam folder.</span>
            </div>
            <Link to="/login" className="text-link center">
                <div className="btn-box mt-3">
                    <p className="btn-txt">Back to Login</p>
                </div>
            </Link>
            <div className="w-100 border-top footer py-2">
                <div className="center">
                    <p className="footer-txt mr-3">Didn’t get the email?</p>
                    <button className="resent-email center" onClick={resendEmail} disabled={Boolean(loading)} >
                        <p className="resent-txt ">Resend Email{!loading ? '' : <span>{' in '}
                        <Timer initialTime={60000*5-1} direction="backward" checkpoints={[{time:1000*1,callback: () => setLoading(false) }]}>
                            {() => (
                                <React.Fragment>
                                    0<Timer.Minutes />:
                                    <Timer.Seconds />s
                                </React.Fragment>
                            )}
                        </Timer>
                        </span>}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default RegisterSuccess;