/**
 * @description check file is image or video based on its extension
 * @param name extension name
 * @author Naeem
 */
export const isMediaFile = (name: string) => {
  const videoType = [
    "WEBM",
    "MPG",
    "MP2",
    "MPEG",
    "MPE",
    "MPV",
    "OGG",
    "MP4",
    "3GP",
		'AVI',
		'MKV',
		'FLV',
		'MOV',
		'MPEG4',
    "PDF",
    "M4P",
    "M4V",
    "WMV",
    "QT",
    "SWF",
    "AVCHD",
    "TXT"
  ];
  const extension: any = name.split(".").pop();
  if (extension && videoType.includes(extension.toUpperCase())) {
    return true;
  }
  return false;
};

export const isPreviewFile = (name: string) => {
  const fileType = [
    "MP4",
		'AVI',
		'MKV',
		'FLV',
		'MOV',
		'WMV',
		'3GP',
		'MPEG4',
    "PDF",
    'JPG',
		'JPEG',
		'JIF',
		'JFIF',
		'JFI',
		'PNG',
		'GIF',
		'WEBP',
		'TIFF',
		'TIF',
		'PSD',
		'RAW',
		'ARW',
		'CR2',
		'NRW',
    'K25',
    'SVG',
		'BMP',
		'DIB',
		'HEIF',
		'HEIC',
		'INDD',
		'IND',
		'INDT',
		'JP2',
		'J2K',
		'JPF',
		'JPX',
		'JPM',
		'MJ2',
	"TXT",
	"MP3"
  ];
  const extension: any = name.split(".").pop();
  if (extension && fileType.includes(extension.toUpperCase())) {
    return true;
  }
  return false;
};
export const getFileType = (name: string) => {
	const fileType = [
	  "MP4",
	  "PDF",
	  'JPG',
		  'JPEG',
		  'JIF',
		  'JFIF',
		  'JFI',
		  'PNG',
		  'GIF',
		  'WEBP',
		  'TIFF',
		  'TIF',
		  'PSD',
		  'RAW',
		  'ARW',
		  'CR2',
		  'NRW',
	  'K25',
	  'SVG',
		  'BMP',
		  'DIB',
		  'HEIF',
		  'HEIC',
		  'INDD',
		  'IND',
		  'INDT',
		  'JP2',
		  'J2K',
		  'JPF',
		  'JPX',
		  'JPM',
		  'MJ2',
	  "MP3",
	  "WEBM",
	  "MPG",
	  "MP2",
	  "MPEG",
	  "MPE",
	  "MPV",
	  "OGG",
	  "3GP",
		  'AVI',
		  'MKV',
		  'FLV',
		  'MOV',
		  'MPEG4',
	  "PDF",
	  "M4P",
	  "M4V",
	  "WMV",
	  "QT",
	  "SWF",
	  "AVCHD",
	  "TXT"
	];
	const extension: any = name.split(".").pop();	
	if (extension && fileType.includes(extension.toUpperCase())) {
	  return extension.toUpperCase();
	}
	else {
		return 'FILE';
	}
  };
