import React, { useState } from "react";
import Header from "../../../components/header/index";
import { Link } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { MdLockOutline } from "react-icons/md";
import {
  AiOutlineEye,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerUser } from "../../../service/auth-services/auth";
import InputProps from "../../../style/input-style";
import LabelProps from "../../../style/label-style";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateMnemonic } from "bip39";
import KeyModal from "components/show-Key/showKey";
import { countries } from "utils/country";
const crypto = require("crypto");
var eccrypto = require("eccrypto");

const SignUp = () => {
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const emailRegex = new RegExp(
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
  );
  const passRegex = new RegExp(/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,30})$/);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [seed, setSeed] = useState("");
  const [showKeyModal, setShowKeyModal] = useState(false);
  
  const renderComponents = () => {
    switch (showKeyModal) {
      case true:
        return <KeyModal seed={seed} setShowkeyModal={setShowKeyModal} />;
    }
  };

  function countryToFlag(isoCode: any) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char: any) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const validate = () => {
    let passwordError = "";
    let countryErr = "";
    let emailError = "";
    let confirmPassError = "";
    let nameErr = "";
    name.trim() === ""
      ? (nameErr = "Name Required")
      : /[^a-zA-Z -]/.test(name)
      ? (nameErr = "Invalid")
      : (nameErr = "");
    country.trim() === ""
      ? (countryErr = "Country Required")
      : (countryErr = "");
    email.trim() === ""
      ? (emailError = "Email Required")
      : emailRegex.test(email) !== true
      ? (emailError = "Email Invalid")
      : (emailError = "");
    password.trim() === ""
      ? (passwordError = "Password Required")
      : passRegex.test(password) !== true
      ? (passwordError = "Password Invalid")
      : (passwordError = "");
    confirmPassword.trim() === ""
      ? (confirmPassError = "Confirm Passsword Required")
      : password !== confirmPassword
      ? (confirmPassError = "Not Match")
      : (confirmPassError = "");
    if (
      emailError ||
      passwordError ||
      confirmPassError ||
      nameErr ||
      countryErr
    ) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      setConfirmPasswordError(confirmPassError);
      setCountryError(countryErr);
      setNameError(nameErr);
      if (passwordError === "Password Invalid")
        toast.error(
          "Password must contain one special character, Capital letter and Number"
        );
      return false;
    } else {
      return true;
    }
  };
  const RegisterUser = async () => {
    const isValid = validate();
    if (isValid) {
      setNameError("");
      setLoading(true);
      setCountryError("");
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");
      try {
        const phrase = generateMnemonic();
        setSeed(phrase);
        const privateKey = crypto
          .createHash("sha256")
          .update(phrase)
          .digest("hex");
        const publicKey = eccrypto
          .getPublic(Buffer.from(privateKey, "hex"))
          .toString("hex");
        const response = await registerUser(
          name,
          email,
          country,
          password,
          confirmPassword,
          publicKey
        );
        toast.success(response.data.response.message);
        sessionStorage.setItem("newUserEmail", email);
        await setTimeout(() => {
          setShowKeyModal(true);
        }, 1000);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      } catch (error) {
        if (error && error.response && error.response.data) {
          setLoading(false);
          if (error.response.data.message === "Already Exists") {
            toast.error("Email Already Exists");
            return;
          }
          toast.error(error.response.data.message);
        } else {
          toast.info("Network Error (Check Your Internet)");
        }
      }
      setLoading(false);
    }
  };
  const changeType = () => {
    passType === "password" ? setPassType("text") : setPassType("password");
  };
  const changeConfirmType = () => {
    confirmPassType === "password"
      ? setConfirmPassType("text")
      : setConfirmPassType("password");
  };

  const onChangeName = (evt:any) => {
    setNameError("");
    setName(evt.target.value);
  }

  const onChangeCountry = (evt:any,val: any) => {
    setCountryError("");
    setCountry(val);
  }
  const onChangeEmail = (evt:any) => {
    setEmailError("");
    setEmail(evt.target.value);
  }
  const onChangePassword = (evt:any) => {
    setPasswordError("");
    setPassword(evt.target.value);
  }
  const onChangeConfirmPassword = (evt:any) => {
    setConfirmPasswordError("");
    setConfirmPassword(evt.target.value);
  }

  return (
    <div className="main-section">
      <ToastContainer limit={1} />
      <Header select={true} />
      <div className="row pt-lg-5 container-fluid mx-auto">
        <div className="col-12 col-lg-6 center borderRight">
          <div>
            <p className="center mb-5 text1">Create New Account</p>
            <div className="main-from">
              <div className="center">
                <AiOutlineUser className="icon-size" />
                <TextField
                  error={nameError !== "" ? true : false}
                  className="div-from mb-4"
                  id="email-box2"
                  label={nameError !== "" ? nameError : "Full Name"}
                  variant="filled"
                  InputProps={InputProps}
                  InputLabelProps={LabelProps}
                  value={name}
                  onChange={onChangeName}
                />
              </div>
              <div className="center">
                <Autocomplete
                  id="country-select-demo"
                  className="country div-from mb-4"
                  onInputChange={onChangeCountry}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{countryToFlag(option.code)}</span>
                      {option.label} ({option.code}) +{option.phone}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={countryError !== "" ? true : false}
                      label="Choose a country"
                      variant="outlined"
                      className="country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="center">
                <HiOutlineMail className="lock-icon" />
                <TextField
                  error={emailError !== "" ? true : false}
                  className="div-from mb-4"
                  id="email-box"
                  label={emailError !== "" ? emailError : "Email Address"}
                  variant="filled"
                  InputProps={InputProps}
                  InputLabelProps={LabelProps}
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
              <div className="center">
                <MdLockOutline className="lock-icon2" />
                {passType === "text" ? (
                  <AiOutlineEyeInvisible
                    className="eye-icon2"
                    onClick={changeType}
                  />
                ) : (
                  <AiOutlineEye
                    className="eye-icon2"
                    onClick={changeType}
                  />
                )}
                <TextField
                  error={passwordError !== "" ? true : false}
                  className="div-from mb-4"
                  id="pass-box"
                  type={passType}
                  label={passwordError !== "" ? passwordError : "Password"}
                  variant="filled"
                  InputProps={InputProps}
                  InputLabelProps={LabelProps}
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
              <MdLockOutline className="lock-icon4" />
              {confirmPassType === "text" ? (
                <AiOutlineEyeInvisible
                  className="eye-icon4"
                  onClick={changeConfirmType}
                />
              ) : (
                <AiOutlineEye
                  className="eye-icon4"
                  onClick={changeConfirmType}
                />
              )}
              <TextField
                error={confirmPasswordError !== "" ? true : false}
                className="div-from mb-4"
                id="connpass-box"
                type={confirmPassType}
                label={
                  confirmPasswordError !== ""
                    ? confirmPasswordError
                    : "Confirm Password"
                }
                variant="filled"
                InputProps={InputProps}
                InputLabelProps={LabelProps}
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    RegisterUser();
                  }
                }}
              />
            </div>
            <div className="box1 mt-4 ml-4 ml-md-0">
              <p className="text2 mb-3 mb-lg-4">
                By continuing, you agree to ZIPTIC{" "}
                <span className="link-text">Terms and Conditions</span> and{" "}
                <span className="link-text">Privacy Policy</span>.
              </p>
            </div>
            <button
              className="text-box2 mt-4 mx-auto"
              onClick={RegisterUser}>
              {!loading ? (
                <p className="login-txt center">Create New Account</p>
              ) : (
                <>
                  <span
                    className="spinner-border login-txt spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="login-txt"> Loading...</span>
                </>
              )}
            </button>
          </div>
        </div>
        <div className="col-12 col-lg-6 center">
          <div className="m-top-bottom">
            <p className="center">Already Registered to Ziptic?</p>
            <Link to="/login" className="text-link">
              <div className="text-box3 border mt-3">
                <p className="account-txt">Back to Login</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {renderComponents()}
    </div>
  );
};
export default React.memo(SignUp);
