import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { IoMdClose } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";
import { isImageFile } from "../../service/dashboard/image-check";
import { Document, Page, pdfjs } from "react-pdf";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FiMinus, FiPlus } from "react-icons/fi";

var mime = require("mime-types");

interface IProps {
  setShowfileViewer: Function;
  url: any;
  fileName: string;
  progress: number;
  customState: boolean;
  setCustomState: Function;
}
const ViewerComponent: React.FC<IProps> = ({
  setShowfileViewer,
  url,
  fileName,
  progress,
  customState,
  setCustomState,
}: IProps) => {
  const extension: any = fileName.split(".").pop();
  let imageFlag = isImageFile(fileName);
  const [pageNo, setPageNo] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [scale, setScale] = useState(0.8);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onPressBack = () => {
    if (pageNo >= 2) setPageNo(pageNo - 1);
  };

  const onPressForward = () => {
    if (pageNo < numPages) setPageNo(pageNo + 1);
  };

  const getDownloadUrl = () => {
    return `data:${mime.lookup(fileName)};base64,${url}`;
  };

  const renderViewer = (ext: string) => {
    switch (ext) {
      case "txt":
        return <p className="text-viewer">{atob(url)}</p>;
      case "mp3":
        return (
          <div className="text-center">
            <div>
              <audio
                src={`data:audio/mp3;base64,${url}`}
                controls
                className="mt-5 mb-3"
              />
            </div>
            <p>{fileName}</p>
          </div>
        );
      case "mp4":
      case "3gp":
      case "wmv":
      case "mkv":
      case "flv":
      case "mov":
      case "avi":
      case "mpeg4":
        return (
          <div className="video-class">
            <video controls>
              <source src={`data:video/mp4;base64,${url}`} type="video/mp4" />
              <source src={`${url}`} type="video/webm" />
              <source src={`${url}`} type="video/mp2" />
              <source src={`${url}`} type="video/mpeg" />
              <source src={`${url}`} type="video/mpe" />
              <source src={`${url}`} type="video/mpv" />
              <source src={`${url}`} type="video/m4p" />
              <source src={`${url}`} type="video/m4v" />
              <source src={`${url}`} type="video/avi" />
              <source src={`${url}`} type="video/wmv" />
              <source src={`${url}`} type="video/flv" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        );
      case "pdf":
        return (
          <div>
            <Document
              renderMode={"svg"}
              file={{ url: `data:application/pdf;base64,${url}` }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.log(error)}
            >
              {/* {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => ( */}
              <div className="d-flex flex-column align-items-center">
                <Page scale={scale} pageNumber={pageNo}/>
                <p className="pdf-view">
                  Page {pageNo} of {numPages}
                </p>
                <div className="pdf-controls-div">
                  <MdKeyboardArrowLeft
                    className="cursor"
                    onClick={onPressBack}
                    size={20}
                  />
                  <FiPlus
                    className="cursor"
                    onClick={() => setScale(scale + 0.1)}
                    size={20}
                  />
                  <FiMinus
                    className="cursor"
                    onClick={() => setScale(scale - 0.1)}
                    size={20}
                  />
                  <MdKeyboardArrowRight
                    className="cursor"
                    onClick={onPressForward}
                    size={20}
                  />
                </div>
              </div>
              {/* ))} */}
            </Document>
          </div>
        );
      case "svg":
        return (
          <img
            src={`data:image/svg+xml;base64,${url}`}
            className="modal-image-box"
            alt=""
            width="300"
          />
        );
      default:
        return <p className="center mt-5">Fail to open {fileName}</p>;
    }
  };

  return (
    <div>
      <Modal
        className="text-dark modal-full float-right border-0"
        effect="fadeInUp"
        show={true}
        onHide={() => {
          setCustomState(!customState);
          setShowfileViewer(false);
        }}
      >
        <div className="p-5 d-flex justify-content-center align-items-center">
          <IoMdClose
            className="zoom-out"
            onClick={() => {
              setCustomState(!customState);
              setShowfileViewer(false);
            }}
          />
          <a
            href={getDownloadUrl()}
            download={fileName}
            onClick={(e) => !url && e.preventDefault()}
          >
            <IoMdDownload
              className=" modal-download-icon"
              color={!url ? "lightgray" : "#5e44d3"}
            />
          </a>
          {!url ? (
            <span
              className="spinner-box d-flex align-items-center"
              style={{ height: "70vh" }}
            >
              <CircularProgressbar
                value={progress}
                text={`${progress}%`}
                strokeWidth={5}
                styles={{
                  root: { height: 125, width: 125 },
                  path: {
                    stroke: `#007bff`,
                    strokeLinecap: "round",
                  },
                  trail: {
                    stroke: "#d6d6d6",
                    strokeLinecap: "round",
                  },
                  text: {
                    fill: "#007bff",
                    fontSize: "12px",
                  },
                }}
              />
            </span>
          ) : imageFlag ? (
            <img
              src={`data:image/*;base64,${url}`}
              className="modal-image-box"
              alt=""
              width=""
            />
          ) : (
            renderViewer(extension.toLowerCase())
          )}
        </div>
      </Modal>
    </div>
  );
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default React.memo(ViewerComponent);
