import React, { useState } from "react";
import Header from "../../../components/header/index";
import TextField from '@material-ui/core/TextField';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisaLogo from "../../../assets/payments.jpg";
import SecureLogo from "../../../assets/image10.jpg";
import { useHistory } from "react-router-dom";
import ConfirmModal from "../../../components/purchase-modal/confirm-purchase";

const BuyPlan = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false)
    const renderComponents = () => {
        switch (showModal) {
            case true:
                return <ConfirmModal setShowModal={setShowModal} />;
        }
    };
    return (
        <div className="div-section">
            <ToastContainer limit={1} />
            <Header  select={true}/>
            <p className="center mt-2 purchase-head">Complete Purchase</p>
            <div className="row pt-lg-5 m-top-bottom container-fluid mx-auto">
                <div className="col-12 col-lg-6 center borderRight">
                    <div className="margin-top-bottom row row-size">
                        <div className="col-5">
                            <p className="head-txt">Ziptic Storage</p>
                            <span className="txt">100 GB Storage</span>
                        </div>
                        <div className="col mt-3">
                            <button className="btn-create border" onClick={() => {
                                history.push("/selectPlan");
                            }}>change</button>
                        </div>
                        <div className="col">
                            <p className="txt">Billing Amount</p>
                            <span className="head-txt">$3.99</span><span>/Year*</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 center ">
                    <div>
                        <div className="main-from">
                            <div className="center">
                                <span className="div-from">
                                    <span className="text-one">Credit Card Information</span>
                                    <img src={VisaLogo} alt="" className="payment-img float-right" />
                                </span>
                            </div>
                            <div className="center mt-2">
                                <TextField
                                    id="input-a"
                                    label="Name on the card"
                                    className="div-from mb-4"
                                />
                            </div>
                            <div className="center">
                                <TextField
                                    id="input-b"
                                    label="Card Number"
                                    className="div-from mb-4"

                                />
                            </div>
                            <div className="center">
                                <span className="div-from mb-4">
                                    <TextField id="input-c" label="Expiry"
                                        className="field-size"
                                    />
                                    <TextField id="input-d" label="CVC" className="field-size ml-1"

                                    />
                                </span>
                            </div>
                        </div>
                        <button className="text-box2 mt-4 mx-auto" onClick={() => { setShowModal(true) }} >
                            <p className="login-txt center">Buy Plan</p>
                        </button>
                    </div>
                </div>
            </div>
            {renderComponents()}
            <div className="center mt-2 ">
                <img src={SecureLogo} alt="" className="payment-img" />
            </div>
            <div className="w-100 center footer">
                <p className="bottom-text mt-3">* Recurring subscription can be cancelled any time before the renewal date.</p>
            </div>
        </div >
    );
};
export default BuyPlan;