import React from "react";
import "../../style/dashboard.style.scss";
import { ProgressBar } from "react-bootstrap";
import { RiArrowDownCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { BiMinus } from "react-icons/bi";

interface Iprop {
  data: any;
  setModalVisible: Function;
  isModal: Boolean;
  setStopDownload: Function;
  controller: any;
  setFetching: Function;
}

const DownloadPage: React.FC<Iprop> = ({
  data,
  isModal,
  setStopDownload,
  setFetching,
  setModalVisible,
  controller,
}: Iprop) => {
  // React.useEffect(() => {
  //   if (data.progress === 100) {
  //     setTimeout(() => {
  //       setModalVisible(false);
  //     }, 3000);
  //   }
  // }, [data.progress]);

  return (
    <>
      {isModal && data.name !== "" && (
        <div className="text-dark download-div">
          <div className="download-header d-flex align-items-center justify-content-between">
            <span className="pl-2 text-light">Download in progress</span>
            <div>
              <BiMinus
                onClick={() => {
                  setModalVisible(false);
                }}
                color="white"
                size={22}
                className="mr-2 cursor"
              />
              <RiCloseCircleLine
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to cancel the download?"
                    )
                  ) {
                    setModalVisible(false);
                    setFetching(false);
                    controller.abort();
                  }
                }}
                color="white"
                className="upload-icon mr-2 cursor"
              />
            </div>
          </div>
          <div className="p-3 upload-text">
            <div className="d-flex align-items-end">
              <RiArrowDownCircleLine
                color="green"
                className="upload-icon mr-2"
              />
              <span className="download-file-text">{data.name}</span>
              <span className="ml-2 download-remaining">
                {data.downloaded} | {data.size}
              </span>
            </div>
            <ProgressBar now={data.progress} className="download-bar" />
          </div>
        </div>
      )}
    </>
  );
};
export default DownloadPage;
