import React from 'react'
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

interface IProps {
    setShowModal: any;
}
const ConfirmModal: React.FC<IProps> = ({ setShowModal }: IProps) => {
    return (
        <Modal
            className="text-dark modal-pos modal-margin-top border-0"
            dialogClassName="modal60w"
            show={true}
        >
            <div className="p-5">
                <p className="head-txt">Confirm Purchase</p>
                <p className="mt-3 txt">Plan</p>
                <p className="popup-text">Ziptic Storage (100 GB)</p>
                <p className="mt-3 txt">Billing Amount</p>
                <p className="popup-text">$3.99/year</p>
                <div className="float-right">
                    <button className="cancel-btn border mr-2" onClick={() => { setShowModal(false) }}>
                        <p className="center ">Cancel</p>
                    </button>
                    <Link to="/dashboard" className="text-link">
                        <button className="confirm-btn  mt-1 mt-lg-4" >
                            <p className="login-txt center">Confirm</p>
                        </button>
                    </Link>
                </div>
            </div>
        </Modal>
    );
};
export default ConfirmModal;