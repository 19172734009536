import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import TextField from "@material-ui/core/TextField";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import {
  getPassEncryptedKey,
  generateKey,
  generateIV,
  getPhraseEncryptedKey,
} from "components/common-functions/crypto.function";
const tus = require("tus-js-client-v2");

interface Iprop {
  setShowNote: any;
  setUpload: any;
  parent_id: any;
  dirType: any;
  setCustomState: any;
  customState: any;
  setSelectedSection: any;
  setPercentage: any;
  setFileName: any;
}
const NoteComponent: React.FC<Iprop> = ({
  setShowNote,
  setUpload,
  dirType,
  parent_id,
  setCustomState,
  customState,
  setSelectedSection,
  setPercentage,
  setFileName,
}: Iprop) => {
  let encryption_public_key = sessionStorage.getItem("server_public_key") || "";
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (name.length < 4 || name === ".txt") {
      setNameError(true);
      return;
    }
    if (value === "") {
      toast.error("Please write some text!");
      return;
    }
    try {
      setLoading(true);
      setPercentage(0);
      setShowNote(false);
      var blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      console.log("Uploading txt file");
      const token = sessionStorage.getItem("access_token");
      const key = generateKey();
      const iv = generateIV();
      const passKey = getPassEncryptedKey(key, iv);
      const phraseKey = await getPhraseEncryptedKey(key,iv); 
      // Create a new tus upload
      let options = {
        endpoint: config.TS_CLIENT_SERVER + "/uploads",
        headers: {
          authorization: token,
          encryption_public_key,
          type: "FILE",
          dir_type: dirType,
          filename: name,
          parent_id: parent_id,
          pass_enc_key: passKey,
          phrase_enc_key: phraseKey,
        },
        metadata: {
          filename: name,
        },
        data: { key: key, iv: iv },
        uploadSize: blob.size,
        onError: function (error: any) {
          setLoading(false);
          setCustomState(!customState);
          setSelectedSection("");
          setPercentage(-2);
          if (error.message.toString().includes("Already Exists")) {
            toast.error("File Already Exists in the directory");
            return;
          }
          console.log("Failed because: " + error);
          toast.error("File Uploaded Failed");
        },
        onProgress: function (bytesUploaded: any, bytesTotal: any) {
          let currentUploading = 0;
          let percent = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          if (+percent > currentUploading) {
            currentUploading = +percent;
            setPercentage(currentUploading);
          }
          console.log(name, bytesUploaded, bytesTotal, percent + "%");
        },
        onSuccess: function () {
          setLoading(false);
          setCustomState(!customState);
          setSelectedSection("");
          setPercentage(-2);
          console.log("Download %s from %s", upload.file.name, upload.url);
        },
      };

      let newFile = new File([blob], name, { type: blob.type });
      var upload = new tus.Upload(newFile, options);
      setUpload(upload)
      // Start the upload
      upload.start();
    } catch (err) {
      console.error(err);
    }
  };  

  return (
    <div className="list-group sidebar border mt-2 f-1 list-group-flush">
      <ToastContainer limit={1} />
      <div className="list-item ">
        <>
          <p className="pl-5 info-text p-5">
            Create New Notes
            <IoMdClose
              onClick={() => setShowNote(false)}
              className="float-right"
            />
          </p>
          <div className=" mx-5 w-70 ">
            <div className="center w-100 mt-2">
              <TextField
                placeholder="File name"
                error={nameError}
                value={name.split(".")[0]}
                onChange={(event: any) => {
                  const val = event.target.value;
                  if (!val.includes(".") && val.length < 50) {
                    setNameError(false);
                    setName(`${val}.txt`);
                    setFileName(`${val}.txt`);
                  }
                }}
                id="outlined-multiline-static"
                className="div-from mb-4"
              />
            </div>
            <div className="center w-100 mt-3">
              <TextField
                multiline
                placeholder="Enter text here"
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                }}
                id="outlined-multiline-static"
                className="div-from mb-4 text-area-style"
              />
            </div>
            <div className="text-right">
              {!isLoading ? (
                <button
                  className="share-btn mt-1 mt-lg-4"
                  onClick={handleSubmit}
                >
                  <p className="login-txt center">Save</p>
                </button>
              ) : (
                <>
                  <span
                    className="spinner-border mt-3 spinner-color login-txt spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="login-txt"></span>
                </>
              )}

              <>
                {/* <span className="spinner-border mt-3 spinner-color login-txt spinner-border-sm" role="status" aria-hidden="true"></span> */}
                <span className="login-txt"></span>
              </>
            </div>
            <div className="Scroll-on-access ">
              <div className="row">
                <div className="col-8 col-sm-7 folder">
                  <span className="font-weight-bold text-dark"></span>
                  <p className="text-muted">
                    <small></small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <div className="d-flex  loader-info  center">
          {/* <div className="spinner-border spiner-xp mt-5" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> */}
        </div>
      </div>
    </div>
  );
};
export default NoteComponent;
