import CryptoJS from "crypto-js";
import base64 from "base-64";
import * as streamSaver from "streamsaver";
import { updateUserBandwidth } from "service/dashboard/data-services";
var eccrypto = require("eccrypto");

export async function decryptResponse(result: any, isShared?: boolean) {
  let decodedData = JSON.parse(base64.decode(result.data.response));
  let private_key = !isShared
    ? sessionStorage.getItem("private_key") || ""
    : sessionStorage.getItem("shared_private_key") || "";
  const encryptedBufferedData: any = {};
  encryptedBufferedData.iv = Buffer.from(decodedData.iv.data);
  encryptedBufferedData.ephemPublicKey = Buffer.from(
    decodedData.ephemPublicKey.data
  );
  encryptedBufferedData.ciphertext = Buffer.from(decodedData.ciphertext.data);
  encryptedBufferedData.mac = Buffer.from(decodedData.mac.data);
  let decryptedData: any = await ECC_decryption(
    encryptedBufferedData,
    private_key
  );
  let resObj = {
    ...result,
  };
  resObj.data.response = JSON.parse(decryptedData.toString());
  return resObj;
}

export async function generatePrivateKey() {
  return eccrypto.generatePrivate();
}

export async function generatePublicKey(private_key: string) {
  return eccrypto.getPublic(Buffer.from(private_key, "hex")).toString("hex");
}

export async function getCredentials(obj: object) {
  let publicKey = sessionStorage.getItem("server_public_key") || "";
  let encoded_credentials = await ECC_encryption(
    JSON.stringify(obj),
    publicKey
  );
  return encoded_credentials;
}

async function ECC_encryption(data: string, key: string) {
  const encrypted_credentials = await eccrypto.encrypt(
    Buffer.from(key, "hex"),
    Buffer.from(data)
  );
  let encoded_credentials = base64.encode(
    JSON.stringify(encrypted_credentials)
  );
  return encoded_credentials;
}

async function ECC_decryption(data: string, key: string) {
  return await eccrypto.decrypt(Buffer.from(key, "hex"), data);
}

export function generateKey() {
  const password = sessionStorage.getItem("password") || "";
  var iterations = 500;
  var keySize = 256;
  var salt = CryptoJS.lib.WordArray.random(128 / 8);
  var output = CryptoJS.PBKDF2(password, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });
  return output.toString(CryptoJS.enc.Base64);
}

export function generateIV() {
  const password = sessionStorage.getItem("password") || "";
  var iterations = 500;
  var keySize = 128;
  var salt = CryptoJS.lib.WordArray.random(128 / 8);
  var output = CryptoJS.PBKDF2(password, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });
  return output.toString(CryptoJS.enc.Base64);
}

export function getPassHash() {
  const password = sessionStorage.getItem("password") || "";
  return CryptoJS.SHA256(password).toString();
}

export function getPhrasePubKey() {
  const publicKey = sessionStorage.getItem("phrase_pub_key") || "";
  return publicKey;
}

export async function getPhraseEncryptedKey(KEY: string, IV: string) {
  const mainKey = IV + KEY;
  const publicKey = getPhrasePubKey();
  console.log('Public key => ', publicKey)
  const phrase_enc_key = await ECC_encryption(mainKey, publicKey);
  console.log('Phrase enc key => ', phrase_enc_key)
  return phrase_enc_key;
}

export function getPassEncryptedKey(KEY: string, IV: string) {
  const mainKey = IV + KEY;
  const hash = getPassHash();
  const hashBuff = Buffer.from(hash, "hex");
  var encryptedData = encryptKEYandIV(mainKey, hashBuff); // --> here
  return encryptedData;
}

export function getFileKey(KEY: string, IV: string) {
  const mainKey = IV + KEY;
  return mainKey;
}

export function encryptKEYandIV(data: string, encryptionKey: Buffer) {
  //data = IV + KEY
  var parsedBase64IV = CryptoJS.enc.Base64.parse("MTIzNDU2Nzg5MDEyMzQ1Ng==");
  var encryptionKeyArray = CryptoJS.lib.WordArray.create(encryptionKey as any); // password or phrase
  var ciphertext = CryptoJS.AES.encrypt(data, encryptionKeyArray, {
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
    iv: parsedBase64IV,
  });
  return ciphertext.toString();
}

export function decryptKEYandIV(
  encryptedData: string, // ---> pass_enc_key
  decryptionKey: Buffer // -->
) {
  var decryptionKeyArray = CryptoJS.lib.WordArray.create(decryptionKey as any);
  var parsedBase64IV = CryptoJS.enc.Base64.parse("MTIzNDU2Nzg5MDEyMzQ1Ng==");
  var DeCiphertext = CryptoJS.AES.decrypt(encryptedData, decryptionKeyArray, {
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
    iv: parsedBase64IV,
  }).toString(CryptoJS.enc.Base64);
  var mainKey = base64.decode(DeCiphertext);
  return mainKey;
}

export function getDecryptedKeys(encryptedKey: string, isDecrypted: boolean) {
  if (isDecrypted) {
    if (encryptedKey.length !== 68) encryptedKey = base64.decode(encryptedKey);
  } else {
    const hash = getPassHash();
    const hashBuff = Buffer.from(hash, "hex");
    encryptedKey = decryptKEYandIV(encryptedKey, hashBuff);
  }
  const iv = encryptedKey.slice(0, 24);
  const key = encryptedKey.slice(24, encryptedKey.length);
  return { key, iv };
}

export function decryptData(
  encryptedData: any,
  pass_enc_key: string,
  isDecrypted: boolean,
  shouldUpdateBandwidth: boolean
) {
  const keys = getDecryptedKeys(pass_enc_key, isDecrypted);
  var parsedBase64Key = CryptoJS.enc.Base64.parse(keys.key);
  var parsedBase64IV = CryptoJS.enc.Base64.parse(keys.iv);
  var decryptedData = CryptoJS.AES.decrypt(encryptedData, parsedBase64Key, {
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
    iv: parsedBase64IV,
  });
  var decryptedText = decryptedData.toString(CryptoJS.enc.Base64);
  var arrayBufer = new Buffer(decryptedText, "base64").buffer;
  shouldUpdateBandwidth && updateUserBandwidth(arrayBufer.byteLength);
  return arrayBufer;
}

export const getDecryptedBuffer = async (
  response: any,
  updateProgress: any,
  name: string,
  isDecrypted: boolean,
  shouldUpdateBandwidth: boolean,
  abortDownload?: any
) => {
  const controller = new AbortController();
  const { signal } = controller;
  // Fetch the file to be downloaded
  return new Promise((resolve, reject) => {
    fetch(response.url, { signal }).then((res) => {
      let fileLength = res.headers.get("Content-Length");
      let chunkSize = 5242880; // 5 MB
      let completedBytes = 0;
      let downloadedBytes = 0;
      let totalChunk =
        Math.floor(parseInt(fileLength ? fileLength : "1") / chunkSize) + 1;
      let lastChunkSize =
        parseInt(fileLength ? fileLength : "1") - (totalChunk - 1) * chunkSize;

      // Create a new Writable Stream
      const fileStream = streamSaver.createWriteStream(name, {
        size: fileLength ? parseInt(fileLength) : undefined,
      });

      let writer = fileStream.getWriter();

      let chunkArray: any = []; // Array to store one chunk

      const reader: any = res.body?.getReader();
      const pump = () =>
        reader
          .read()
          .then((res: any) => {
            if (res.done) {
              console.log("done => ", res.done);
              writer.close(); // If the file is downloaded completely
              updateProgress(downloadedBytes, fileLength, name, res.done);
              resolve(res.done);
            } else {
              // Read & store the data in chunkArray
              var c = new Uint8Array(chunkArray.length + res.value.length);
              c.set(chunkArray);
              c.set(res.value, chunkArray.length);
              chunkArray = c;
              completedBytes = c.length; // to calculate how much bytes has been downloaded in chunkArray
              downloadedBytes += res.value.length; // to calculate how much bytes has been read
              // Send progress updates to DOWNLOAD modal
              updateProgress(downloadedBytes, fileLength, name, res.done);
              // Check if One chunk has been downloded / file is downloaded completely
              if (
                chunkArray.length === fileLength ||
                chunkArray.length === chunkSize ||
                completedBytes === lastChunkSize
              ) {
                // Forward the Chunk for Decryption
                let decryptedChunk = new Uint8Array(
                  decryptData(
                    Buffer.from(chunkArray).toString("base64"),
                    response.pass_enc_key,
                    isDecrypted,
                    shouldUpdateBandwidth
                  )
                );
                chunkArray = []; // Clear the chunkArray for storing next chunk
                writer.write(decryptedChunk).then(pump); // write the decrypted chunk into stream
              } else {
                pump();
              }
            }
          })
          .catch(function (err: any) {
            if (err.name === "AbortError") {
              reject("Aborted");
            }
          });
      pump();
    });
    abortDownload(controller);
  });
};

export const getDecryptedViewableBuffer = async (
  response: any,
  updateProgress: any,
  name: string,
  isDecrypted: boolean
) => {
  // Fetch the file to be downloaded
  return new Promise((resolve, reject) => {
    fetch(response.url).then((res) => {
      let fileLength = res.headers.get("Content-Length");
      let chunkSize = 5242880; // 5 MB
      let completedBytes = 0;
      let downloadedBytes = 0;
      let totalChunk =
        Math.floor(parseInt(fileLength ? fileLength : "1") / chunkSize) + 1;
      let lastChunkSize =
        parseInt(fileLength ? fileLength : "1") - (totalChunk - 1) * chunkSize;

      // Create a new Writable Stream
      const fileStream = streamSaver.createWriteStream(name, {
        size: fileLength ? parseInt(fileLength) : undefined,
      });
      console.log(fileStream);
      let chunkArray: any = []; // Array to store one chunk
      const reader: any = res.body?.getReader();
      let decryptedData = new Uint8Array();
      const pump = () =>
        reader.read().then((res: any) => {
          if (res.done) {
            const dec = Buffer.from(decryptedData).toString("base64");
            resolve(dec);
          } else {
            // Read & store the data in chunkArray
            var c = new Uint8Array(chunkArray.length + res.value.length);
            c.set(chunkArray);
            c.set(res.value, chunkArray.length);
            chunkArray = c;
            completedBytes = c.length; // to calculate how much bytes has been downloaded in chunkArray
            downloadedBytes += res.value.length; // to calculate how much bytes has been read
            // Send progress updates to DOWNLOAD modal
            updateProgress(downloadedBytes, fileLength, name);
            // Check if One chunk has been downloded / file is downloaded completely
            if (
              chunkArray.length === fileLength ||
              chunkArray.length === chunkSize ||
              completedBytes === lastChunkSize
            ) {
              // Forward the Chunk for Decryption
              let decryptedChunk = new Uint8Array(
                decryptData(
                  Buffer.from(chunkArray).toString("base64"),
                  response.pass_enc_key,
                  isDecrypted,
                  true
                )
              );
              chunkArray = []; // Clear the chunkArray for storing next chunk
              var finalBuffer = new Uint8Array(
                decryptedData.length + decryptedChunk.length
              );
              finalBuffer.set(decryptedData);
              finalBuffer.set(decryptedChunk, decryptedData.length);
              decryptedData = finalBuffer;
              pump();
            } else {
              pump();
            }
          }
        });
      pump();
    });
  });
};
