import React, { useState } from "react";
import Header from "../../../components/header/index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import KeyModal from "../../../components/show-Key/showKey";
const SelectPlan = () => {
    const history = useHistory();
    const data: any = [{ name: 'Ziptic Storage' }, { name: 'Ziptic Download Bandwidth' }];
    const subData: any = [
        { package: '100 GB', price: '$3.99' },
        { package: '1000 GB', price: '$39.99' }
    ];
    let userStatus = sessionStorage.getItem('userStatus')
    const [showKeyModal, setShowkeyModal] = useState(false);
    const buyPlan = () => {
        history.push("/buyPlan");
    }
    React.useEffect(() => {
        if (userStatus === 'first') {
            setShowkeyModal(true);
        }
    }, [userStatus]);
    const renderComponents = () => {
        switch (showKeyModal) {
            case true:
                return <KeyModal setShowkeyModal={setShowkeyModal} />;
        }
    };
    const renderSubdata = () => {
        return subData.map((elements: any) => {
            return (
                <div className="card card-sm-size mb-4" key={elements.price}>
                    <div className="card-body p-5">
                        <p className="center card-bold mb-2">{elements.package} <span className="card-txt"> Storage</span></p>
                        <p className="center card-bold color">{elements.price}/Year*</p>
                        <div className="mt-auto center">
                            <button className="buy-btn">
                                <p className="login-txt " onClick={() => { buyPlan() }}>Buy</p>
                            </button>
                        </div>
                    </div>
                </div>
            );
        })
    }
    const renderPlans = () => {
        return data.map((elements: any) => {
            return (
                <div className="pl-4" key={elements.name}>
                    <p className="center mb-1 bold-head">{elements.name}</p>
                    {renderSubdata()}
                </div>
            );
        })
    }
    return (
        <div className="div-section">
            <ToastContainer limit={1} />
            <Header select={true} />
            <p className="center mt-5 purchase-head">Select Plan</p>
            <div className=" pt-lg-4 container center mx-auto">
                <div className="card border-0">
                    <p className="center head-pos bold-head">Ziptic Light</p>
                    <div className="card-body card-size rounded border p-5">
                        <p className="center card-bold mb-2">20 GB <span className="card-txt"> Storage</span></p>
                        <p className="center card-bold">20 GB <span className="card-txt"> Bandwidth</span></p>
                        <div className="mt-auto">
                            <p className="center card-txt mb-5">You can upgrade plan at any time</p>
                            <p className="center free-text">FREE</p>
                        </div>
                    </div>
                </div>
                {renderComponents()}
                {renderPlans()}
            </div>
        </div>
    );
};
export default SelectPlan;