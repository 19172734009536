import React from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";

interface IProps {
  setModal: Function;
  sharedLink: string[];
}
const SharedLinkModal: React.FC<IProps> = ({
  setModal,
  sharedLink,
}: IProps) => {
  const copyLink = `${sharedLink}`;
  return (
    <Modal
      className="text-dark modal-pos modal-margin-top border-0 pb-0"
      show={true}
    >
      <ToastContainer limit={1} />
      <div className="p-4 upload-text">
        <p className="upload-text mb-3">{"Get Link"}</p>
        <div className="d-flex justify-content-between align-items-center">
          <input
            type="text"
            value={copyLink}
            disabled={true}
            className="link2-text"
          />
          <CopyToClipboard
            text={copyLink}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <p className="copy-text">
              Copy link
            </p>
          </CopyToClipboard>
        </div>

        <div className="float-right mt-3">
          <button
            className="confirm-btn"
            onClick={() => {
              setModal(false);
            }}
          >
            <p className="login-txt center">Done</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default SharedLinkModal;
