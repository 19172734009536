import React from 'react'
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import copy from 'copy-to-clipboard';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import copyIcon from "../../assets/copy.png"

interface IProps {
    setShowkeyModal: any;
    seed?: string
}
const ConfirmModal: React.FC<IProps> = ({ setShowkeyModal,seed }: IProps) => {
    const history = useHistory();
    let privateKey: any = sessionStorage.getItem('userPhrase') || seed;
    const onCopy = () => {
        copy(privateKey);
        toast.info("Copied To Clipboard")
    }
    return (
        <Modal
            className="text-dark modal-pos modal-margin-top border-0"
            dialogClassName="modal60w"
            show={true}
        >
            <ToastContainer limit={1} />
            <div className="p-4">
                <div className="p-4 row border box-key ">
                    <span className="col-10 seed-text">{privateKey}</span>
                    <span className="col-1 text-right"><img onClick={() => { onCopy() }} src={copyIcon} className="profile-icons" alt="" /></span>
                </div>
                <li className="texts mt-3"> This is the Private Key which is unique only to you that gives you full ownership of the data stored on Ziptic servers.</li>
                <li className="texts"> Your data is highly encrypted using military grade technology of AES 256 encryption and locked using this unique Private Key </li>
                <li className="texts"> Only yourself and the devices you authorise may access your data. Not even any administrators at Ziptic or our Data Centre engineers can view your data without this Private Key</li>
                <li className="texts"> Please write this randomly generated 12 phrases accurately in its arrangement ON PAPER and avoid storing it on your phone or computer to avoid any possibility of digital theft</li>
                <li className="texts"> Do store this Private Key safely to unlock your data. If you lose this, Ziptic will not be able to unlock the data for you.</li>
                <div className="float-right">
                    <button className="update-btn  mt-1 mt-lg-4" onClick={() => { sessionStorage.removeItem('userStatus') ; setShowkeyModal(false);  history.push("/registered"); }}>
                        <p className="login-txt center">OK</p>
                    </button>
                </div>
            </div>
        </Modal >
    );
};
export default ConfirmModal;