import React from "react"
import Logo from '../../assets/ziptik_logo_light_bg.svg';
import DarkLogo from '../../assets/ziptik_logo_dark_bg.svg';
import { useHistory } from "react-router-dom";
// import selectedLogo from "../../assets/profile.selected.png";
import ReactTooltip from 'react-tooltip';
import { FaUserCircle } from 'react-icons/fa';
import { TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

interface Iprop {
    select?: boolean,
    headLoader?: boolean,
    pasteLoader? : boolean,
    query?: any,
    setQuery?: any,
    onPressSearch?: any
}
const Header: React.FC<Iprop> = ({ select, query, setQuery, onPressSearch, headLoader, pasteLoader }: Iprop) => {    
    const token = sessionStorage.getItem('access_token');
    const history = useHistory();
    const redirect = () => {
        if (!token) {
            sessionStorage.clear();
            history.push("/");
        } else {
            history.push("/dashboard");
        }
    }

    const isDarkTheme = localStorage.getItem('isDarkTheme');
    const onChangeSearchInput = (val:any) => {
        setQuery(val.target.value);
    }
    const onPressProfile = () => history.push("/profile");
    const onPressEnterKey = (event:any) => {
        if (event.key === 'Enter') {
            onPressSearch()
        }
    }
       
    return (
        <div>
            <div className={"d-flex header justify-content-between  " + (select ? 'bor-bottom' : '')}>
                <div className="p-2 ml-lg-5 cursor">
                    <img className="logo" onClick={redirect} src={isDarkTheme === 'true' ? DarkLogo : Logo } alt="logo svg" />
                </div>
                {query !== undefined && 
                <div className="search-field ">
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    className="search-input text-box-l"
                    value={query}
                    onChange={onChangeSearchInput}
                    onKeyPress={onPressEnterKey}
                />
                <SearchIcon className="search-btn" onClick={onPressSearch} />
                </div> }
                {token ?
                    <div className="pt-2 pr-5" data-tip="Profile">
                        <ReactTooltip textColor='white' backgroundColor='#5e44d3' effect="float" />
                        {select ?
                            <div onClick={onPressProfile} className="select-icon">
                                <FaUserCircle color={isDarkTheme === 'true' ? 'white' : '#5e47d4'} size={42}/>
                            </div> :
                            <div onClick={redirect} className="select-icon">
                                <FaUserCircle color={isDarkTheme === 'true' ? 'white' : '#5e47d4'} size={42}/>
                            </div>}
                    </div>
                    : ''}
            </div>
            {headLoader ?
                <div className="loading">
                    <div className="loading_line_wrapper">
                        <div className="loading_line">
                            <div className="loading_line_inner loading_line_inner--1"></div>
                            <div className="loading_line_inner loading_line_inner--2"></div>
                        </div>
                    </div>
                </div> : ''}
            {pasteLoader ? 
            <div className="d-flex  pos-tab paste-loader">
            <div className="spinner-border spiner-xp mt-5" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>: ""}
        </div>
    );
};
export default React.memo(Header);