import React, { useState } from "react";
import Header from "../../../components/header/index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Faqs = () => {
    const [activeQuestion, setActiveQuestion] = useState(0);
    
    return (
        <div className="">
            <ToastContainer limit={1} />
            <Header select={true} />
            <h2 className="center my-5 text1 font-weight-normal">Frequently Asked Questions</h2>
            <div className="faqs-section">
                <h2 className="my-3 text1 font-weight-bold">Getting Started</h2>
                <ul className="questions">
                    <li
                        className={activeQuestion === 1 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(1)}
                    >
                        Where can I find my recovery key?
                    </li>
                    {activeQuestion === 1 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                    <li
                        className={activeQuestion === 2 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(2)}
                    >
                        Where can I find your iOS app?
                    </li>
                    {activeQuestion === 2 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                </ul>
                <h2 className="my-3 text1 font-weight-bold">Cloud Drive Issues</h2>
                <ul className="questions">
                    <li
                        className={activeQuestion === 3 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(3)}
                    >
                        Can i sort my files on cloud drive?
                    </li>
                    {activeQuestion === 3 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                    <li
                        className={activeQuestion === 4 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(4)}
                    >
                        How can i move files and folders within drive?
                    </li>
                    {activeQuestion === 4 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                </ul>
                <h2 className="my-3 text1 font-weight-bold">Contact and Sharing</h2>
                <ul className="questions">
                    <li
                        className={activeQuestion === 5 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(5)}
                    >
                        How can i share files?
                    </li>
                    {activeQuestion === 5 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                    <li
                        className={activeQuestion === 6 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(6)}
                    >
                        How can i add acount?
                    </li>
                    {activeQuestion === 6 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                </ul>
                <h2 className="my-3 text1 font-weight-bold">Ziptic Account</h2>
                <ul className="questions">
                    <li
                        className={activeQuestion === 7 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(7)}
                    >
                        How can i logout from account?
                    </li>
                    {activeQuestion === 7 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                    <li
                        className={activeQuestion === 8 ? 'font-weight-bold' : ''} onClick={() => setActiveQuestion(8)}
                    >
                        How can i clear apps cache?
                    </li>
                    {activeQuestion === 8 && 
                        <div className="py-3 ml-5">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec augue nulla. Duis sed quam gravida, posuere diam nec, pellentesque sem. Nam eget diam lacus. Donec consectetur tempus dictum. Vivamus egestas id nisl a imperdiet. Vivamus ligula ex, vestibulum quis finibus id, egestas in neque.</p>
                        </div>
                    }
                </ul>
            </div>
        </div>
    );
};
export default Faqs;